import React, { Component } from 'react';
import { userActions } from '../../_actions';
import { connect } from 'react-redux';
import ReactEcharts from 'echarts-for-react';
import LoadingOverlay from 'react-loading-overlay';


class Hierarchy extends Component {
  componentDidMount() {
    this.props.dispatch(userActions.getTreeList());
    window.scrollTo(0, 0);
  }

  renderTreeNodes = (nodes) => {
    if (!nodes) return []; // Return an empty array if nodes is undefined
    return nodes.map(node => {
      let formattedNode = {
        name: node.userName,
        children: node.children ? this.renderTreeNodes(node.children) : []
      };
      return formattedNode;
    });
  }

  render() {
    const { users } = this.props;
    const { loading, getTreeList } = users;
    console.log('getTreeList', getTreeList);

    if (!getTreeList || !getTreeList.children) {
      // If getTreeList or its children are undefined, return null or render a message
      return (
        <>
          <div>
            <LoadingOverlay
              active={loading}
              className="temp001"
              spinner
              text='Please wait...'
            />
          </div>

          <div className="h-screen overflow-y-auto bg-[#1f2837] py-6">
            <div className="bg-[#111627] mt-2 mx-5">
              <h1 className="text-white py-2 px-6 text-xl">Hierarchy</h1>
            </div>
            <p className="text-white">No data available</p>
          </div>
        </>
      );
    }

    const formattedData = {
      name: getTreeList && getTreeList.userName,
      children: this.renderTreeNodes(getTreeList.children)
    };

    return (
      <>
        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...'
          />
        </div>

        <div className="h-screen overflow-y-auto bg-[#1f2837] py-6">
          <div className="bg-[#111627] mt-2 mx-5">
            <h1 className="text-white py-2 px-6 text-xl">Hierarchy</h1>
          </div>

          <ReactEcharts
            option={{
              tooltip: {
                trigger: 'item',
                triggerOn: 'mousemove'
              },
              series: [
                {
                  type: 'tree',
                  data: [formattedData],
                  left: '2%',
                  right: '2%',
                  top: '8%',
                  bottom: '20%',
                  symbol: 'emptyCircle',
                  orient: 'vertical',
                  expandAndCollapse: true,
                  label: {
                    position: 'top',
                    rotate: -90,
                    verticalAlign: 'middle',
                    align: 'right',
                    fontSize: 16,
                    color: 'white',
                    // rotate: 0,
                  },
                  leaves: {
                    label: {
                      position: 'bottom',
                      rotate: -90,
                      verticalAlign: 'middle',
                      align: 'left',
                      fontSize: 16,
                      // rotate: 0,
                      color: 'white'
                    }
                  },
                  animationDurationUpdate: 750
                }
              ]
            }}
            style={{ height: '80vh', width: '100%' }}
            opts={{ renderer: 'canvas' }}
            showLoading={loading}
          />
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  return {
    users,
  };
}

export default connect(mapStateToProps)(Hierarchy);
