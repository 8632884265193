import React, { Component } from 'react';
import { userActions } from '../../_actions';
import { connect } from 'react-redux';
// import { BsCurrencyDollar } from "react-icons/bs";
import QRCode from 'qrcode.react';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import LoadingOverlay from 'react-loading-overlay';
import ReactPaginate from 'react-paginate';
import { isMobile } from "react-device-detect";
import { HiPencilAlt } from "react-icons/hi";


class ExternalWallet extends Component {

  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      size: 10,
      keyWord: "",
      pageNo: 1,
      edit: false,
      formField: {},
      errorField: {},
      dateDetails: {
        from_date: new Date(),
        to_date: new Date(),
      },
    }
  }

  componentDidMount() {
    let data = {
      "txType": ['WITHDRAW', 'DEPOSIT'],
      "keyWord": "",
      "pageNo": 1,
      "size": 10
    }

    this.props.dispatch(userActions.getTxAllMulti(data));
    this.props.dispatch(userActions.getUserDetails());
    this.props.dispatch(userActions.getDftWallet());
  }


  static getDerivedStateFromProps(nextProps, prevState) {

    if (nextProps.users.withdrawBalanceSuccess) {
      return {
        ...nextProps,
        isVerifyUserName: false,
        edit: false,
        formField: {},
        errorField: {},
      }
    }
    else {
      return {
        ...nextProps,
      }
    }
  }




  getDate = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let dateDetails = this.state.dateDetails;
    dateDetails[name] = value;
    // console.log(name, value);
    this.setState({ dateDetails });
  }




  handleFromDate = (event) => {
    // console.log(event.target.name, event.target.value);
    // event.preventDefault();
    const { dateDetails } = this.state;
    dateDetails["from_date"] = event.target.value;

    this.setState({ dateDetails });
  }


  handleToDate = (event) => {
    // console.log(event.target.name, event.target.value);
    // event.preventDefault();
    const { dateDetails } = this.state;
    dateDetails["to_date"] = event.target.value;

    this.setState({ dateDetails });
  }

  handlePageClick = (data) => {
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });


    let datatemp = {
      "txType": ['WITHDRAW', 'DEPOSIT'],
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size,
    }
    this.props.dispatch(userActions.getTxAllMulti(datatemp));
  }

  inputChange = (event) => {
    // console.log(event.target.name, event.target.value);
    // event.preventDefault();
    const { formField, errorField } = this.state;
    formField[event.target.name] = event.target.value;
    errorField[event.target.name] = "";
    // console.log(event.target.name, event.target.value);
    this.setState({ formField, errorField });
  }

  addressSubmit = (e) => {
    e.preventDefault();
    // let { users } = this.props;
    // let { getUserNameVerified } = users;

    if (this.handleValidationAddress()) {
      confirmAlert({
        title: 'Confirm to save address?',
        message: 'Are you sure to save address !',
        buttons: [
          {
            label: 'Yes',
            onClick: () => this.props.dispatch(userActions.saveDftWalletAddress({
              address: this.state.formField.address,
            }, this.props))
          },
          {
            label: 'No'
          }
        ]
      });

    }
  }
  // addressSubmit = (e) => {
  //   e.preventDefault();
  //   if (this.handleValidationAddress()) {
  //     let reqData = {
  //       address: this.state.formField.address
  //     }

  //     // console.log("reqData____________:", reqData);

  //     this.props.dispatch(userActions.saveDftWalletAddress(reqData, this.props));
  //   }
  // }


  withdrawBalanceSubmit = (e) => {
    let reqData = {
      amount: this.state.formField.amount
    }

    // console.log("reqData____________:", reqData);

    if (this.handleValidationwithdrawBalance()) {
      confirmAlert({
        title: 'Confirm to Withdraw?',
        message: 'Are you sure to Withdraw Amount To ' + e,
        buttons: [
          {
            label: 'Yes',
            onClick: () => this.props.dispatch(userActions.withdrawBalance(reqData, this.props))
          },
          {
            label: 'No'
          }
        ]
      });

    }
  }


  handleValidationwithdrawBalance = () => {
    let formField = this.state.formField;
    let errorField = {};
    let formIsValid = true;

    //amount
    if (!formField["amount"] || formField["amount"] === "") {
      formIsValid = false;
      errorField["amount"] = "Cannot be empty";
    }

    // console.log('errorsIndex_errorsIndex_:::::', errorField);
    this.setState({ errorField: errorField });
    return formIsValid;
  }

  handleValidationAddress = () => {
    let formField = this.state.formField;
    let errorField = {};
    let formIsValid = true;

    //address
    if (!formField["address"] || formField["address"] === "") {
      formIsValid = false;
      errorField["address"] = "Cannot be empty";
    }

    // console.log('errorsIndex_errorsIndex_:::::', errorField);
    this.setState({ errorField: errorField });
    return formIsValid;
  }


  claimAddressSubmit = (e) => {
    e.preventDefault();
    if (this.handleValidationClaimAddress()) {
      let reqData = {
        txId: this.state.formField.txId
      }

      // console.log("reqData____________:", reqData);

      this.props.dispatch(userActions.claimAddress(reqData, this.props));
    }
  }


  handleValidationClaimAddress = () => {
    let formField = this.state.formField;
    let errorField = {};
    let formIsValid = true;

    //txId
    if (!formField["txId"] || formField["txId"] === "") {
      formIsValid = false;
      errorField["txId"] = "Cannot be empty";
    }

    // console.log('errorsIndex_errorsIndex_:::::', errorField);
    this.setState({ errorField: errorField });
    return formIsValid;
  }

  setEdit = (e) => {
    // e.preventDefault();
    if (this.state.edit == false) {
      this.setState({ edit: true })
    }

    console.log('set__________________', this.state.edit);
  }

  onClickMenu = (url) => {
    this.props.history.push(url)
  }

  render() {



    let { users } = this.props;
    let { txList, loading, txTotal, getDftWalletItems, overview } = users;

    console.log("_RENDER____this.state.edit________________", this.state.edit);


    return (

      <>

        <div >
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <div className="h-screen overflow-y-auto w-full bg-[#1f2837] p-6">

          <section>

            <div className="container mx-auto xl:flex xl:space-x-6 xl:space-y-0 space-y-6 items-start w-full">

              <div className="w-full ">
                <div className="px-4 py-6  bg-[#111627] space-y-3">
                  <div className="flex justify-center">
                    <QRCode className="mx-auto"
                      value={getDftWalletItems && getDftWalletItems.companyAddress ? getDftWalletItems.companyAddress : "-"}
                    />
                  </div>
                  <div className='text-center space-y-3'>
                    <h1 className="text-[#e30c11] text-xl font-bold">ADD DFTC</h1>
                    <h1 className='text-green-600 md:text-md text-sm font-medium w-full overflow-hidden'>{getDftWalletItems && getDftWalletItems.companyAddress ? getDftWalletItems.companyAddress : "-"}</h1>
                    <h1 className="text-white text-lg font-bold">You Have To Pay From Register DFTC Address</h1>
                    <h1 className='text-[#e30c11] text-lg font-bold'>{getDftWalletItems && getDftWalletItems.ownAddress && getDftWalletItems.ownAddress != null ? getDftWalletItems.ownAddress : "Please save your address !"}</h1>
                    <p className="text-white text-base">In case you are not able to scan code & then Directly Pay To the<br />Given Address</p>
                    <p className="text-white text-base">Note: After Transaction Wait For The transaction Shown In Claim.</p>
                  </div>

                  <input className="border-opacity-100 border border-red-500  appearance-none block md:w-4/6 w-full mx-auto px-4 py-2 h-12 rounded-xl text-gray-800 bg-gray-50 focus:outline-none focus:border-gray-200 focus:bg-gray-100 focus:text-gray-800 transition duration-150 text-xl "
                    id="txId" name="txId" placeholder="Enter Transaction Id"
                    value={this.state.formField && this.state.formField['txId'] ? this.state.formField['txId'] : ''}
                    onChange={this.inputChange} />
                  {this.state.errorField && this.state.errorField["txId"] ?
                    <div className="invalid-feedback text-yellow-600">
                      {this.state.errorField["txId"]}
                    </div>
                    : null}
                  <center className="pt-4">
                    <button className='bg-[#DC2626] text-white text-xl font-semibold px-6 py-2 w-44 rounded-2xl hover:bg-red-800' type="button" onClick={this.claimAddressSubmit}
                    >Claim</button>
                  </center>
                </div>
              </div>

              {
                getDftWalletItems && getDftWalletItems.ownAddress && getDftWalletItems.ownAddress != null ?

                  <>
                    <div className="w-full">
                      <div className="flex justify-center items-center w-full px-4 py-4 h-[35rem] bg-[#111627]">

                        <div className="w-full">
                          <div>
                            {/* <h1 className='text-white text-xl font-semibold text-center pb-6 capitalize'>Save your dftc address</h1> */}
                            <div className="flex justify-center">

                            </div>
                            {this.state.edit == false ?
                              <><h1 className='text-white text-xl font-semibold text-center '>Your Address</h1>
                                <div className='flex justify-center items-center text-center space-y-1 space-x-3 py-10'>
                                  <div className='w-full md:w-3/5'>
                                    <input className=" w-full border-opacity-100 border border-red-500  appearance-none block  px-4 py-2 h-12 rounded-xl text-gray-800 bg-gray-50 focus:outline-none focus:border-gray-200 focus:bg-gray-100 focus:text-gray-800 transition duration-150 text-xl font-medium sm:leading-5"
                                      id="address" name="address" placeholder="Enter Address"
                                      value={getDftWalletItems.ownAddress}
                                    // onChange={this.inputChange} 
                                    />
                                    {this.state.errorField && this.state.errorField["address"] ?
                                      <div className="invalid-feedback text-yellow-600">
                                        {this.state.errorField["address"]}
                                      </div>
                                      : null}
                                  </div>
                                  <div class="targetablepx-4 tooltip px-3 py-2 font-medium tracking-wider text-blue-100 bg-green-700 border rounded-md shadow-sm hover:shadow-lg hover:bg-green-400 cursor-pointer w-10 h-10"
                                    // name={this.state.edit}
                                    value={this.state.edit} onClick={() => this.setEdit()}
                                  >
                                    <span class='tooltip-text bg-pink-600 text-white text-sm rounded'>Edit Address</span>
                                    <HiPencilAlt className='w-5 h-5 block object-contain' />
                                  </div>
                                </div>
                              </>
                              :
                              <div className="w-full">
                                <div className='flex justify-center text-center space-y-3  py-10'>
                                  <input className="border-opacity-100 border border-red-500  appearance-none block md:w-4/6 w-full mx-auto px-4 py-2 h-12 rounded-xl text-gray-800 bg-gray-50 focus:outline-none focus:border-gray-200 focus:bg-gray-100 focus:text-gray-800 transition duration-150 text-xl font-medium sm:leading-5"
                                    id="address" name="address" placeholder="Enter Address"
                                    onChange={this.inputChange} />
                                  {this.state.errorField && this.state.errorField["address"] ?
                                    <div className="invalid-feedback text-yellow-600">
                                      {this.state.errorField["address"]}
                                    </div>
                                    : null}
                                </div>
                                <center className="">
                                  <button className='bg-[#DC2626] text-white text-xl font-semibold px-6 py-2 w-44 rounded-2xl hover:bg-red-800' type="button" onClick={this.addressSubmit}>Submit</button>
                                </center>
                              </div>
                            }
                            {/* <center className="">
                              <button className='bg-[#DC2626] text-white text-xl font-semibold px-6 py-2 w-44 rounded-2xl hover:bg-red-800' type="button" onClick={this.addressSubmit}>Submit</button>
                            </center> */}
                          </div>
                          <div>
                            <h1 className='text-white text-xl font-semibold text-center pb-6'>Withdraw Balance</h1>
                            <div className="flex justify-center">
                              <h1 className="text-white text-lg font-bold">You Have To Withdraw From Register DFTC Address</h1>
                            </div>
                            <div className="flex justify-center mt-2">
                              <h1 className="text-green-600 text-lg font-bold">Balance : {overview && overview.wallet && overview.wallet.wBalance ? overview.wallet.wBalance.toFixed(2) : 0}</h1>
                            </div>
                            <div className='flex justify-center text-center space-y-3 py-6'>
                              <input className="border-opacity-100 border border-red-500  appearance-none block md:w-4/6 w-full px-4 py-2 h-12 rounded-xl text-gray-800 bg-gray-50 focus:outline-none focus:border-gray-200 focus:bg-gray-100 focus:text-gray-800 transition duration-150 text-xl font-medium sm:leading-5"
                                id="amount" name="amount" placeholder="Enter Amount"
                                onChange={this.inputChange}
                                value={this.state.formField && this.state.formField['amount'] ? this.state.formField['amount'] : ''}
                              />
                              {this.state.errorField && this.state.errorField["amount"] ?
                                <div className="invalid-feedback text-yellow-600">
                                  {this.state.errorField["amount"]}
                                </div>
                                : null}
                            </div>
                            <center className="">
                              <button className='bg-[#DC2626] text-white text-xl font-semibold px-6 py-2 w-44 rounded-2xl hover:bg-red-800' type="button"
                              onClick={() => this.withdrawBalanceSubmit(getDftWalletItems.ownAddress)}
                                // onClick={this.withdrawBalanceSubmit(getDftWalletItems.ownAddress)}
                                >Submit</button>
                            </center>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                  :
                  <>
                    <div className="w-full">
                      <div className="flex justify-center items-center w-full px-4 py-6 h-[34rem] bg-[#111627]">
                        <div className="w-full">
                          <h1 className='text-white text-xl font-semibold text-center pb-6 capitalize'>Save your dftc address</h1>
                          <div className="flex justify-center">
                            <h1 className="text-white text-lg font-bold">You Have To Pay From Register DFTC Address</h1>
                          </div>
                          <div className='flex justify-center text-center space-y-3  py-10'>
                            <input className="border-opacity-100 border border-red-500  appearance-none block md:w-4/6 w-full mx-auto px-4 py-2 h-12 rounded-xl text-gray-800 bg-gray-50 focus:outline-none focus:border-gray-200 focus:bg-gray-100 focus:text-gray-800 transition duration-150 text-xl font-medium sm:leading-5"
                              id="address" name="address" placeholder="Enter Address"
                              onChange={this.inputChange} />
                            {this.state.errorField && this.state.errorField["address"] ?
                              <div className="invalid-feedback text-yellow-600">
                                {this.state.errorField["address"]}
                              </div>
                              : null}
                          </div>
                          <center className="">
                            <button className='bg-[#DC2626] text-white text-xl font-semibold px-6 py-2 w-44 rounded-2xl hover:bg-red-800' type="button" onClick={this.addressSubmit}>Submit</button>
                          </center>
                        </div>
                      </div>
                    </div>
                  </>
              }

            </div>
          </section>


          <section className="mt-5">
            <div className="bg-slate-900 w-full 2xl:py-7 py-5 px-5 flex-col rounded-md ">
              <div className="w-full flex justify-between flex-wrap">
                <h3 className="md:text-2xl mb-2 text-lg leading-6 md:leading-9 text-center font-semibold uppercase text-white text-opacity-70 tracking-widest">Transaction History</h3>
                <button onClick={() => this.onClickMenu('/app/transactions')} className="w-32 py-1 cursor-pointer bg-[#790000] rounded-md text-xs font-semibold text-white hover:bg-shine-400 focus:outline-none">See All Transaction</button>
              </div>
              <div className=" overflow-hidden pb-2 mt-4 shadow-md">
                <div className="overflow-x-auto max-w-full ">
                  <div className="inline-block min-w-full  ">
                    <div className="overflow-x-auto">

                      <table className="min-w-full divide-y divide-gray-800 border-0 ">
                        <thead className="border border-black">
                          <tr className="border border-black text-center">
                            <th scope="col" className="whitespace-nowrap border border-white text-center px-[49px] bg-[#790000] py-3 text-xs font-semibold text-white uppercase">S/n</th>
                            <th scope="col" className="whitespace-nowrap border border-white text-center px-[38px] py-3 bg-[#790000] text-xs font-semibold text-white capitalize">date</th>

                            {/* <th scope="col" className="whitespace-nowrap border border-white text-center px-[49px] bg-[#790000] py-3 text-xs font-semibold text-white uppercase">coin name</th> */}

                            <th scope="col" className="whitespace-nowrap border border-white text-center px-[45px] bg-[#790000] py-3 text-xs font-semibold text-white capitalize">Transaction Type</th>
                            {/* <th scope="col" className="whitespace-nowrap border border-white text-center px-[31px] py-3 bg-[#790000] text-xs font-semibold text-white capitalize">ticker</th> */}
                            <th scope="col" className="whitespace-nowrap border border-white text-center px-[22px] py-3 bg-[#790000] text-xs font-semibold text-white capitalize">amount</th>
                            <th scope="col" className="whitespace-nowrap border border-white text-center px-[38px] py-3 bg-[#790000] text-xs font-semibold text-white capitalize">Received From</th>

                          </tr>
                        </thead>
                        <tbody>

                          {
                            txList && txList.length > 0 ?
                              txList.map((element, index) => (
                                <>
                                  <tr className="border border-black text-center">
                                    <td className="whitespace-nowrap border border-gray-300 text-center px-[13px] py-3 text-xs font-semibold text-black bg-white uppercase bg-whj">{this.state.offset + index + 1}</td>
                                    <td className="whitespace-nowrap border border-gray-300 text-center px-[33px] py-3 text-xs font-semibold text-black bg-white capitalize bg-whj">{moment(new Date(parseInt(element && element.createdAt ? element.createdAt : "-"))).utcOffset("+05:30").format("DD-MM-YYYY HH:mm")}</td>
                                    {/* <td className="whitespace-nowrap border border-gray-300 text-center px-[13px] py-3 text-xs font-semibold text-black bg-white uppercase bg-whj">{element && element.coinId && element.coinId.name ? element.coinId.name : "-"}</td> */}
                                    {/* <td className="whitespace-nowrap border border-gray-300 text-center px-[25px] py-3 text-xs font-semibold text-black bg-white capitalize bg-whj">{element && element.coinId && element.coinId.price ? element.coinId.price : 0} </td> */}
                                    <td className="whitespace-nowrap border border-gray-300 text-center px-[25px] py-3 text-xs font-semibold text-black bg-white capitalize bg-whj">{element && element.txType ? element.txType : "-"}</td>
                                    {/* <td className="whitespace-nowrap border border-gray-300 text-center px-[55px] py-3 text-xs font-semibold text-black bg-white capitalize bg-whj">{element && element.coinId && element.coinId.ticker ? element.coinId.ticker : "-"} </td> */}
                                    <td className="whitespace-nowrap border border-gray-300 text-center px-[51px] py-3 text-xs font-semibold text-black bg-white capitalize bg-whj">{element && element.amount ? element.amount : "-"}</td>
                                    <td className="whitespace-nowrap border border-gray-300 text-center px-[11.5px] py-3 text-xs font-semibold text-black bg-white uppercase bg-whj">{element && element.userRecId && element.userRecId.userName ? element.userRecId.userName : "-"} </td>

                                  </tr>
                                </>
                              )) : null
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                {
                  isMobile ?
                    <nav className="relative z-0 flex justify-end mt-5 w-76">
                      {
                        txTotal && txTotal > 10 ?
                          <ReactPaginate
                            previousLabel={'Prev'}
                            nextLabel={'Next'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={txTotal / this.state.size}
                            marginPagesDisplayed={0}
                            pageRangeDisplayed={2}
                            onPageChange={this.handlePageClick}
                            containerClassName={'pagination'}
                            pageClassName={'page-cls'}
                            activeClassName={'active'}
                          />
                          : null}
                    </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                      {
                        txTotal && txTotal > 10 ?
                          <ReactPaginate
                            previousLabel={'Previous'}
                            nextLabel={'Next'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={txTotal / this.state.size}
                            marginPagesDisplayed={3}
                            pageRangeDisplayed={3}
                            onPageChange={this.handlePageClick}
                            containerClassName={'pagination'}
                            pageClassName={'page-cls'}
                            activeClassName={'active'}
                          />
                          : null}
                    </nav>
                }

              </div>
            </div>
          </section>

        </div >

      </>

    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  const { overview } = users ? users : {};
  const { setting } = overview ? overview : {};
  return {
    users,
    setting
  };
}
export default connect(mapStateToProps)(ExternalWallet);