import React, { Component } from 'react';
import { userActions, dashboardActions } from '../../_actions';
import { connect } from 'react-redux';
import moment from 'moment';
import Header from '../../components/Header/Header';
import "./App.css";
import "jspdf-autotable";
import ReactPaginate from 'react-paginate';
import { isMobile } from "react-device-detect";
import LoadingOverlay from 'react-loading-overlay';



class LtaReward extends Component {

  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      size: 10,
      keyWord: "",
      pageNo: 1,
      formField: {},
      errorField: {},
      dateDetails: {
        from: new Date(),
        to: new Date(),
      },
    }
  }

  componentDidMount() {

    let data = {
      "keyWord": "",
      "pageNo": 1,
      "size": 14
    }

    this.props.dispatch(dashboardActions.getLTArchiveRewardList(data));
  }

  transactionSearchSubmit = (e) => {
    e.preventDefault();
    if (this.handleValidation()) {
      let reqData = {
        "txType": "SEND_RECEIVED",
        "keyWord": "",
        "pageNo": 1,
        "size": 10,
        // "coinId": this.state.formField && this.state.formField.coinId ? this.state.formField.coinId : null,
        "from": (this.state.dateDetails.from),
        "to": (this.state.dateDetails.to),
      }
      // console.log('transactionSearch_____******', reqData);
      // console.log('this.state.dateDetails******', this.state.dateDetails);
      this.props.dispatch(userActions.transactionSearch(reqData, this.props));
    }
  }

  getTx = (e) => {
    e.preventDefault();
    // if (this.handleValidation()) {
    let reqData = {
      "txType": "RECEIVED_FROM_ADMIN",
      "keyWord": "",
      "pageNo": 1,
      "size": 10,
      "from": (this.state.dateDetails.from),
      "to": (this.state.dateDetails.to),
    }

    // console.log("getTx_______________:", reqData);

    this.props.dispatch(userActions.getTx(reqData, this.props));
    // }
  }


  getDate = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let dateDetails = this.state.dateDetails;
    dateDetails[name] = value;
    // console.log(name, value);
    this.setState({ dateDetails });
  }

  inputChange = (event) => {
    // console.log(event.target.name, event.target.value);
    // event.preventDefault();
    const { formField, errorField } = this.state;
    formField[event.target.name] = event.target.value;
    errorField[event.target.name] = "";
    // console.log(event.target.name, event.target.value);
    this.setState({ formField, errorField });
  }



  handlePageClick = (data) => {
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });
    let datatemp = {
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size,
    }
    this.props.dispatch(userActions.getTx(datatemp));
  }

  handleValidation = () => {
    let formField = this.state.formField;
    let errorField = {};
    let formIsValid = true;

    // console.log('errorsIndex_errorsIndex_:::::', errorField);
    this.setState({ errorField: errorField });
    return formIsValid;
  }

  handleFromDate = (event) => {
    // console.log(event.target.name, event.target.value);
    // event.preventDefault();
    const { dateDetails } = this.state;
    dateDetails["from"] = event.target.value;

    this.setState({ dateDetails });
  }


  handleToDate = (event) => {
    // console.log(event.target.name, event.target.value);
    // event.preventDefault();
    const { dateDetails } = this.state;
    dateDetails["to"] = event.target.value;

    this.setState({ dateDetails });
  }

  render() {



    let { users, dashboard } = this.props;
    let { txList, loading, txTotal } = users;
    let { LTArchiveRewardList } = dashboard;

    // // console.log("RENDER_txTotaltxTotal:::::", txTotal);
    // console.log("RENDER_____________LTArchiveRewardList___:::::", LTArchiveRewardList);
    // // console.log("RENDER_formField:::::", this.state.formField);
    // console.log("RENDER___________dashboard:::::", dashboard);


    return (

      <>

        <div >
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>


        <div >
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>


        <div className="w-full h- h-screen bg_page">
          <div className='bg-[#111827] m-6'>
            <div className="text-xl mx-5 py-5 text-white">
              <h1>EBT Statistic</h1>
            </div>

            <div className="pb-10 mx-5 space-y-4">


              {

                LTArchiveRewardList && LTArchiveRewardList.length > 0 ?
                  LTArchiveRewardList.map((element, index) => (
                    <React.Fragment>


                      <div className="text-center bg-[#1F2937] border border-gray-700 rounded-xl shadow-xl p-4 text-sm">
                        <div className="text-lg text-white">{element && element.rank ? element.rank : ""}</div>
                        <div className="text-yellow-400 text-lg text-right pt-2">Volume ${element && element.volumeShow ? element.volumeShow : ""}/Reward ${element && element.rewardShow ? element.rewardShow : ""}</div>
                      </div>

                    </React.Fragment>

                  )) : null


              }
















              {/* <div className="text-center bg-[#1F2937] border border-gray-700 rounded-xl shadow-xl p-4 text-sm">
                <div className="text-lg text-white">Mining Advisor</div>
                <div className="text-yellow-400 text-lg text-right pt-2">Volume $2500/Reward $50</div>
              </div>
              <div className="text-center bg-[#1F2937] border border-gray-700 rounded-xl shadow-xl p-4 text-sm">
                <div className="text-lg text-white">Mining Advisor</div>
                <div className="text-yellow-400 text-lg text-right pt-2">Volume $2500/Reward $50</div>
              </div>
              <div className="text-center bg-[#1F2937] border border-gray-700 rounded-xl shadow-xl p-4 text-sm">
                <div className="text-lg text-white">Mining Advisor</div>
                <div className="text-yellow-400 text-lg text-right pt-2">Volume $2500/Reward $50</div>
              </div>
              <div className="text-center bg-[#1F2937] border border-gray-700 rounded-xl shadow-xl p-4 text-sm">
                <div className="text-lg text-white">Mining Advisor</div>
                <div className="text-yellow-400 text-lg text-right pt-2">Volume $2500/Reward $50</div>
              </div> */}






            </div>
          </div>
        </div>


      </>

    );
  }
}

function mapStateToProps(state) {
  const { users, dashboard } = state;
  const { overview } = users ? users : {};
  const { setting } = overview ? overview : {};
  return {
    users,
    setting,
    dashboard
  };
}
export default connect(mapStateToProps)(LtaReward);
