import React, { Component } from 'react';
import { userActions } from '../../_actions';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
// import moment from 'moment';
import Header from '../../components/Header/Header';
import "./App.css";
import "jspdf-autotable";
import ReactPaginate from 'react-paginate';
import { isMobile } from "react-device-detect";


class SuperTransactions extends Component {

  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      size: 10,
      keyWord: "",
      pageNo: 1,
      formField: {},
      errorField: {},
      dateDetails: {
        from_date: Date.now(),
        to_date: Date.now(),
      },
    }
  }

  componentDidMount() {

    let data = {
      "txType": "SEND_RECEIVED",
      "keyWord": "",
      "pageNo": 1,
      "size": 10
    }

    // console.log("puku???", this.props);

    let req = {
      id: this.props.match.params.id
    }
    this.props.dispatch(userActions.getAllCoinIndexByIndexId(req));
    this.props.dispatch(userActions.getTx(data));
  }


  getDate = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let dateDetails = this.state.dateDetails;
    dateDetails[name] = value;
    // console.log(name, value);
    this.setState({ dateDetails });
  }

  inputChange = (event) => {
    // console.log(event.target.name, event.target.value);
    // event.preventDefault();
    const { formField, errorField } = this.state;
    formField[event.target.name] = event.target.value;
    errorField[event.target.name] = "";
    // console.log(event.target.name, event.target.value);
    this.setState({ formField, errorField });
  }

  handlePageClick = (data) => {
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });
    let datatemp = {
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size,
      "txType": "SEND_RECEIVED",
    }
    this.props.dispatch(userActions.getTx(datatemp));
  }

  handleValidation = () => {
    // let formField = this.state.formField;
    let errorField = {};
    let formIsValid = true;
    // console.log('errorsIndex_errorsIndex_:::::', errorField);
    this.setState({ errorField: errorField });
    return formIsValid;
  }

  // transactionSearchSubmit = (e) => {
  //   e.preventDefault();
  //   if (this.handleValidation()) {
  //     let reqData = {
  //       "startDate": new Date(this.state.dateDetails.from_date).valueOf() / 1000,
  //       "endDate": new Date(this.state.dateDetails.to_date).valueOf() / 1000,
  //     }
  //   }
  // }

  render() {



    // let {allCoinIndexByIndexId} = this.props;

    let { users } = this.props;
    let { loading, txTotal, allCoinIndexByIndexId } = users;
    // console.log("RENDER_allCoinIndexByIndexIdRENDER_allCoinIndexByIndexIdRENDER_allCoinIndexByIndexId:::", allCoinIndexByIndexId);

    // console.log("RENDER_txTotaltxTotal:::::", txTotal);
    // // console.log("RENDER_formField:::::", this.state.formField);


    return (

      <>

        <Header name="Transactions" />

        <div >
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <div className='w-full bg-slate-700 h-screen overflow-y-auto mb-16 lg:mb-0'>
          <main className=" flex-1 w-full sm:mim-w-screen m-auto relative z-0 overflow-y-auto focus:outline-none"  >
            <div className="2xl:p-10 sm:p-5 p-3">


              <div className='lg:w-full w-full flex-wrap overflow-x-auto'>
                <table className="mt-5 rounded-md bg-slate-900 shadow-2xl w-full sm:px-4">
                  <tr className="whitespace-nowrap">
                    <th className="text-white text-base py-6">S/N</th>
                    <th className="text-white text-base py-6">COIN NAME</th>
                    <th className="text-white text-base py-6">SYMBOL</th>
                    <th className="text-white text-base py-6">CURRENT PRICE</th>
                    <th className="text-white text-base py-6">%</th>
                  </tr>
                  {
                    allCoinIndexByIndexId && allCoinIndexByIndexId.length > 0 ?
                      allCoinIndexByIndexId.map((element, index) => (<React.Fragment>
                        <tr key={element.id} className="tablenth ">
                          <td className="px-6 py-3 whitespace-nowrap font-medium text-sm text-white">{this.state.offset + index + 1}</td>
                          <td className="px-6 py-3 whitespace-nowrap text-sm text-white">{element && element.coin && element.coin.name ? element.coin.name : "-"}</td>
                          <td className="px-6 py-3 whitespace-nowrap text-sm text-white">{element && element.coin && element.coin.ticker ? element.coin.ticker : "-"}</td>
                          <td className="px-6 py-3 whitespace-nowrap text-sm text-white">
                            {element && element.coin && element.coin.price ? element.coin.price > 100 ? element.coin.price.toFixed(3) : element.coin.price.toFixed(6) : "-"}</td>
                          <td className="px-6 py-3 whitespace-nowrap text-sm text-white">{element && element.weightage ? element.weightage : "-"}</td>

                        </tr>
                      </React.Fragment>))
                      : (
                        <tr>
                          <td colspan="6" className="text-center">No Record Found</td>
                        </tr>)
                  }
                </table>
              </div>

              {

                isMobile ?


                  <nav className="relative z-0 flex justify-end mt-5 w-76">
                    {
                      txTotal && txTotal > 10 ?
                        <ReactPaginate
                          previousLabel={'Prev'}
                          nextLabel={'Next'}
                          breakLabel={'...'}
                          breakClassName={'break-me'}
                          pageCount={txTotal / this.state.size}
                          marginPagesDisplayed={1}
                          pageRangeDisplayed={1}
                          onPageChange={this.handlePageClick}
                          containerClassName={'pagination'}
                          pageClassName={'page-cls'}
                          activeClassName={'active'}
                        />
                        : null}
                  </nav>
                  :
                  <nav className="relative z-0 flex justify-end mt-5 w-76">
                    {
                      txTotal && txTotal > 10 ?
                        <ReactPaginate
                          previousLabel={'Previous'}
                          nextLabel={'Next'}
                          breakLabel={'...'}
                          breakClassName={'break-me'}
                          pageCount={txTotal / this.state.size}
                          marginPagesDisplayed={3}
                          pageRangeDisplayed={3}
                          onPageChange={this.handlePageClick}
                          containerClassName={'pagination'}
                          pageClassName={'page-cls'}
                          activeClassName={'active'}
                        />
                        : null
                    }
                  </nav>
              }

            </div>
          </main>
        </div>
      </>

    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  const { overview } = users ? users : {};
  const { setting } = overview ? overview : {};
  return {
    users,
    setting
  };
}
export default connect(mapStateToProps)(SuperTransactions);
