import React, { Component } from 'react';
import { dashboardActions } from '../../_actions';
import { connect } from 'react-redux';
import "./App.css";
import "jspdf-autotable";
import LoadingOverlay from 'react-loading-overlay';



class LtaReward extends Component {

  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      size: 10,
      keyWord: "",
      pageNo: 1,
      formField: {},
      errorField: {},
      dateDetails: {
        from: new Date(),
        to: new Date(),
      },
    }
  }

  componentDidMount() {

    let data = {
      "keyWord": "",
      "pageNo": 1,
      "size": 14
    }

    this.props.dispatch(dashboardActions.getFastrackBonusList(data));
  }


  render() {



    let { users } = this.props;
    let { loading } = users;
    // let { } = dashboard;



    return (

      <>

        <div >
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>


        <div >
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>


        <div className="w-full h- h-screen bg_page">
          <div className='bg-[#111827] m-6'>
            <div className="text-xl mx-5 py-5 text-white">
              <h1>EBT Statistic</h1>
            </div>

            <div className="pb-10 mx-5 space-y-4">

              {/* {
                fastrackBonusList && fastrackBonusList.length
              } */}
              <div className="text-center bg-[#1F2937] border border-gray-700 rounded-xl shadow-xl p-4 text-sm">
                <div className='flex justify-between flex-wrap border-b pb-2'>
                  <div className="flex space-x-4 text-lg text-white">
                    <h1>100k/100k</h1>
                    <p className='pt-1'>Mining Advisor</p>
                  </div>
                  <div className="text-lg text-white">Mining Advisor</div>
                </div>
                <div className="text-yellow-400 text-lg text-left pt-2">Volume $2500/Reward $50</div>
                <div className="w-28 text-sm text-yellow-400 text-center border rounded-full py-2 mt-4">Enroll Now</div>
              </div>
            </div>
          </div>
        </div>


      </>

    );
  }
}

function mapStateToProps(state) {
  const { users, dashboard } = state;
  const { overview } = users ? users : {};
  const { setting } = overview ? overview : {};
  return {
    users,
    setting,
    dashboard
  };
}
export default connect(mapStateToProps)(LtaReward);
