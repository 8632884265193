import React, { Component } from 'react';
import { userActions, dashboardActions } from '../../_actions';
import { connect } from 'react-redux';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import LoadingOverlay from 'react-loading-overlay';
import CreateAppSettingModal from "./components/CreateAppSettingModal/CreateAppSettingModal";


class packageupgradation extends Component {

  constructor(props) {
    super(props);
    this.state = {
      appsettingCreateModal: false,
      offset: 0,
      size: 10,
      keyWord: "",
      pageNo: 1,
      cardDetails: {},
      formField: {},
      errorField: {},
      dateDetails: {
        from_date: new Date(),
        to_date: new Date(),
      },
    }
  }

  componentDidMount() {

    let data2 = {
      "keyWord": "",
      "pageNo": 1,
      "size": 10
    }

    this.props.dispatch(userActions.getUserDetails());
    // this.props.dispatch(userActions.getTx(data));
    this.props.dispatch(userActions.getPackageList(data2));
    this.props.dispatch(dashboardActions.getUserPackages());
  }

  static getDerivedStateFromProps(nextProps, prevState) {

    if (nextProps.dashboard.swapCoinSuccess) {
      return {
        ...nextProps,
        formField: {},
        errorField: {},
        cardDetails: {},
        appsettingCreateModal: false,

      }
    } else {
      return {
        ...nextProps
      }
    }
  }

  buyPackageSubmit = () => {
    let reqData = {
      packageId: this.state.cardDetails.id,
      amount: this.state.formField.amount
    }

    if (this.handleValidation()) {
      confirmAlert({
        title: 'Confirm to Buy?',
        message: 'Are you sure to Buy Package ',
        buttons: [
          {
            label: 'Yes',
            onClick: () => this.props.dispatch(dashboardActions.buyPackage(reqData, this.props))
          },
          {
            label: 'No'
          }
        ]
      });

    }
  }

  handleValidation = () => {
    let formField = this.state.formField;
    let errorField = {};
    let formIsValid = true;

    //amount
    if (!formField["amount"] || formField["amount"] === "") {
      formIsValid = false;
      errorField["amount"] = "Cannot be empty";
    }

    this.setState({ errorField: errorField });
    return formIsValid;
  }


  getDate = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let dateDetails = this.state.dateDetails;
    dateDetails[name] = value;
    this.setState({ dateDetails });
  }

  inputChange = (event) => {
    const { formField, errorField } = this.state;
    formField[event.target.name] = event.target.value;
    errorField[event.target.name] = "";
    this.setState({ formField, errorField });
  }


  handleFromDate = (event) => {
    const { dateDetails } = this.state;
    dateDetails["from_date"] = event.target.value;
    this.setState({ dateDetails });
  }


  handleToDate = (event) => {
    // console.log(event.target.name, event.target.value);
    // event.preventDefault();
    const { dateDetails } = this.state;
    dateDetails["to_date"] = event.target.value;

    this.setState({ dateDetails });
  }

  handlePageClick = (data) => {
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });
    let datatemp = {
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size,
    }
    this.props.dispatch(userActions.getTx(datatemp));
  }



  handleOpenCreateModal = (data) => {
    this.setState({ appsettingCreateModal: true, cardDetails: data });
  }
  handleAppSeetingHideModal = () => {
    this.setState({ appsettingCreateModal: false });
  }

  onClickMenu = (id) => {
    this.props.history.push("/app/userPackagesTeam/" + id)
  }

  render() {

    let { users, dashboard } = this.props;
    let { packageList, overview } = users;
    let { user } = overview ? overview : {};
    let { userPackages, loading } = dashboard;

    // console.log("RENDER____this.state.appsettingCreateModal:::::", this.state.appsettingCreateModal);

    return (

      <>


        <div >
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <div className="h-screen w-full mx-auto px-4 md:px-12 overflow-y-auto bg-[#1f2837]">
          <div className="flex justify-center items-center flex-wrap lg:-mx-4 my-4 py-10">

            {
              packageList && packageList.length > 0 ?
                packageList.map((element, index) => (

                  <>

                    <div className="flex justify-center my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3">
                      <div className='w-full shadow-xl bg-[#1F2937] p-2 rounded-md'>
                        <div className=' w-full bg-slate-900  rounded-md p-4 z-20 shadow-lg'>
                          {/* <h2 className='text-center text-white pt-2 text-2xl font-medium uppercase'>Package </h2> */}

                          <h2 className='text-center text-white pt-2 text-2xl font-medium uppercase'>{element && element.name ? element.name : "-"} </h2>

                          <h2 className="text-center text-white border-b border-gray-400 mx-4 py-3 text-3xl">${element && element.from ? element.from : "-"} - ${element && element.to ? element.to : "-"}</h2>
                          <div className='flex justify-center'>
                            <div className='pt-4 text-white space-y-2'>
                              <h2 className='text-center text-xl pt-1'>MINING PROFIT PACKAGE</h2>
                              <h2 className='text-center text-2xl py-1'>{element && element.reward ? element.reward : "-"}% / Week</h2>
                              <center className="">
                                <button className='bg-[#DC2626] text-white px-6 py-1.5 w-36 rounded-2xl' onClick={() => this.handleOpenCreateModal(element)}>BUY</button>
                              </center>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </>

                )) : null

            }



            <div className="bg-[#1f2837] w-full h-screen">
              <section>
                <div className='p-6'>
                  <div className='w-full flex justify-center bg-slate-800 pt-6'>

                  </div>
                  <div className=' bg-slate-900 pb-6 px-2'>
                    <div className='flex justify-between flex-wrap pt-4 px-4'>
                      <h1 className="text-white text-xl py-2 px-4">Buy Package Transaction History</h1>
                      <h1 className="text-white flex text-xl pt-1 px-4 mr-2 cursor-pointer hover:text-green-600 hover:bg-[#1f2837] border mb-2" onClick={() => this.onClickMenu(user.id)}>User Package Team</h1>
                    </div>

                    <div className="overflow-hidden pb-2 lg:p-6">
                      <div className="overflow-auto max-w-full">

                        <div className="inline-block min-w-full">
                          <div className="overflow-hidden">

                            <table className="min-w-full border border-black  text-center">
                              <thead className="border border-black">
                                <tr className="border border-black text-center">
                                  <th scope="col" className="whitespace-nowrap border border-white text-center px-[49px] bg-[#790000] py-3 text-xs font-semibold text-white uppercase">Sr no.</th>
                                  <th scope="col" className="whitespace-nowrap border border-white text-center px-[49px] bg-[#790000] py-3 text-xs font-semibold text-white uppercase">Name</th>
                                  <th scope="col" className="whitespace-nowrap border border-white text-center px-[31px] py-3 bg-[#790000] text-xs font-semibold text-white capitalize">Reward</th>
                                  <th scope="col" className="whitespace-nowrap border border-white text-center px-[42px] py-3 bg-[#790000] text-xs font-semibold text-white capitalize">From - To</th>
                                  <th scope="col" className="whitespace-nowrap border border-white text-center px-[45px] bg-[#790000] py-3 text-xs font-semibold text-white capitalize">amount</th>
                                  <th scope="col" className="whitespace-nowrap border border-white text-center px-[22px] py-3 bg-[#790000] text-xs font-semibold text-white capitalize">Date</th>
                                </tr>
                              </thead>
                              <tbody>

                                {
                                  userPackages && userPackages.length > 0 ?
                                    userPackages.map((element, index) => (
                                      <React.Fragment key={index}>

                                        <tr className="border border-black text-center">
                                          <td className="whitespace-nowrap border border-gray-300 text-center px-[55px] py-3 text-xs font-semibold text-black bg-white capitalize bg-whj">{index + 1}</td>
                                          <td className="whitespace-nowrap border border-gray-300 text-center px-[11.5px] py-3 text-xs font-semibold text-black bg-white uppercase bg-whj">{element && element.packageId && element.packageId.name ? element.packageId.name : "-"}</td>
                                          <td className="whitespace-nowrap border border-gray-300 text-center px-[55px] py-3 text-xs font-semibold text-black bg-white capitalize bg-whj">{element && element.packageId && element.packageId.reward ? element.packageId.reward : "-"}</td>
                                          <td className="whitespace-nowrap border border-gray-300 text-center px-[28px] py-3 text-xs font-semibold text-black bg-white  capitalize bg-whj">{element && element.packageId && element.packageId.from ? element.packageId.from : "-"}-{element && element.packageId && element.packageId.to ? element.packageId.to : "-"}</td>
                                          <td className="whitespace-nowrap border border-gray-300 text-center px-[25px] py-3 text-xs font-semibold text-black bg-white capitalize bg-whj">{element && element.amount ? element.amount : "-"}</td>
                                          <td className="whitespace-nowrap border border-gray-300 text-center px-[51px] py-3 text-xs font-semibold text-black bg-white capitalize bg-whj">{moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("YYYY-MM-DD HH:mm")}</td>

                                        </tr>

                                      </React.Fragment>
                                    )) : null
                                }

                              </tbody>
                            </table>



                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                  {/* {
                    isMobile ?
                      <nav className="relative z-0 flex justify-end mt-5 w-76">
                        {
                          txTotal && txTotal > 10 ?
                            <ReactPaginate
                              previousLabel={'Prev'}
                              nextLabel={'Next'}
                              breakLabel={'...'}
                              breakClassName={'break-me'}
                              pageCount={txTotal / this.state.size}
                              marginPagesDisplayed={1}
                              pageRangeDisplayed={1}
                              onPageChange={this.handlePageClick}
                              containerClassName={'pagination'}
                              pageClassName={'page-cls'}
                              activeClassName={'active'}
                            />
                            : null}
                      </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                        {
                          txTotal && txTotal > 10 ?
                            <ReactPaginate
                              previousLabel={'Previous'}
                              nextLabel={'Next'}
                              breakLabel={'...'}
                              breakClassName={'break-me'}
                              pageCount={txTotal / this.state.size}
                              marginPagesDisplayed={3}
                              pageRangeDisplayed={3}
                              onPageChange={this.handlePageClick}
                              containerClassName={'pagination'}
                              pageClassName={'page-cls'}
                              activeClassName={'active'}
                            />
                            : null}
                      </nav>
                  } */}
                </div>
              </section>
            </div>


          </div>
        </div>


        <CreateAppSettingModal
          appsettingCreateModal={this.state.appsettingCreateModal}
          formField={this.state.formField}
          errorField={this.state.errorField}
          inputChange={this.inputChange}
          buyPackageSubmit={this.buyPackageSubmit}
          handleAppSeetingHideModal={this.handleAppSeetingHideModal}
        />

      </>

    );
  }
}

function mapStateToProps(state) {
  const { users, dashboard } = state;
  const { overview } = users ? users : {};
  const { setting } = overview ? overview : {};
  return {
    users,
    setting,
    dashboard
  };
}
export default connect(mapStateToProps)(packageupgradation);