import React, { Component } from 'react';
import { userActions } from '../../_actions';
import { connect } from 'react-redux';
import moment from 'moment';
import "jspdf-autotable";
import ReactPaginate from 'react-paginate';
import { isMobile } from "react-device-detect";
import LoadingOverlay from 'react-loading-overlay';
import './styles.css';
import DateRangePicker from 'rsuite/DateRangePicker';
// import { DateRangePicker } from 'rsuite';
// import { DateRangePicker } from 'react-date-range';
// import { Calendar } from 'react-date-range';
// import 'react-date-range/dist/styles.css'; // main style file
// import 'react-date-range/dist/theme/default.css'; // theme css file


class CoinMarket extends Component {

  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      size: 10,
      keyWord: "",
      pageNo: 1,
      formField: {},
      errorField: {},
      dateDetails: {
        txType: null,
        from: new Date(),
        to: new Date(),
      },
    }
  }

  componentDidMount() {

    let data = {
      // "txType": "SEND_RECEIVED",
      "keyWord": "",
      "pageNo": 1,
      "size": 10
    }

    this.props.dispatch(userActions.getCmcCoinList(data));
  }


  inputChange = (event) => {
    // console.log(event.target.name, event.target.value);
    // event.preventDefault();
    const { formField, errorField } = this.state;
    formField[event.target.name] = event.target.value;
    errorField[event.target.name] = "";
    // console.log(event.target.name, event.target.value);
    this.setState({ formField, errorField });
  }



  handlePageClick = (data) => {
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });
    let datatemp = {
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size,
    }
    this.props.dispatch(userActions.getCmcCoinList(datatemp));
  }


  render() {


    let { users } = this.props;
    let { txList, loading, cmcCoinTotal, cmcCoinItem } = users;
    // console.log("RENDER_____________cmcCoinItem:::::", cmcCoinItem);


    return (

      <>

        <div >
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <div className="bg-[#1f2837] w-full h-screen">
          <section>
            <div className='p-8'>
              <div className=' bg-slate-900 '>
                <h1 className="text-white text-xl py-2 px-4">Coin Market List</h1>

                

                <div className="overflow-hidden pb-2 mt-4 lg:p-6">
                  <div className="overflow-auto max-w-full ">
                    <div className="inline-block min-w-full  ">
                      <div className="overflow-hidden  ">

                        <table className="min-w-full border border-black  text-center">
                          <thead className="border border-black">
                            <tr className="border border-black text-center">
                              <th className="whitespace-nowrap border border-white text-center px-[49px] bg-[#790000] py-3 text-xs font-semibold text-white uppercase">S/n</th>
                              <th className="whitespace-nowrap border border-white text-center px-[49px] bg-[#790000] py-3 text-xs font-semibold text-white uppercase">name</th>
                              {/* <th className="whitespace-nowrap border border-white text-center px-[49px] bg-[#790000] py-3 text-xs font-semibold text-white uppercase">Image</th> */}
                              <th className="whitespace-nowrap border border-white text-center px-[49px] bg-[#790000] py-3 text-xs font-semibold text-white uppercase">Ticker</th>
                              <th className="whitespace-nowrap border border-white text-center px-[31px] py-3 bg-[#790000] text-xs font-semibold text-white capitalize">date</th>
                              <th className="whitespace-nowrap border border-white text-center px-[45px] bg-[#790000] py-3 text-xs font-semibold text-white capitalize">price</th>

                            </tr>
                          </thead>
                          <tbody>

                            {
                              cmcCoinItem && cmcCoinItem.length > 0 ?
                                cmcCoinItem.filter(
                                  element =>
                                    element.isDisable === false,
                                )
                                  .map((element, index) => (
                                    <>
                                      <tr className="border border-black text-center">
                                        <td className="whitespace-nowrap border border-gray-300 text-center px-[11.5px] py-3 text-xs font-semibold text-black bg-white uppercase bg-whj">{this.state.offset + index + 1}</td>
                                        <td className="whitespace-nowrap border border-gray-300 text-center px-[11.5px] py-3 text-xs font-semibold text-black bg-white uppercase bg-whj">{element && element.name ? element.name : "-"}</td>
                                        {/* <td className="whitespace-nowrap border border-gray-300 text-center px-[11.5px] py-3 text-xs font-semibold text-black bg-white uppercase bg-whj"><img src={element && element.imageLinkUrl ? element.imageLinkUrl : "-"}
                                        /></td> */}

                                        <td className="whitespace-nowrap border border-gray-300 text-center px-[11.5px] py-3 text-xs font-semibold text-black bg-white uppercase bg-whj">{element && element.ticker ? element.ticker : "-"}</td>
                                        <td className="whitespace-nowrap border border-gray-300 text-center px-[11.5px] py-3 text-xs font-semibold text-black bg-white uppercase bg-whj">{moment(new Date(parseInt(element && element.createdAt ? element.createdAt : "-"))).utcOffset("+05:30").format("DD-MM-YYYY HH:mm")}</td>
                                        <td className="whitespace-nowrap border border-gray-300 text-center px-[11.5px] py-3 text-xs font-semibold text-black bg-white uppercase bg-whj">{element && element.price ? element.price.toFixed(2) : "-"}</td>

                                      </tr>
                                    </>
                                  )) : null
                            }

                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  {
                    isMobile ?
                      <nav className="relative z-0 flex justify-end mt-5 w-76">
                        {
                          cmcCoinTotal && cmcCoinTotal > 10 ?
                            <ReactPaginate
                              previousLabel={'Prev'}
                              nextLabel={'Next'}
                              breakLabel={'...'}
                              breakClassName={'break-me'}
                              pageCount={cmcCoinTotal / this.state.size}
                              marginPagesDisplayed={0}
                              pageRangeDisplayed={2}
                              onPageChange={this.handlePageClick}
                              containerClassName={'pagination'}
                              pageClassName={'page-cls'}
                              activeClassName={'active'}
                            />
                            : null}
                      </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                        {
                          cmcCoinTotal && cmcCoinTotal > 10 ?
                            <ReactPaginate
                              previousLabel={'Previous'}
                              nextLabel={'Next'}
                              breakLabel={'...'}
                              breakClassName={'break-me'}
                              pageCount={cmcCoinTotal / this.state.size}
                              marginPagesDisplayed={3}
                              pageRangeDisplayed={3}
                              onPageChange={this.handlePageClick}
                              containerClassName={'pagination'}
                              pageClassName={'page-cls'}
                              activeClassName={'active'}
                            />
                            : null}
                      </nav>
                  }
                </div>
              </div>



            </div>
          </section>
        </div>
      </>

    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  const { overview } = users ? users : {};
  const { setting } = overview ? overview : {};
  return {
    users,
    setting
  };
}
export default connect(mapStateToProps)(CoinMarket);
