import React, { Component } from 'react';
import { userActions, dashboardActions, alertActions } from '../../_actions';
import { connect } from 'react-redux';
import moment from 'moment';
import "./App.css";
import "jspdf-autotable";
import ReactPaginate from 'react-paginate';
import { isMobile } from "react-device-detect";
import LoadingOverlay from 'react-loading-overlay';



class Transaction extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isSelectLevel: false,
      offset: 0,
      size: 10,
      keyWord: "",
      pageNo: 1,
      formField: {},
      errorField: {},
      currentLevel: {},
      currentTable: "USER",
      dateDetails: {
        from: new Date(),
        to: new Date(),
      },
    }
  }

  componentDidMount() {

    let data = {
      "txType": ["MINING"],
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.size
    }
    let data1 = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.size
    }
    // this.props.dispatch(dashboardActions.getTxAllMultiTeam(data));
    this.props.dispatch(userActions.getAllLevelMining(data));
    this.props.dispatch(dashboardActions.getMiningPftShareList(data1));
  }


  setTableData = (data) => {
    console.log("datadata____________--!", data);

    this.setState({ currentTable: data });


    if (this.state.isSelectLevel) {
      if (data === "USER") {
        let level = {
          "id": this.state.currentLevel && this.state.currentLevel.id ? this.state.currentLevel.id : null,
          // "txType": "MINING",
          "keyWord": "",
          "pageNo": 1,
          "size": 10,
          "level": this.state.level
        }
        console.log("if____________--!", level);
        this.props.dispatch(userActions.getLevelByIdUser(level));
      } else if (data === "PACKAGE") {
        let level = {
          "id": this.state.currentLevel && this.state.currentLevel.id ? this.state.currentLevel.id : null,
          "keyWord": "",
          "pageNo": 1,
          "size": 10,
          "level": this.state.level
        }
        console.log("else____________--!", level);

        this.props.dispatch(userActions.getLevelByIdUserPackage(level));
      }
      else {
        let level = {
          "id": this.state.currentLevel && this.state.currentLevel.id ? this.state.currentLevel.id : null,
          "keyWord": "",
          "pageNo": 1,
          "size": 10,
          txType: "MINING",
          "level": this.state.level
        }
        console.log("else____________--!", level);

        this.props.dispatch(userActions.getLevelByIdUserTx(level));
      }

    } else {

      this.props.dispatch(alertActions.error("Please Select Level !"));

    }




  }


  getLevelByIdUserHandle = (data) => {
    console.log("eeeeeeeeeeeeee", data);
    this.setState({ currentLevel: data, isSelectLevel: true });
    let level = {
      "id": data.id,
      // "txType": "MINING",
      "keyWord": "",
      "pageNo": 1,
      "size": 10,
      "level": this.state.level
    }
    this.props.dispatch(userActions.getLevelByIdUser(level));

  }


  transactionSearchSubmit = (e) => {
    e.preventDefault();
    if (this.handleValidation()) {
      let reqData = {
        "txType": "SEND_RECEIVED",
        "keyWord": "",
        "pageNo": 1,
        "size": 10,
        "from": (this.state.dateDetails.from),
        "to": (this.state.dateDetails.to),
      }
      this.props.dispatch(userActions.transactionSearch(reqData, this.props));
    }
  }

  getTx = (e) => {
    e.preventDefault();
    let reqData = {
      "txType": "RECEIVED_FROM_ADMIN",
      "keyWord": "",
      "pageNo": 1,
      "size": 10,
      "from": (this.state.dateDetails.from),
      "to": (this.state.dateDetails.to),
    }
    this.props.dispatch(userActions.getTx(reqData, this.props));
    // }
  }


  getDate = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let dateDetails = this.state.dateDetails;
    dateDetails[name] = value;
    this.setState({ dateDetails });
  }

  inputChange = (event) => {
    const { formField, errorField } = this.state;
    formField[event.target.name] = event.target.value;
    errorField[event.target.name] = "";
    this.setState({ formField, errorField });
  }



  handlePageClick = (data) => {
    console.log("data___________", data)
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });
    // let datatemp = {
    //   "txType": "MINING",
    //   "keyWord": this.state.keyWord,
    //   "pageNo": data.selected + 1,
    //   "size": this.state.size,
    // }
    // this.props.dispatch(userActions.getTx(datatemp));
    if (this.state.currentTable === "USER") {
      console.log('11111111111111111111111');
      let level = {
        "id": this.state.currentLevel && this.state.currentLevel.id ? this.state.currentLevel.id : null,
        // "txType": "MINING",
        "keyWord": "",
        "pageNo": data.selected + 1,
        "size": 10,
        "level": this.state.level
      }
      console.log("if____________--!", level);
      this.props.dispatch(userActions.getLevelByIdUser(level));
    } else {
      let level = {
        "id": this.state.currentLevel && this.state.currentLevel.id ? this.state.currentLevel.id : null,
        "keyWord": "",
        "pageNo": data.selected + 1,
        "size": 10,
        "level": this.state.level
      }
      console.log("else____________--!", level);

      this.props.dispatch(userActions.getLevelByIdUserPackage(level));
    }


  }

  handleValidation = () => {
    let errorField = {};
    let formIsValid = true;
    this.setState({ errorField: errorField });
    return formIsValid;
  }

  handleFromDate = (event) => {
    const { dateDetails } = this.state;
    dateDetails["from"] = event.target.value;
    this.setState({ dateDetails });
  }


  handleToDate = (event) => {
    const { dateDetails } = this.state;
    dateDetails["to"] = event.target.value;
    this.setState({ dateDetails });
  }

  render() {
    let { users, dashboard } = this.props;
    let { loading, getAllLevelMining, getLevelByIdUserTx, getLevelByIdUserPackage, getLevelByIdUserPackageTotal, getLevelByIdUser, txTotal, getLevelByIdUserTxTotal } = users;
    let { getTxAllMultiTeam, miningPftShareList } = dashboard;
    console.log("RENDER_____________txTotal___:::::", txTotal);
    console.log('999999999999999999999999999999999999999999', this.state.currentTable);

    return (

      <>

        <div >
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <div className="bg-[#1f2837] w-full h-screen overflow-y-auto overflow-x-hidden">
          <div>
            <div className='p-6'>
              <section className='w-full flex justify-center bg-slate-800 py-4'>
                <div className='grid 2xl:grid-cols-1 gap-4 items-center px-4 md:w-4/6 w-full'>

                  <div className='w-full space-y-6'>
                    <div className="bg-[#2A3441] shadow-xl w-full">
                      <h1 className='py-4 text-white text-xl text-medium pl-4'>Team Mining Profit</h1>
                      <div className='grid md:grid-cols-3 gap-4 px-2 py-2 rounded-b-md'>
                        {
                          miningPftShareList && miningPftShareList.length > 0 ?
                            miningPftShareList.map((element, index) => (
                              <React.Fragment key={index}>
                                <div className='bg-black p-6 rounded-md relative my-2'>
                                  <div className='rounded-xl overflow-hidden -mt-10 ' >
                                    <div className='bg-yellow-500'>
                                      <h2 className='text-center xl:text-md text-sm py-2 '>Percentage Distribution</h2>
                                    </div>
                                    <div className='w-14 bg-yellow-500 rounded-b-full mx-auto'>
                                      <h2 className='text-center text-lg font-semibold p-0.5'>{element && element.percentDist ? element.percentDist : "-"}%</h2>
                                    </div>
                                    {
                                      (element && element.from) === (element && element.to) ?
                                        <>
                                          <h2 className='text-white text-center font-semibold h-24 flex items-center justify-center'>Level {element && element.from ? element.from : "-"} </h2>
                                        </> :
                                        <>
                                          <h2 className='text-white text-center font-semibold h-24 flex items-center justify-center'>Level {element && element.from ? element.from : "-"}- {element && element.to ? element.to : "-"}</h2>
                                        </>
                                    }

                                    <div className=' mx-auto h-8 bg-yellow-500'>
                                    </div>
                                  </div>
                                </div>
                              </React.Fragment>
                            )) : null
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className=' '>
                <div className="w-full mx-auto px-4 py-4">
                  <div className="grid xl:grid-cols-9 md:grid-cols-3 grid-cols-2 gap-2">
                    {
                      getAllLevelMining && getAllLevelMining.length > 0 ?
                        getAllLevelMining.map((element, index) => (
                          <React.Fragment key={index}>

                            <div className="w-full cursor-pointer" onClick={() => this.getLevelByIdUserHandle(element)}>
                              <div className={element.id === this.state.currentLevel.id ? "px-3 py-1 text-center border border-gray-500 bg-red-800" : "px-3 py-1 text-center border border-gray-500 bg-slate-800"}>
                                <h1 className="text-white text-base font-bold leading-normal capitalize">Level: {element && element.level ? element.level : 0}</h1>
                              </div>
                              <div className="px-1 flex justify-between py-1 border border-gray-500 bg-slate-800">
                                <span className='cursor-pointer targetablepx-4 tooltip relative'>
                                  <p className='bg-slate-900 text-white rounded-lg px-1.5 text-sm'>PC: {element && element.packageCount ? element.packageCount : 0}</p>
                                  <span class='tooltip-text bg-red-900 p-8 -mt-20 text-white text-sm rounded'>PackageCount:PC: {element && element.packageCount ? element.packageCount : 0}</span>
                                </span>
                                <span className='cursor-pointer targetablepx-4 tooltip relative'>
                                  <p className='bg-slate-900 text-white rounded-lg px-1.5 text-sm'>PCS: {element && element.packageCountSum ? element.packageCountSum : 0}</p>
                                  <span class='tooltip-text bg-red-900 p-8 -mt-20 text-white text-sm rounded'>PackageCountSum: {element && element.packageCountSum ? element.packageCountSum : 0}</span>
                                </span>
                              </div>
                            </div>

                          </React.Fragment>
                        )) : null
                    }
                  </div>

                </div>
              </section>

              <div className="flex space-x-4 mb-4 px-4">


                {/* {
                  this.state.currentTable===
                } */}



                <div className={` px-3 py-2 text-white ${this.state.currentTable == "USER" ? 'bg-red-700' : 'bg-green-800'}`} onClick={() => this.setTableData("USER")}>
                  <h2 className='text-center xl:text-md text-sm'>User</h2>
                </div>

                <div className={` px-3 py-2 text-white ${this.state.currentTable == "PACKAGE" ? 'bg-red-700' : 'bg-green-800'}`} onClick={() => this.setTableData("PACKAGE")}>
                  <h2 className='text-center xl:text-md text-sm'>Package</h2>
                </div>

                <div className={` px-3 py-2 text-white ${this.state.currentTable == "USERTX" ? 'bg-red-700' : 'bg-green-800'}`} onClick={() => this.setTableData("USERTX")}>
                  <h2 className='text-center xl:text-md text-sm'>User Transaction</h2>
                </div>
              </div>

              {
                this.state.currentTable === "USER" ?
                  <section className=' bg-slate-900 pb-2 px-2'>
                    <h1 className="text-white text-xl pt-4 px-4">User List </h1>
                    <div className="overflow-hidden pb-2 lg:p-6">
                      <div className="overflow-auto max-w-full ">
                        <div className="inline-block min-w-full  ">
                          <div className="overflow-hidden  ">
                            <table className="min-w-full border border-black  text-center">
                              <thead className="border border-black">
                                <tr className="border border-black text-center">
                                  <th scope="col" className="whitespace-nowrap border border-white text-center px-[49px] bg-[#790000] py-3 text-xs font-semibold text-white uppercase">S/n</th>
                                  <th scope="col" className="whitespace-nowrap border border-white text-center px-[49px] bg-[#790000] py-3 text-xs font-semibold text-white capitalize">Email</th>
                                  <th scope="col" className="whitespace-nowrap border border-white text-center px-[49px] bg-[#790000] py-3 text-xs font-semibold text-white capitalize">User Name</th>
                                  <th scope="col" className="whitespace-nowrap border border-white text-center px-[45px] bg-[#790000] py-3 text-xs font-semibold text-white capitalize">RefCode</th>
                                  {/* <th scope="col" className="whitespace-nowrap border border-white text-center px-[22px] py-3 bg-[#790000] text-xs font-semibold text-white capitalize">amount</th> */}
                                  <th scope="col" className="whitespace-nowrap border border-white text-center px-[38px] py-3 bg-[#790000] text-xs font-semibold text-white capitalize">date</th>
                                </tr>
                              </thead>
                              <tbody>

                                {
                                  getLevelByIdUser && getLevelByIdUser.length > 0 ?
                                    getLevelByIdUser.map((element, index) => (
                                      <React.Fragment key={index}>
                                        <tr className="border border-black text-center">
                                          <td className="whitespace-nowrap border border-gray-300 text-center px-[11.5px] py-3 text-xs font-semibold text-black bg-white uppercase bg-whj">{this.state.offset + index + 1}</td>
                                          <td className="whitespace-nowrap border border-gray-300 text-center px-[11.5px] py-3 text-xs font-semibold text-black bg-white uppercase bg-whj">{element && element.email && element.email ? element.email : "-"}</td>
                                          <td className="whitespace-nowrap border border-gray-300 text-center px-[11.5px] py-3 text-xs font-semibold text-black bg-white uppercase bg-whj">{element && element && element.userName ? element.userName : "-"}</td>
                                          <td className="whitespace-nowrap border border-gray-300 text-center px-[11.5px] py-3 text-xs font-semibold text-black bg-white uppercase bg-whj">{element && element.refCode ? element.refCode : "-"}</td>
                                          {/* <td className="whitespace-nowrap border border-gray-300 text-center px-[11.5px] py-3 text-xs font-semibold text-black bg-white uppercase bg-whj">{element && element.amount ? element.amount : "-"}</td> */}
                                          <td className="whitespace-nowrap border border-gray-300 text-center px-[11.5px] py-3 text-xs font-semibold text-black bg-white uppercase bg-whj">{moment(new Date(parseInt(element && element.createdAt ? element.createdAt : "-"))).utcOffset("+05:30").format("DD-MM-YYYY HH:mm")}</td>
                                        </tr>
                                      </React.Fragment>
                                    )) : null
                                }

                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>

                    {
                      isMobile ?
                        <nav className="relative z-0 flex justify-end mt-2 w-76">
                          {
                            txTotal && txTotal > 10 ?
                              <ReactPaginate
                                previousLabel={'Prev'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={txTotal / this.state.size}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={1}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav> : <nav className="relative z-0 flex justify-end mt-2 w-76">
                          {
                            txTotal && txTotal > 10 ?
                              <ReactPaginate
                                previousLabel={'Previous'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={txTotal / this.state.size}
                                marginPagesDisplayed={3}
                                pageRangeDisplayed={3}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav>
                    }
                  </section>
                  : null}

              {
                this.state.currentTable === "PACKAGE" ?
                  <section className=' bg-slate-900 pb-2 px-2'>
                    <h1 className="text-white text-xl pt-4 px-4">Team Mining Profits Transaction Package</h1>
                    <div className="overflow-hidden pb-2 lg:p-6">
                      <div className="overflow-auto max-w-full ">
                        <div className="inline-block min-w-full  ">
                          <div className="overflow-hidden  ">
                            <table className="min-w-full border border-black  text-center">
                              <thead className="border border-black">
                                <tr className="border border-black text-center">
                                  <th scope="col" className="whitespace-nowrap border border-white text-center px-[49px] bg-[#790000] py-3 text-xs font-semibold text-white uppercase">S/n</th>
                                  <th scope="col" className="whitespace-nowrap border border-white text-center px-[49px] bg-[#790000] py-3 text-xs font-semibold text-white capitalize">Received From</th>
                                  <th scope="col" className="whitespace-nowrap border border-white text-center px-[49px] bg-[#790000] py-3 text-xs font-semibold text-white capitalize">package name</th>
                                  <th scope="col" className="whitespace-nowrap border border-white text-center px-[49px] bg-[#790000] py-3 text-xs font-semibold text-white capitalize">package reward</th>
                                  <th scope="col" className="whitespace-nowrap border border-white text-center px-[49px] bg-[#790000] py-3 text-xs font-semibold text-white capitalize">amount</th>
                                  <th scope="col" className="whitespace-nowrap border border-white text-center px-[49px] bg-[#790000] py-3 text-xs font-semibold text-white capitalize">totalProfit</th>
                                  <th scope="col" className="whitespace-nowrap border border-white text-center px-[38px] py-3 bg-[#790000] text-xs font-semibold text-white capitalize">date</th>
                                </tr>
                              </thead>
                              <tbody>

                                {
                                  getLevelByIdUserPackage && getLevelByIdUserPackage.length > 0 ?
                                    getLevelByIdUserPackage.map((element, index) => (
                                      <React.Fragment key={index}>
                                        <tr className="border border-black text-center">
                                          <td className="whitespace-nowrap border border-gray-300 text-center px-[11.5px] py-3 text-xs font-semibold text-black bg-white uppercase bg-whj">{this.state.offset + index + 1}</td>

                                          <td className="whitespace-nowrap border border-gray-300 text-center px-[11.5px] py-3 text-xs font-semibold text-black bg-white uppercase bg-whj">{element && element.userId && element.userId.userName ? element.userId.userName : "-"}</td>

                                          <td className="whitespace-nowrap border border-gray-300 text-center px-[11.5px] py-3 text-xs font-semibold text-black bg-white uppercase bg-whj">{element && element.packageId && element.packageId.name ? element.packageId.name : "-"}</td>

                                          <td className="whitespace-nowrap border border-gray-300 text-center px-[11.5px] py-3 text-xs font-semibold text-black bg-white uppercase bg-whj">{element && element.packageId && element.packageId.reward ? element.packageId.reward : "-"}</td>

                                          <td className="whitespace-nowrap border border-gray-300 text-center px-[11.5px] py-3 text-xs font-semibold text-black bg-white uppercase bg-whj">{element && element.amount ? element.amount : 0}</td>

                                          <td className="whitespace-nowrap border border-gray-300 text-center px-[11.5px] py-3 text-xs font-semibold text-black bg-white uppercase bg-whj">{element && element.totalProfit ? element.totalProfit : 0}</td>

                                          <td className="whitespace-nowrap border border-gray-300 text-center px-[11.5px] py-3 text-xs font-semibold text-black bg-white uppercase bg-whj">{moment(new Date(parseInt(element && element.createdAt ? element.createdAt : "-"))).utcOffset("+05:30").format("DD-MM-YYYY HH:mm")}</td>
                                        </tr>
                                      </React.Fragment>
                                    )) : null
                                }

                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>

                    {
                      isMobile ?
                        <nav className="relative z-0 flex justify-end mt-2 w-76">
                          {
                            txTotal && txTotal > 10 ?
                              <ReactPaginate
                                previousLabel={'Prev'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={txTotal / this.state.size}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={1}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav> : <nav className="relative z-0 flex justify-end mt-2 w-76">
                          {
                            txTotal && txTotal > 10 ?
                              <ReactPaginate
                                previousLabel={'Previous'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={txTotal / this.state.size}
                                marginPagesDisplayed={3}
                                pageRangeDisplayed={3}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav>
                    }
                  </section>

                  : null}
              {
                this.state.currentTable === "USERTX" ?
                  <section className='bg-slate-900 pb-2 px-2'>
                    <h1 className="text-white text-xl pt-4 px-4">Team Welcome Bonus User Transaction</h1>

                    <div className="overflow-hidden pb-2 lg:p-6">
                      <div className="overflow-auto max-w-full ">

                        <div className="inline-block min-w-full  ">
                          <div className="overflow-hidden  ">


                            <table className="min-w-full border border-black  text-center">
                              <thead className="border border-black">
                                <tr className="border border-black text-center">
                                  <th scope="col" className="whitespace-nowrap border border-white text-center px-[49px] bg-[#790000] py-3 text-xs font-semibold text-white uppercase">S/n</th>
                                  <th scope="col" className="whitespace-nowrap border border-white text-center px-[49px] bg-[#790000] py-3 text-xs font-semibold text-white capitalize">userName</th>
                                  {/* <th scope="col" className="whitespace-nowrap border border-white text-center px-[49px] bg-[#790000] py-3 text-xs font-semibold text-white capitalize">package name</th>
                                  <th scope="col" className="whitespace-nowrap border border-white text-center px-[49px] bg-[#790000] py-3 text-xs font-semibold text-white capitalize">package reward</th> */}
                                  <th scope="col" className="whitespace-nowrap border border-white text-center px-[49px] bg-[#790000] py-3 text-xs font-semibold text-white capitalize">amount</th>
                                  {/* <th scope="col" className="whitespace-nowrap border border-white text-center px-[49px] bg-[#790000] py-3 text-xs font-semibold text-white capitalize">totalProfit</th> */}
                                  {/* <th scope="col" className="whitespace-nowrap border border-white text-center px-[49px] bg-[#790000] py-3 text-xs font-semibold text-white capitalize">Received From</th> */}
                                  <th scope="col" className="whitespace-nowrap border border-white text-center px-[38px] py-3 bg-[#790000] text-xs font-semibold text-white capitalize">date</th>
                                </tr>
                              </thead>
                              <tbody>

                                {
                                  getLevelByIdUserTx && getLevelByIdUserTx.length > 0 ?
                                    getLevelByIdUserTx.map((element, index) => (
                                      <>
                                        <tr className="border border-black text-center">
                                          <td className="whitespace-nowrap border border-gray-300 text-center px-[11.5px] py-3 text-xs font-semibold text-black bg-white uppercase bg-whj">{this.state.offset + index + 1}</td>

                                          <td className="whitespace-nowrap border border-gray-300 text-center px-[11.5px] py-3 text-xs font-semibold text-black bg-white uppercase bg-whj">{element && element.userId && element.userId.userName ? element.userId.userName : "-"}</td>

                                          {/* <td className="whitespace-nowrap border border-gray-300 text-center px-[11.5px] py-3 text-xs font-semibold text-black bg-white uppercase bg-whj">{element && element.packageId && element.packageId.name ? element.packageId.name : "-"}</td>

                                          <td className="whitespace-nowrap border border-gray-300 text-center px-[11.5px] py-3 text-xs font-semibold text-black bg-white uppercase bg-whj">{element && element.packageId && element.packageId.reward ? element.packageId.reward : "-"}</td> */}

                                          <td className="whitespace-nowrap border border-gray-300 text-center px-[11.5px] py-3 text-xs font-semibold text-black bg-white uppercase bg-whj">{element && element.amount ? element.amount : "-"}</td>

                                          {/* <td className="whitespace-nowrap border border-gray-300 text-center px-[11.5px] py-3 text-xs font-semibold text-black bg-white uppercase bg-whj">{element && element.totalProfit ? element.totalProfit : "-"}</td> */}
                                          {/* <td className="whitespace-nowrap border border-gray-300 text-center px-[11.5px] py-3 text-xs font-semibold text-black bg-white uppercase bg-whj">{element && element.userRecId ? element.userRecId.userName : "-"}</td> */}

                                          <td className="whitespace-nowrap border border-gray-300 text-center px-[11.5px] py-3 text-xs font-semibold text-black bg-white uppercase bg-whj">{moment(new Date(parseInt(element && element.createdAt ? element.createdAt : "-"))).utcOffset("+05:30").format("DD-MM-YYYY HH:mm")}</td>
                                        </tr>
                                      </>
                                    )) : null
                                }

                              </tbody>
                            </table>
                          </div>
                        </div>


                      </div>

                    </div>
                    {
                      isMobile ?
                        <nav className="relative z-0 flex justify-end mt-2 w-76">
                          {
                            getLevelByIdUserTxTotal && getLevelByIdUserTxTotal > 10 ?
                              <ReactPaginate
                                previousLabel={'Prev'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={getLevelByIdUserTxTotal / this.state.size}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={1}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav> : <nav className="relative z-0 flex justify-end mt-2 w-76">
                          {
                            getLevelByIdUserTxTotal && getLevelByIdUserTxTotal > 10 ?
                              <ReactPaginate
                                previousLabel={'Previous'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={getLevelByIdUserTxTotal / this.state.size}
                                marginPagesDisplayed={3}
                                pageRangeDisplayed={3}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav>
                    }
                  </section>
                  : null}
            </div>
          </div>
        </div>
      </>

    );
  }
}

function mapStateToProps(state) {
  const { users, dashboard } = state;
  const { overview } = users ? users : {};
  const { setting } = overview ? overview : {};
  // console.log("dashboarddashboarddashboarddashboarddashboarddashboarddashboarddashboarddashboarddashboard", dashboard);
  return {
    users,
    dashboard,
    setting
  };
}
export default connect(mapStateToProps)(Transaction);
