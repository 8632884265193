import React, { Component } from 'react';
import { userActions, dashboardActions } from '../../_actions';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment';
import Header from '../../components/Header/Header';
import "./App.css";
import "jspdf-autotable";
// import QRCode from 'qrcode.react';
import ReactPaginate from 'react-paginate';
// import jsPDF from "jspdf";
import { isMobile } from "react-device-detect";
// import { CSVLink } from "react-csv";
// import SubHeader from '../../components/Header/SubHeader';
// import { FaDollarSign } from "react-icons/fa";
// import { AiOutlineCheckCircle } from "react-icons/ai";
// import { HiArrowCircleDown, HiArrowCircleUp } from "react-icons/hi";
// import { BsChevronDown } from "react-icons/bs";


class Transaction extends Component {

  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      size: 10,
      keyWord: "",
      pageNo: 1,
      formField: {},
      errorField: {},
      dateDetails: {
        from_date: new Date(),
        to_date: new Date(),
      },
    }
  }

  componentDidMount() {

    // let data = {
    //   "txType": "SEND_RECEIVED",
    //   "keyWord": "",
    //   "pageNo": 1,
    //   "size": 10
    // }

    // this.props.dispatch(userActions.getTx(data));
    this.props.dispatch(dashboardActions.getUserPackagesTeam());
  }

  transactionSearchSubmit = (e) => {
    e.preventDefault();
    if (this.handleValidation()) {
      let reqData = {
        "txType": "SEND_RECEIVED",
        "keyWord": "",
        "pageNo": 1,
        "size": 10,
        // "coinId": this.state.formField && this.state.formField.coinId ? this.state.formField.coinId : null,
        "from": (this.state.dateDetails.from_date),
        "to": (this.state.dateDetails.to_date),
      }
      // console.log('transactionSearch_____******', reqData);
      // console.log('this.state.dateDetails******', this.state.dateDetails);
      this.props.dispatch(userActions.transactionSearch(reqData, this.props));
    }
  }


  getDate = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let dateDetails = this.state.dateDetails;
    dateDetails[name] = value;
    // console.log(name, value);
    this.setState({ dateDetails });
  }

  inputChange = (event) => {
    // console.log(event.target.name, event.target.value);
    // event.preventDefault();
    const { formField, errorField } = this.state;
    formField[event.target.name] = event.target.value;
    errorField[event.target.name] = "";
    // console.log(event.target.name, event.target.value);
    this.setState({ formField, errorField });
  }


  handleFromDate = (event) => {
    // console.log(event.target.name, event.target.value);
    // event.preventDefault();
    const { dateDetails } = this.state;
    dateDetails["from_date"] = event.target.value;

    this.setState({ dateDetails });
  }


  handleToDate = (event) => {
    // console.log(event.target.name, event.target.value);
    // event.preventDefault();
    const { dateDetails } = this.state;
    dateDetails["to_date"] = event.target.value;

    this.setState({ dateDetails });
  }

  handlePageClick = (data) => {
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });
    let datatemp = {
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size,
      "txType": "SEND_RECEIVED",
    }
    this.props.dispatch(userActions.getTx(datatemp));
  }

  handleValidation = () => {
    let formField = this.state.formField;
    let errorField = {};
    let formIsValid = true;

    // console.log('errorsIndex_errorsIndex_:::::', errorField);
    this.setState({ errorField: errorField });
    return formIsValid;
  }



  render() {



    let { users, dashboard } = this.props;
    let { txList, txTotal } = users;
    let { UserPackagesTeam, loading } = dashboard;

    // console.log("RENDER__UserPackagesTeam:::::", UserPackagesTeam);
    // // console.log("RENDER_formField:::::", this.state.formField);


    return (

      <>
        <div className="bg-[#1f2837] w-full h-screen overflow-y-auto">
          <section>
            <div className='p-6'>
              <div className='w-full flex justify-center bg-slate-800 pt-6'>

              </div>
              <div className=' bg-slate-900 pb-6 px-2'>
                <h1 className="text-white text-xl py-2 px-4">User Packages Team</h1>

                <div className="overflow-hidden pb-2 lg:p-6">
                  <div className="overflow-auto max-w-full h-screen">

                    <div className="inline-block min-w-full overflow-y-auto">
                      <div className="overflow-hidden">

                        <table className="min-w-full border border-black text-center">
                          <thead className="border border-black">
                            <tr className="border border-black text-center">
                              <th scope="col" className="whitespace-nowrap border border-white text-center px-[49px] bg-[#790000] py-3 text-xs font-semibold text-white uppercase">User Name</th>
                              <th scope="col" className="whitespace-nowrap border border-white text-center px-[31px] py-3 bg-[#790000] text-xs font-semibold text-white capitalize">Email</th>
                              <th scope="col" className="whitespace-nowrap border border-white text-center px-[42px] py-3 bg-[#790000] text-xs font-semibold text-white capitalize">Package Name</th>
                              <th scope="col" className="whitespace-nowrap border border-white text-center px-[45px] bg-[#790000] py-3 text-xs font-semibold text-white capitalize">Package Reward</th>
                              <th scope="col" className="whitespace-nowrap border border-white text-center px-[22px] py-3 bg-[#790000] text-xs font-semibold text-white capitalize">Amount</th>
                              <th scope="col" className="whitespace-nowrap border border-white text-center px-[38px] py-3 bg-[#790000] text-xs font-semibold text-white capitalize">Date</th>
                            </tr>
                          </thead>
                          <tbody>




                            {
                              UserPackagesTeam && UserPackagesTeam.length > 0 ?
                                UserPackagesTeam.map((element, index) => (
                                  <React.Fragment key={index}>

                                    <tr className="border border-black text-center">
                                      <th scope="col" className="whitespace-nowrap border border-gray-300 text-center px-[11.5px] py-3 text-xs font-semibold text-black bg-white uppercase bg-whj">{element && element.userId && element.userId.userName ? element.userId.userName : "-"}</th>
                                      <th scope="col" className="whitespace-nowrap border border-gray-300 text-center px-[55px] py-3 text-xs font-semibold text-black bg-white capitalize bg-whj">{element && element.userId && element.userId.email ? element.userId.email : "-"}</th>
                                      <th scope="col" className="whitespace-nowrap border border-gray-300 text-center px-[28px] py-3 text-xs font-semibold text-black bg-white  capitalize bg-whj">{element && element.packageId && element.packageId.name ? element.packageId.name : "-"}</th>
                                      <th scope="col" className="whitespace-nowrap border border-gray-300 text-center px-[25px] py-3 text-xs font-semibold text-black bg-white capitalize bg-whj">{element && element.packageId && element.packageId.reward ? element.packageId.reward : "-"}</th>
                                      <th scope="col" className="whitespace-nowrap border border-gray-300 text-center px-[51px] py-3 text-xs font-semibold text-black bg-white capitalize bg-whj">{element && element.amount ? element.amount : "-"}</th>
                                      <th scope="col" className="whitespace-nowrap border border-gray-300 text-center px-[32px] py-3 text-xs font-semibold text-black bg-white capitalize bg-whj">{moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("YYYY-MM-DD HH:mm")}</th>
                                    </tr>

                                  </React.Fragment>
                                )) : null
                            }



                          </tbody>
                        </table>


                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </section>
        </div>
      </>

    );
  }
}

function mapStateToProps(state) {
  const { users, dashboard } = state;
  const { overview } = users ? users : {};
  const { setting } = overview ? overview : {};
  return {
    users,
    setting,
    dashboard
  };
}
export default connect(mapStateToProps)(Transaction);
