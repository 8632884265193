import React, { Component } from 'react';
import { userActions } from '../../_actions';
import { connect } from 'react-redux';
import moment from 'moment';
import "jspdf-autotable";
import ReactPaginate from 'react-paginate';
import { isMobile } from "react-device-detect";
import LoadingOverlay from 'react-loading-overlay';
import './styles.css';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file


class Transaction extends Component {

  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      size: 10,
      keyWord: "",
      pageNo: 1,

      offsetOverflow: 0,
      sizeOverflow: 10,
      keyWordOverflow: "",
      pageNoOverflow: 1,
      formField: {},
      errorField: {},
      dateDetails: {
        txType: null,
        from: Date.now(),
        to: Date.now(),
      },
    }
  }


  componentDidMount() {

    let data = {
      // "txType": "SEND_RECEIVED",
      "keyWord": "",
      "pageNo": 1,
      "size": 10,

    }

    this.props.dispatch(userActions.getTx(data));
    this.props.dispatch(userActions.getUserTxListOverFlow(data));
  }

  transactionSearchSubmit = (e) => {
    e.preventDefault();
    if (this.handleValidation()) {
      let reqData = {
        "txType": this.state.txType,
        "keyWord": "",
        "pageNo": 1,
        "size": 10,
        "from": this.state.formField.from,
        "to": this.state.formField.to,
      }
      // console.log('this.state.dateDetails******', this.state.reqData);
      this.props.dispatch(userActions.getTx(reqData, this.props));
    }
  }
  inputTypeChange = (e) => {
    e.preventDefault();
    let { value } = e.target;
    let data3 = {
      txType: value === "ALL" ? null : value,
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.size,
      "from": this.state.formField.from,
      "to": this.state.formField.to,
    }
    // console.log("___________data3", data3)
    this.props.dispatch(userActions.getTx(data3));
    if (value === "ALL") {
      this.setState({ txType: null })
    } else {
      this.setState({ txType: value })
    }
  }

  getTx = (e) => {
    e.preventDefault();
    // if (this.handleValidation()) {
    let reqData = {
      "txType": this.state.txType,
      "keyWord": "",
      "pageNo": 1,
      "size": 10,
      "from": (this.state.dateDetails.from),
      "to": (this.state.dateDetails.to),
    }

    // console.log("getTx_______________:", reqData);

    this.props.dispatch(userActions.getTx(reqData, this.props));
    // }
  }


  getDate = (e) => {
    e.preventDefault();
    // console.log("1111111111111111111111")
    let { name, value } = e.target;
    // console.log("222222222222222", name, value)
    let dateDetails = this.state.dateDetails;
    dateDetails[name] = value;
    // console.log(name, value);
    this.setState({ dateDetails });
  }

  inputChange = (event) => {
    // console.log(event.target.name, event.target.value);
    // event.preventDefault();
    const { formField, errorField } = this.state;
    formField[event.target.name] = event.target.value;
    errorField[event.target.name] = "";
    // console.log(event.target.name, event.target.value);
    this.setState({ formField, errorField });
  }



  handlePageClick = (data) => {
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });
    let datatemp = {
      txType: this.state.txType ? this.state.txType : null,
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size,
    }
    this.props.dispatch(userActions.getTx(datatemp));
  }

  handlePageClickOverflow = (data) => {
    let offset = Math.ceil(data.selected * this.state.sizeOverflow);
    this.setState({ offsetOverflow: offset, page: data.selected });
    let dataTemp = {
      // overflowTxList: this.state.overflowTxList ? this.state.overflowTxList : null,
      "keyWord": this.state.keyWordOverflow,
      "pageNo": data.selected + 1,
      "size": this.state.sizeOverflow,
    }
    // console.log('overflowTxListoverflowTxList_________', this.state.overflowTxList);
    this.props.dispatch(userActions.getUserTxListOverFlow(dataTemp));
  }

  handleValidation = () => {
    // let formField = this.state.formField;
    let errorField = {};
    let formIsValid = true;

    // console.log('errorsIndex_errorsIndex_:::::', errorField);
    this.setState({ errorField: errorField });
    return formIsValid;
  }

  handleFromDate = (event) => {
    // console.log(event.target.name, event.target.value);
    // event.preventDefault();
    const { dateDetails } = this.state;
    dateDetails["from"] = event.target.value;

    this.setState({ dateDetails });
  }
  handleToDateMultiDate = (event) => {
    // console.log("eventevent  ", event);
    event.preventDefault();
    // console.log(" event.target  ", event.target);
    // // console.log(event.target.name, event.target.value);
    // // event.preventDefault();
    // const { dateDetails } = this.state;
    // dateDetails["to"] = event.target.value;

    // this.setState({ dateDetails });
  }

  handleToDate = (event) => {
    // console.log(event.target.name, event.target.value);
    // event.preventDefault();
    const { dateDetails } = this.state;
    dateDetails["to"] = event.target.value;

    this.setState({ dateDetails });
  }
  render() {


    // const selectionRange = {
    //   startDate: new Date(),
    //   endDate: new Date(),
    //   key: 'selection',
    // }


    let { users } = this.props;
    let { txList, loading, txTotal, overflowTxList, overflowTxTotal } = users;
    // console.log("RENDER_txTotaltxTotal:::::", txTotal);
    // console.log("RENDER_____________txList__:::::", txList);
    // // console.log("RENDER_formField:::::", this.state.formField);
    console.log("overflowTxList_____________RENDER_______", overflowTxList);
    console.log("overflowTxTotal_____________RENDER_______", overflowTxTotal);


    return (

      <>

        <div >
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <div className="bg-[#1f2837] w-full h-screen overflow-y-auto">
          <section>
            <div className='overflow-hidden'>
              <div className='overflow-y-auto'>
                <div className='p-8'>
                  <div className=' bg-slate-900 '>
                    <h1 className="text-white text-xl py-2 px-4">Transaction History</h1>

                    <div className='md:flex justify-between items-end md:space-x-4 px-8'>

                      <div className="relative w-48 border border-gray-300 text-gray-800 bg-white shadow-lg appearance-none">
                        <label for="frm-whatever" className="sr-only">My field</label>
                        <select className="appearance-none w-full p-2 bg-white text-sm font-normal" onChange={this.inputTypeChange}
                          id="txType"
                          name="txType">
                          <option value="ALL">Please choose&hellip;</option>
                          <option value="SEND">SEND</option>
                          <option value="RECEIVED">RECEIVED</option>
                          <option value="BUY">BUY</option>
                          <option value="REF">REF</option>
                          <option value="RECEIVED_FROM_ADMIN">RECEIVED_FROM_ADMIN</option>
                          <option value="WITHDRAW">WITHDRAW</option>
                          <option value="MINING">MINING</option>
                          <option value="WELCOME">WELCOME</option>
                          <option value="TEAM_MINING">TEAM_MINING</option>
                        </select>
                        <div className="pointer-events-none absolute right-0 top-0 bottom-0 flex items-center px-2 text-gray-700 border-l">
                          <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                          </svg>
                        </div>
                      </div>
                      {/* <div className="relative  w-full mt-4 border  shadow-lg appearance-none grid-col-2 "></div> */}
                      <div className="  text-gray-800  shadow-lg  flex items-end space-x-4 ">
                        <div className=" ">
                          <label style={{ color: "#6D6997" }} className="text-gray-400 text-xs font-bold">From Date</label><br />
                          <input
                            onChange={this.inputChange}
                            name="from"
                            value={this.state.formField["from"] ? this.state.formField["from"] : ''}

                            type="date" className="bg-slate-600 cursor-pointer outline-none lg:py-1.5 p-2 w-40	text-white/50 focus:text-white rounded-md " />
                        </div>
                        <div className=" ">
                          <label style={{ color: "#6D6997" }} className="text-white/50 text-xs font-bold">To Date</label><br />
                          <input
                            onChange={this.inputChange}
                            name="to"
                            value={this.state.formField["to"] ? this.state.formField["to"] : ''}
                            // value={this.state.formField.to}
                            type="date" className="bg-slate-600 cursor-pointer outline-none lg:py-1.5 p-2 w-40 text-white/50 focus:text-white	rounded-md" />
                        </div>
                        <button onClick={this.transactionSearchSubmit} className="items-center bg-red-800 p-2 px-4	rounded-md text-white">
                          Search
                        </button>

                        {/* <Calendar
                      ranges={[selectionRange]}
                      // onChange={this.handleSelect}
                    /> */}
                        {/* <DateRangePicker
                      // hoverRange="week"
                      // ranges={[]}
                      onSelect={this.handleToDateMultiDate}
                    /> */}
                        {/* <label for="frm-whatever" className="sr-only">My field</label>
                    <select className="appearance-none w-full py-1 px-2 bg-white" name="whatever" id="frm-whatever">
                      <option value="">Please choose&hellip;</option>
                      <option value="1">Item 1</option>
                      <option value="2">Item 2</option>
                      <option value="3">Item 3</option>
                    </select>
                    <div className="pointer-events-none absolute right-0 top-0 bottom-0 flex items-center px-2 text-gray-700 border-l">
                      <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div> */}
                      </div>


                      {/* <div className="relative w-full mt-4 border border-gray-300 text-gray-800 bg-white shadow-lg appearance-none">
                    <label for="frm-whatever" className="sr-only text-white">My field</label>
                    <select className="appearance-none w-full py-1 px-2 bg-white text-sm font-normal" name="whatever" id="frm-whatever">
                      <option value="">Please choose&hellip;</option>
                      <option value="1">SEND</option>
                      <option value="2">RECEIVED</option>
                      <option value="2">BUY</option>
                      <option value="3">RECEIVED_FROM_ADMIN</option>
                      <option value="3">WITHDRAW</option>
                      <option value="3">MINING</option>
                      <option value="3">WELCOME</option>
                    </select>
                    <div className="pointer-events-none absolute right-0 top-0 bottom-0 flex items-center px-2 text-gray-700 border-l">
                      <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                  </div> */}

                    </div>

                    <div className="overflow-hidden pb-2 mt-4 lg:p-6">
                      <div className="overflow-auto max-w-full ">
                        <div className="inline-block min-w-full  ">
                          <div className="overflow-hidden  ">

                            <table className="min-w-full border border-black  text-center">
                              <thead className="border border-black">
                                <tr className="border border-black text-center">
                                  <th className="whitespace-nowrap border border-white text-center px-[49px] bg-[#790000] py-3 text-xs font-semibold text-white uppercase">S/n</th>
                                  <th className="whitespace-nowrap border border-white text-center px-[38px] py-3 bg-[#790000] text-xs font-semibold text-white capitalize">date</th>



                                  <th className="whitespace-nowrap border border-white text-center px-[45px] bg-[#790000] py-3 text-xs font-semibold text-white capitalize">Transaction Type</th>
                                  <th className="whitespace-nowrap border border-white text-center px-[22px] py-3 bg-[#790000] text-xs font-semibold text-white capitalize">amount</th>

                                  <th className="whitespace-nowrap border border-white text-center px-[31px] py-3 bg-[#790000] text-xs font-semibold text-white capitalize">Received From</th>

                                </tr>
                              </thead>
                              <tbody>

                                {
                                  txList && txList.length > 0 ?
                                    txList.map((element, index) => (
                                      <>
                                        <tr className="border border-black text-center">
                                          <td className="whitespace-nowrap border border-gray-300 text-center px-[11.5px] py-3 text-xs font-semibold text-black bg-white uppercase bg-whj">{this.state.offset + index + 1}</td>
                                          <td className="whitespace-nowrap border border-gray-300 text-center px-[11.5px] py-3 text-xs font-semibold text-black bg-white uppercase bg-whj">{moment(new Date(parseInt(element && element.createdAt ? element.createdAt : "-"))).utcOffset("+05:30").format("DD-MM-YYYY HH:mm")}</td>
                                          {/* <td className="whitespace-nowrap border border-gray-300 text-center px-[11.5px] py-3 text-xs font-semibold text-black bg-white uppercase bg-whj">{element && element.coinId && element.coinId.name ? element.coinId.name : "-"}</td> */}



                                          <td className="whitespace-nowrap border border-gray-300 text-center px-[11.5px] py-3 text-xs font-semibold text-black bg-white uppercase bg-whj">{element && element.txType ? element.txType : "-"}</td>

                                          <td className="whitespace-nowrap border border-gray-300 text-center px-[11.5px] py-3 text-xs font-semibold text-black bg-white uppercase bg-whj">{element && element.amount ? element.amount : "-"}</td>

                                          {/* <td className="whitespace-nowrap border border-gray-300 text-center px-[11.5px] py-3 text-xs font-semibold text-black bg-white uppercase bg-whj">{element && element.amount ? element.amount : "-"}</td> */}

                                          <td className="whitespace-nowrap border border-gray-300 text-center px-[11.5px] py-3 text-xs font-semibold text-black bg-white uppercase bg-whj">{element && element.userRecId && element.userRecId.userName ? element.userRecId.userName : "-"}</td>
                                        </tr>
                                      </>
                                    )) : null
                                }

                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>

                      {
                        isMobile ?
                          <nav className="relative z-0 flex justify-end mt-5 w-76">
                            {
                              txTotal && txTotal > 10 ?
                                <ReactPaginate
                                  previousLabel={'Prev'}
                                  nextLabel={'Next'}
                                  breakLabel={'...'}
                                  breakClassName={'break-me'}
                                  pageCount={txTotal / this.state.size}
                                  marginPagesDisplayed={0}
                                  pageRangeDisplayed={2}
                                  onPageChange={this.handlePageClick}
                                  containerClassName={'pagination'}
                                  pageClassName={'page-cls'}
                                  activeClassName={'active'}
                                />
                                : null}
                          </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                            {
                              txTotal && txTotal > 10 ?
                                <ReactPaginate
                                  previousLabel={'Previous'}
                                  nextLabel={'Next'}
                                  breakLabel={'...'}
                                  breakClassName={'break-me'}
                                  pageCount={txTotal / this.state.size}
                                  marginPagesDisplayed={3}
                                  pageRangeDisplayed={3}
                                  onPageChange={this.handlePageClick}
                                  containerClassName={'pagination'}
                                  pageClassName={'page-cls'}
                                  activeClassName={'active'}
                                />
                                : null}
                          </nav>
                      }
                    </div>
                  </div>



                </div>
                <div className='p-8'>
                  <div className=' bg-slate-900 '>
                    <div>
                      <h1 className="text-white text-xl py-2 px-4">Overflow History</h1>
                    </div>

                    <div className="overflow-hidden pb-2 mt-4 lg:p-6">
                      <div className="overflow-auto max-w-full ">
                        <div className="inline-block min-w-full  ">
                          <div className="overflow-hidden  ">

                            <table className="min-w-full border border-black  text-center">
                              <thead className="border border-black">
                                <tr className="border border-black text-center">
                                  <th className="whitespace-nowrap border border-white text-center px-[49px] bg-[#790000] py-3 text-xs font-semibold text-white uppercase">S/n</th>
                                  <th className="whitespace-nowrap border border-white text-center px-[38px] py-3 bg-[#790000] text-xs font-semibold text-white capitalize">date</th>



                                  <th className="whitespace-nowrap border border-white text-center px-[45px] bg-[#790000] py-3 text-xs font-semibold text-white capitalize">Transaction Type</th>
                                  <th className="whitespace-nowrap border border-white text-center px-[22px] py-3 bg-[#790000] text-xs font-semibold text-white capitalize">amount</th>

                                  <th className="whitespace-nowrap border border-white text-center px-[31px] py-3 bg-[#790000] text-xs font-semibold text-white capitalize">Received From</th>
                                  <th className="whitespace-nowrap border border-white text-center px-[31px] py-3 bg-[#790000] text-xs font-semibold text-white capitalize">package amount</th>

                                </tr>
                              </thead>
                              <tbody>

                                {
                                  overflowTxList && overflowTxList.length > 0 ?
                                    overflowTxList.map((element, index) => (
                                      <>
                                        <tr className="border border-black text-center">
                                          <td className="whitespace-nowrap border border-gray-300 text-center px-[11.5px] py-3 text-xs font-semibold text-black bg-white uppercase bg-whj">{this.state.offset + index + 1}</td>
                                          <td className="whitespace-nowrap border border-gray-300 text-center px-[11.5px] py-3 text-xs font-semibold text-black bg-white uppercase bg-whj">{moment(new Date(parseInt(element && element.createdAt ? element.createdAt : "-"))).utcOffset("+05:30").format("DD-MM-YYYY HH:mm")}</td>
                                          {/* <td className="whitespace-nowrap border border-gray-300 text-center px-[11.5px] py-3 text-xs font-semibold text-black bg-white uppercase bg-whj">{element && element.coinId && element.coinId.name ? element.coinId.name : "-"}</td> */}



                                          <td className="whitespace-nowrap border border-gray-300 text-center px-[11.5px] py-3 text-xs font-semibold text-black bg-white uppercase bg-whj">{element && element && element.txType ? element.txType : "-"}</td>

                                          <td className="whitespace-nowrap border border-gray-300 text-center px-[11.5px] py-3 text-xs font-semibold text-black bg-white uppercase bg-whj">{element && element.amount ? element.amount : "-"}</td>

                                          {/* <td className="whitespace-nowrap border border-gray-300 text-center px-[11.5px] py-3 text-xs font-semibold text-black bg-white uppercase bg-whj">{element && element.amount ? element.amount : "-"}</td> */}

                                          <td className="whitespace-nowrap border border-gray-300 text-center px-[11.5px] py-3 text-xs font-semibold text-black bg-white uppercase bg-whj">{element && element.userRecId && element.userRecId.userName ? element.userRecId.userName : "-"}</td>

                                          <td className="whitespace-nowrap border border-gray-300 text-center px-[11.5px] py-3 text-xs font-semibold text-black bg-white uppercase bg-whj">{element && element.userPackageId && element.userPackageId.amount ? element.userPackageId.amount : "-"}</td>
                                        </tr>
                                      </>
                                    )) : null
                                }

                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>

                      {
                        isMobile ?
                          <nav className="relative z-0 flex justify-end mt-5 w-76">
                            {
                              overflowTxTotal && overflowTxTotal > 10 ?
                                <ReactPaginate
                                  previousLabel={'Prev'}
                                  nextLabel={'Next'}
                                  breakLabel={'...'}
                                  breakClassName={'break-me'}
                                  pageCount={overflowTxTotal / this.state.size}
                                  marginPagesDisplayed={0}
                                  pageRangeDisplayed={2}
                                  onPageChange={this.handlePageClick}
                                  containerClassName={'pagination'}
                                  pageClassName={'page-cls'}
                                  activeClassName={'active'}
                                />
                                : null}
                          </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                            {
                              overflowTxTotal && overflowTxTotal > 10 ?
                                <ReactPaginate
                                  previousLabel={'Previous'}
                                  nextLabel={'Next'}
                                  breakLabel={'...'}
                                  breakClassName={'break-me'}
                                  pageCount={overflowTxTotal / this.state.sizeOverflow}
                                  marginPagesDisplayed={3}
                                  pageRangeDisplayed={3}
                                  onPageChange={this.handlePageClickOverflow}
                                  containerClassName={'pagination'}
                                  pageClassName={'page-cls'}
                                  activeClassName={'active'}
                                />
                                : null}
                          </nav>
                      }
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </section>
        </div>
      </>

    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  const { overview } = users ? users : {};
  const { setting } = overview ? overview : {};
  return {
    users,
    setting
  };
}
export default connect(mapStateToProps)(Transaction);
