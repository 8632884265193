import React, { Component } from "react";
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
// import { TiSocialFacebook, TiSocialTwitter, TiSocialLinkedin, TiMail } from "react-icons/ti";
// import { FaMediumM, FaInstagram } from "react-icons/fa";
// import { BsReddit } from "react-icons/bs";
// import { BiBitcoin } from "react-icons/bi";
// import { HiChevronDown, HiPlus } from "react-icons/hi";
// import { MdMail } from 'react-icons/md';
import { AiFillLinkedin, AiOutlineArrowRight, } from 'react-icons/ai';
// import { SiTelegram, SiBitcoin } from 'react-icons/si';
import { FiMenu } from 'react-icons/fi';
import { GrFacebookOption } from 'react-icons/gr';
import { IoLogoTwitter, IoLogoInstagram } from 'react-icons/io';
import { RiLinkedinFill } from 'react-icons/ri';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles


class Home extends Component {
  constructor(props) {

    super(props);
    // this.props.dispatch(userActions.logout());
    this.loginSubmit = this.loginSubmit.bind(this);
    this.inputChange = this.inputChange.bind(this);
    this.state = {
      fieldslogin: {},
      errorslogin: {},
      otpSentLogin: false,
      shown: false,

    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {

    if (nextProps.otpSentLogin) {
      return {
        ...nextProps,
        // fieldslogin: {},
        // errorslogin: {},
        otpSentLogin: nextProps.otpSentLogin ? nextProps.otpSentLogin : false

      }
    } else {
      return {
        ...nextProps
      }
    }
  }


  inputChange(e) {
    e.preventDefault();
    let { name, value } = e.target;
    this.setState({ [name]: value });
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = this.state.errorslogin;
    fieldslogin[name] = value;
    errorslogin[name] = "";
    // // console.log(name, value);
    this.setState({ fieldslogin, errorslogin });
  }


  loginSubmit(e) {
    e.preventDefault();
    if (this.handleValidationLogin()) {
      let { email, password, } = this.state;
      this.props.dispatch(userActions.login({ email: email, password: password, }, this.props));
    }

  }

  resendSubmit = (e) => {
    // e.preventDefault();
    if (this.handleValidationLogin()) {
      let { email, password, } = this.state;
      this.props.dispatch(userActions.login({ email: email, password: password, }, this.props));
    }

  }

  resetForm = (e) => {
    e.preventDefault();
    this.setState({
      fieldslogin: {},
      errorslogin: {},
    })
    this.hideErrorMessage();
  }


  handleValidationLogin = () => {
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = {};
    let formIsValid = true;

    //Email
    if (!fieldslogin["email"] || fieldslogin["email"] === "") {
      formIsValid = false;
      errorslogin["email"] = "Please Enter Email Address";
    }
    if (typeof fieldslogin["email"] !== "undefined" && fieldslogin["email"] !== "") {
      let lastAtPos = fieldslogin["email"].lastIndexOf('@');
      let lastDotPos = fieldslogin["email"].lastIndexOf('.');

      if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fieldslogin["email"].indexOf('@@') === -1 && lastDotPos > 2 && (fieldslogin["email"].length - lastDotPos) > 2)) {
        formIsValid = false;
        errorslogin["email"] = "Enter valid email!";
      }
    }
    //Password
    if (!fieldslogin["password"]) {
      formIsValid = false;
      errorslogin["password"] = "Please Enter Password!";
    }
    this.setState({ errorslogin: errorslogin });
    return formIsValid;
  }

  componentDidMount() {
    document.title = "confy-mlm";


  }

  onClickMenu = (url) => {
    // console.log("url:", url);
    this.props.history.push(url)
  }

  otpSubmit = (e) => {
    e.preventDefault();
    if (this.handleValidationOTP()) {
      let { token } = this.props.registeruser;
      let { OTP } = this.state;
      this.props.dispatch(userActions.validateLoginOtp({
        token: token,
        otp: OTP
      }, this.props));
    }
  }

  handleValidationOTP = () => {
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = {};
    let formIsValid = true;

    //Name
    if (!fieldslogin["OTP"]) {
      formIsValid = false;
      errorslogin["OTP"] = "please enter OTP!";
    }

    this.setState({ errorslogin: errorslogin });
    return formIsValid;
  }

  setCurrentCount = (data) => {
    this.setState({ currentCount: data });

  };

  render() {

    AOS.init();

    let { otpSentLogin, loading } = this.props;

    // console.log("Render______this.state.otpSentLogin:::", this.state.otpSentLogin);

    return (
      <>

        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>


        {/* Whole Structure */}
        <div className="h-screen overflow-y-auto">



          {/* Header section */}
          <div className="w-full bg-gradient-to-r from-gray-900 to-[#6e0168]  sticky top-0 z-20">
            <div className="2xl:w-4/6 xl:w-5/6  xl:px-0 px-6 mx-auto p-6  relative flex items-center justify-between h-full text-white">
              <a className='cursor-pointer ' onClick={() => this.onClickMenu('/')}  > <img src="/newImg/logo-comfy-mlm.png" alt="final" className="bg-cover w-52 lg:w-72" /></a>


              {this.state.shown ? <div className="md:flex md:relative absolute md:top-0 top-20 right-0 md:w-auto w-full md:bg-transparent bg-gradient-to-r from-gray-900 to-[#6e0168]">
                <ul className="p-6 space-y-4 font-medium text-lg text-center text-white uppercase md:flex md:space-x-8 md:space-y-0 md:p-0 md:text-left">
                  <li className=" text-white cursor-pointer  " onClick={() => this.onClickMenu('/')}>Home</li>
                  <li className="cursor-pointer " onClick={() => this.onClickMenu('/aboutus')}>Learn</li>
                  <li className="cursor-pointer " onClick={() => this.onClickMenu('/contactus')}>Ranking</li>
                  <li className="cursor-pointer " onClick={() => this.onClickMenu('/login')}>Portfolio</li>
                  <li className="cursor-pointer " onClick={() => this.onClickMenu('/signup')}>About</li>
                </ul>
              </div> :
                <div className="md:flex hidden md:relative absolute md:top-0 top-20 right-0 md:w-auto w-full md:bg-transparent bg-[#29092a]  ">
                  <ul className="p-6 space-y-4 font-medium text-lg text-center text-white uppercase md:flex md:space-x-8 md:space-y-0 md:p-0 md:text-left">
                    <li className=" text-white cursor-pointer " onClick={() => this.onClickMenu('/')}>Home</li>
                    <li className="cursor-pointer " onClick={() => this.onClickMenu('/')}>Learn</li>
                    <li className="cursor-pointer " onClick={() => this.onClickMenu('/')}>Ranking</li>
                    <li className="cursor-pointer " onClick={() => this.onClickMenu('/')}>Portfolio</li>
                    <li className="cursor-pointer " onClick={() => this.onClickMenu('/')}>About</li>
                  </ul>
                </div>}

              <FiMenu onClick={() => this.setState({ shown: !this.state.shown })} className="text-white text-[1.5rem] md:hidden flex" />

            </div>
          </div>


          <div className="w-full md:py-20 py-10 bg-gradient-to-r from-gray-900 to-[#6e0168]" >
            <div className="flex md:flex-row flex-col items-center 2xl:w-4/6 xl:w-5/6  xl:px-0 px-6 mx-auto" >
              <div className="w-full space-y-2 md:text-left text-center" >
                <h1 className="lg:text-6xl text-3xl font-normal text-white lg:leading-[5rem] leading-none" >Redefining Digital Assest Ownership</h1>
                <p className="text-white/70 text-xl" >Invest for the future, make your life
                  events the happiest!</p>
                <div className="flex items-center md:justify-start justify-center space-x-4 py-6 text-xl" >
                  <button className="rounded-full px-6 py-2 text-white border-2 border-[#6e0168] flex items-center" onClick={() => this.onClickMenu('/login')} >Login <AiOutlineArrowRight /> </button>
                  <button className="rounded-full px-6 py-2 text-white bg-[#6e0168] flex items-center" onClick={() => this.onClickMenu('/signup')}> sign up <AiOutlineArrowRight /></button>
                </div>

                {/* <div className="flex md:justify-start justify-center flex-wrap gap-4 text-white py-6">

                  <a className="cursor-pointer" href='' target="blank" > <AiFillFacebook className="text-[1.5rem]" /></a>

                  <a className="cursor-pointer" href='' target="blank" ><AiFillTwitterSquare className="text-[1.5rem]" /></a>

                  <a className="cursor-pointer" href='' target="blank" ><FaInstagramSquare className="text-[1.5rem]" /></a>

                  <a className="cursor-pointer" href='' target="blank" ><AiFillLinkedin className="text-[1.5rem]" /></a>
                  <a className="cursor-pointer" href='' target="blank" ><FaYoutubeSquare className="text-[1.5rem]" /></a>


                </div> */}

              </div>
              {/* img */}
              <div className="flex justify-center items-center w-full" >
                <img src="/newImg/what-is-Metaverse.png" alt="shark" className="w-full" />
              </div>
            </div>
            <div className="px-3">
              <div className="flex md:flex-row flex-col  md:justify-between  2xl:w-4/6 xl:w-5/6 rounded-lg bg-white/20 my-16 mx-auto p-5">
                <div className="flex items-center space-x-4 ">
                  <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-registered text-white w-16 lg:w-28" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path>
                    <path d="M10 15v-6h2a2 2 0 1 1 0 4h-2"></path>
                    <path d="M14 15l-2 -2"></path>
                  </svg>
                  <div>
                    <p className="text-2xl lg:text-4xl text-white" >149+</p>
                    <p className="text-white/70 text-[14px] md:text-lg" >Registered companies</p>
                  </div>
                </div>

                <div className="flex items-center space-x-4">
                  <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-coin-rupee text-white w-16 lg:w-28" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path>
                    <path d="M15 8h-6h1a3 3 0 0 1 0 6h-1l3 3"></path>
                    <path d="M9 11h6"></path>
                  </svg>
                  <div>
                    <p className="text-2xl lg:text-4xl text-white" >109M+</p>
                    <p className="text-white/70 text-[14px] md:text-lg" >The disbursed Funds</p>
                  </div>
                </div>

                <div className="flex items-center space-x-4">
                  <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle-check text-white w-16 lg:w-28" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path>
                    <path d="M9 12l2 2l4 -4"></path>
                  </svg>
                  <div>
                    <p className="text-2xl lg:text-4xl text-white">99+</p>
                    <p className="text-white/70 text-[14px] md:text-lg" >Fintech project completed</p>
                  </div>
                </div>
              </div>
            </div>
          </div>



          <div className="w-full py-10 bg-gradient-to-r from-gray-900 to-[#6e0168]" >

            <div className="2xl:w-4/6 xl:w-5/6  xl:px-0 px-6 mx-auto space-y-20" >
              <div className="w-full space-y-2 text-center" >
                <h1 className="lg:text-6xl text-3xl font-normal text-white md:leading-[5rem]" >
                  Our Products</h1>
                <p className="text-xl text-white/70" >Leading the way to better business in financial services,We provide our clients with data security and empower our merchants to efficiently deal with various Forex exchange concerns.</p>
              </div>

              <div className="grid md:grid-cols-4 gap-6 " >

                <div className="p-4 rounded-lg bg-white/10">
                  <img src="/newImg/NFT_5 1.png" className="mx-auto w-60" />
                  <div class="flex justify-center items-center">
                    <div>
                      <h1 className="text-xl text-white" >Best Trading Crypto</h1>
                    </div>
                  </div>
                </div>

                <div className="p-4 rounded-lg bg-white/10">
                  <img src="/newImg/Other 18.png" className="mx-auto w-60" />
                  <div class="flex justify-center items-center">
                    <div>
                      <h1 className="text-xl text-white text-center" >Utility</h1>
                    </div>
                  </div>
                </div>

                <div className="p-4 rounded-lg bg-white/10">
                  <img src="/newImg/associate-marketing-6170201-5057845 1.png" className="mx-auto w-60" />
                  <div class="flex justify-center items-center">
                    <div>
                      <h1 className="text-xl text-white" >Referral program</h1>
                    </div>
                  </div>
                </div>

                <div className="p-4 rounded-lg bg-white/10">
                  <img src="/newImg/Rectangle.png" className="mx-auto w-60" />
                  <div class="flex justify-center items-center">
                    <div>
                      <h1 className="text-xl text-white" >Crowdfunding</h1>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div className="w-full py-8 lg:py-16 bg-gradient-to-r from-gray-900 to-[#6e0168]" >
            <div className="2xl:w-4/6 xl:w-5/6  xl:px-0 px-6 mx-auto space-y-20" >
              <div className="w-full space-y-2 text-center" >
                <h1 className="lg:text-6xl text-3xl text-white md:leading-[5rem]" >
                  Why choose us?</h1>
                <p className="text-lg text-white/70" >confy-mlm foundation is built using the best-in-class core blockchain principles,
                  and optimized for the next evolution of crypto economy
                </p>
              </div>
              {/* img */}
              <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6" >

                <div className="p-6 space-y-6 bg-white/10 rounded-xl">
                  <img src="/newImg/logos_google-optimize.png" className="mx-auto" />

                  <div className="pb-10 space-y-2 ">
                    <h1 className="text-2xl text-white text-center" >Optimized for Crypto</h1>
                    <p className="text-[16px] text-white/70 text-center" >Our chain is specifically calibrated with features like Verified Crypto Creators, customized Crypto
                      properties, in-built whitelisting
                      features etc.</p>
                  </div>
                </div>

                <div className="p-6 space-y-6 bg-white/10 rounded-xl">
                  <img src="/newImg/Vector (8).png" className="mx-auto" />

                  <div className="pb-10 space-y-2 ">
                    <h1 className="text-2xl text-white  text-center" >Cosmos Ecosystem</h1>
                    <p className="text-[16px] text-white/70  text-center" > confy-mlm being one of the top zones of the Cosmos Hub, leverages features like Tender mint Core for
                      Consensus and a highly modular SDK framework.
                      features etc.</p>
                  </div>
                </div>

                <div className="p-6 space-y-6 bg-white/10 rounded-xl">
                  <img src="/newImg/low-cost-icon-low-cost-icon-pound-11553420082r1qi7rst5e 1.png" className="mx-auto" />

                  <div className="pb-10 space-y-2 ">
                    <h1 className="text-2xl text-white  text-center" >Extremely Low Fees</h1>
                    <p className="text-[16px] text-white/70  text-center" > confy-mlm has a highly Economical network providing one of the lowest fees compared to any popular network, of around $0.01 per transaction.
                      features etc.</p>
                  </div>
                </div>

                <div className="p-6 space-y-6 bg-white/10 rounded-xl">
                  <img src="/newImg/Vector (9).png" className="mx-auto" />

                  <div className="pb-10 space-y-2 ">
                    <h1 className="text-2xl text-white  text-center" >Fast Order Fulfillments </h1>
                    <p className="text-[16px] text-white/70  text-center" > Extremely fast transactions nearing 7 seconds enables almost zero waiting time for fulfillment of orders of buy, sell, auction etc.
                      features etc.</p>
                  </div>
                </div>

                <div className="p-6 space-y-6 bg-white/10 rounded-xl">
                  <img src="/newImg/Vector (10).png" className="mx-auto" />

                  <div className="pb-10 space-y-2 ">
                    <h1 className="text-2xl text-white  text-center" >Completely Secure</h1>
                    <p className="text-[16px] text-white/70  text-center" >With high network security and leveraging the native token DFTC, confy-mlm provides the level of
                      protection required for your assets.
                    </p>
                  </div>
                </div>

                <div className="p-6 space-y-6 bg-white/10 rounded-xl">
                  <img src="/newImg/Vector (11).png" className="mx-auto" />

                  <div className="pb-10 space-y-2 ">
                    <h1 className="text-2xl text-white  text-centerI" >Future Interoperability</h1>
                    <p className="text-[16px] text-white/70  text-centerI" > Intercrypto can potentially enable Crypto to be transferred to other connected chains, thereby creating a larger shared market economy.
                    </p>
                  </div>
                </div>

              </div>
            </div>

          </div>

          <div className="w-full py-10 bg-gradient-to-r from-gray-900 to-[#6e0168]" >
            <div className="2xl:w-4/6 xl:w-5/6  xl:px-0 px-6 mx-auto space-y-20" >
              <div className="w-full space-y-2 text-center" >
                <h1 className="lg:text-6xl text-3xl  text-white md:leading-[5rem]" >
                  Distributed Crypto economy</h1>
                <p className="text-lg  text-white/70" >confy-mlm suite of products creates a distributed crypto Economy where users can create
                  not only crypto collections but their own crypto marketplaces and storefronts.
                </p>
              </div>
            </div>
          </div>

          <div className="w-full py-7 bg-gradient-to-r from-gray-900 to-[#6e0168]" >

            <div className="2xl:w-4/6 xl:w-5/6  xl:px-0 px-6 mx-auto " >
              {/* img */}
              <div className="flex md:flex-row flex-col md:space-y-0 space-y-10 items-center">
                <div className="flex justify-end w-full " >
                  <img src="/newImg/we-understand.png" alt="shark" className="-scale-x-100 " />

                </div>
                <div className="w-full">
                  <div className="w-full space-y-8" >
                    <h1 className="lg:text-6xl text-3xl text-white md:leading-[5rem]" >We understand what you needs?</h1>
                    <p className="text-lg text-white/70" >Building a framework to discover and realize the potential of your data is critical to increasing the value you provide to shareholders, and to optimizing the future success of your organization.</p>
                  </div>

                  <div className="mt-6 space-y-6 " >
                    <div className="flex ">
                      <div className="flex items-center p-4 px-8 space-x-4 rounded-lg bg-white/10 lg:w-- w-full">
                        <img src="/newImg/Vector (2).png" />
                        <div>
                          <p className="text-lg font-semibold text-white" >Best Trading Application</p>
                          <p className="text-sm text-white" >Trade and monitor accounts</p>
                        </div>
                      </div>
                    </div>

                    <div className="flex md:ml-10 ">
                      <div className="flex items-center p-4 px-8 space-x-4 rounded-lg bg-white/10 lg:w-- w-full">
                        <img src="/newImg/Vector.png" />
                        <div>
                          <p className="text-lg font-semibold text-white" >DeFi (Decentralized Finance)</p>
                          <p className="text-sm text-white" >Blockchain - Based finance</p>
                        </div>
                      </div>
                    </div>

                    <div className="flex ">
                      <div className="flex items-center p-4 px-8 space-x-4 rounded-lg bg-white/10 lg:w-- w-full">
                        <img src="/newImg/Vector (1).png" />
                        <div>
                          <p className="text-lg font-semibold text-white" > NFT (Non-Fungible Token)</p>
                          <p className="text-sm text-white" >Unique identity and Ownership</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid justify-center md:grid-cols-3 gap-6 mt-6 " >
                <div className="flex items-center p-4 px-8 space-x-4 rounded-lg bg-white/10 lg:w-- w-full">
                  <img src="/newImg/Vector (3).png" />
                  <div>
                    <p className="text-lg font-semibold text-white" >Digital Token</p>
                    <p className="text-sm text-white" >Built on Blockchain Network</p>
                  </div>
                </div>

                <div className="flex items-center p-4 px-8 space-x-4 rounded-lg bg-white/10 lg:w-- w-full">
                  <img src="/newImg/Vector (4).png" />
                  <div>
                    <p className="text-lg font-semibold text-white" >Blockchain</p>
                    <p className="text-sm text-white" >Digital investment data storage</p>
                  </div>
                </div>

                <div className="flex items-center p-4 px-8 space-x-4 rounded-lg bg-white/10 lg:w-- w-full">
                  <img src="/newImg/Vector (5).png" />
                  <div>
                    <p className="text-lg font-semibold text-white" >Fintech</p>
                    <p className="text-sm text-white" >Easy finanical Transaction</p>
                  </div>
                </div>

                <div className="grid md:grid-cols-2 md:col-span-3 gap-6 mx-auto ">
                  <div className="flex items-center p-4 px-8 space-x-4 rounded-lg bg-white/10 lg:w-- w-full">
                    <img src="/newImg/Vector (6).png" />
                    <div>
                      <p className="text-lg font-semibold text-white" >Big Data</p>
                      <p className="text-sm text-white" >Processing & analyzing data sets </p>
                    </div>
                  </div>

                  <div className="flex items-center p-4 px-8 space-x-4 rounded-lg bg-white/10 lg:w-- w-full col-start-end ">
                    <img src="/newImg/Vector (7).png" />
                    <div>
                      <p className="text-lg font-semibold text-white" >Asset Tokenization</p>
                      <p className="text-sm text-white" >Best digital token</p>
                    </div>
                  </div>
                </div>

              </div>

            </div>

          </div>


          <div className="w-full py-10 bg-gradient-to-r from-gray-900 to-[#6e0168]" >
            <div className="2xl:w-4/6 xl:w-5/6  xl:px-0 px-6 mx-auto flex md:flex-row flex-col-reverse gap-10 items-center" >
              <div className="w-full space-y-2 md:text-left text-center" >
                <h1 className="lg:text-6xl text-3xl  text-white md:leading-[5rem]" >
                  MISSION</h1>
                <p className="text-lg font-normal text-white/70" >How are we creating this outcome?
                  By building safe and efficient bridges between traditional and decentralized finance,incorporating the best of both worlds. The open financial system is HERE, NOW! If you want to create history, if you are the kind that cannot compromise with status quo, if you are
                  always challenging and working hard to create better solutions for tomorrow, reach us to us now. We are constantly looking for curious minds to join our awesome team.
                </p>
              </div>

              <div>
                <img src="/newImg/NFT-Space-Location-3.png" className="rounded-xl " />
              </div>
            </div>
          </div>


          <div className="w-full py-10 bg-gradient-to-r from-gray-900 to-[#6e0168]" >
            <div className=" 2xl:w-4/6 xl:w-5/6  xl:px-0 px-6 mx-auto flex md:flex-row flex-col gap-10 items-center ">
              <div>
                <img src="/newImg/NFT_digital_art-removebg-preview.png" className="w-[44rem] " />
              </div>
              <div className="w-full space-y-2 md:text-left text-center " >
                <h1 className="lg:text-6xl text-3xl  text-white md:leading-[5rem]" >
                  VISION</h1>
                <p className="text-lg font-normal text-white/70" >What drives us? Why are we here? What wakes us up every day and prompts us to go to work? We wake up every morning to create equal access to opportunity for everyone, by democratizing access to capital markets. We are disrupting the financial markets, one day at a time.
                </p>
              </div>




            </div>
          </div>



          <div className="w-full py-10 bg-gradient-to-r from-gray-900 to-[#6e0168]" >

            <div className="2xl:w-4/6 xl:w-5/6  xl:px-0 px-6 mx-auto space-y-20" >
              <div className="w-full space-y-2 text-center" >
                <h1 className="lg:text-6xl text-3xl  text-white md:leading-[5rem]" >
                  Featured In</h1>
                <p className="text-lg text-white/70" >A quick look into the social proof aspect surrounding confy-mlm<br />
                  to get the bearings of its wide adoption factor</p>
              </div>
              {/* img */}
              <div className="flex flex-col lg:flex-row items-center justify-center gap-5 lg:gap-6" >

                <img alt="faksjd" src="/newImg/coin-base.png" className="oject-cover w-[200px] text-white" />
                <img alt="faksjd" src="/newImg/coin-marketcap.png" className="oject-cover w-52" />
                <img alt="faksjd" src="/newImg/New Project (5).png" className="oject-cover w-48" />
              </div>
            </div>

          </div>


          {/* footer section  */}
          <section className="w-full bg-cover bg-gradient-to-r from-gray-900 to-[#6e0168] px-2">
            <footer className="2xl:w-4/6 xl:w-5/6  xl:px-0 px-6 mx-auto bg-white/20 rounded-t-2xl " >
              <div className="flex lg:flex-row flex-col w-full px-2 lg:px-8 py-8">

                <div className="space-y-6 w-full">
                  <img src="/newImg/logo-comfy-mlm.png" alt="final" className="bg-cover w-52" />
                  <p className="text-white/80 text-base lg:pr-10 px-0">confy-mlm provides clients with the industry’s broadest range of solutions to meet their investment objectives, continued investment in technology and our ambition is to give more people access to financial markets.</p>
                </div>

                <div className=" w-full py-4 lg:py-0 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3">
                  <div className=" w-full">
                    <h1 className=" text-white uppercase text-xl font-semibold">Home</h1>
                    <div className="flex flex-col py-2 ">
                      <a href="/login#/login" className="text-white/80 hover:text-white cursor-pointer text-base transition duration-200 transform hover:translate-x-4">Login</a>
                      <a href="#" className="text-white/80 hover:text-white cursor-pointer text-base transition duration-200 transform hover:translate-x-4">Ranking</a>
                      <a href="#" className="text-white/80 hover:text-white cursor-pointer text-base transition duration-200 transform hover:translate-x-4">Portfolio</a>
                      <a href="#" className="text-white/80 hover:text-white cursor-pointer text-base transition duration-200 transform hover:translate-x-4">About</a>
                    </div>
                  </div>

                  {/* <div className="w-full text-white text-base ">
                    <h1 className=" text-white uppercase text-xl font-semibold" >connect</h1>
                    <div className="flex flex-col gap-1 py-2 ">
                      <a href="#" className="text-white/80 hover:text-white text-base ">Email</a>
                      <a href="" className="text-white/80 hover:text-white text-base">Linkedin</a>
                      <a href="" className="text-white/80 hover:text-white text-base">Instagram</a>
                      <a href="" className="text-white/80 hover:text-white text-base">Twitter</a>
                    </div>
                  </div> */}
                  <div className="w-full text-white text-base  ">
                    <h1 className=" text-white text-xl font-semibold uppercase" > info</h1>
                    <div className="flex flex-col gap-1 py-2 ">
                      <p className="text-white/80 hover:text-white text-base transition duration-200 transform hover:translate-x-4">support.@confy-mlm.io</p>
                    </div>
                  </div>
                  <div className="w-full text-white text-base ">
                    <h1 className=" text-white text-xl font-semibold uppercase" >For collab</h1>
                    <div className="flex flex-col gap-1 py-2">
                      <p className="text-white/80 hover:text-white text-base transition duration-200 transform hover:translate-x-4">sales.@confy-mlm.io</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="px-10 py-3 md:text-left flex lg:flex-row flex-col lg:justify-between justify-center  items-center  border-t border-white/10">
                <p className="text-white text-base py-5 lg:py-0"> © 2022 All Rights Reserved </p>
                <div className="flex md:justify-start justify-center flex-wrap gap-4 text-white">
                  <a className="cursor-pointer" href='https://www.facebook.com/people/Comfygen-Private-Limited/100083835361397/' target="blank" > <GrFacebookOption className="text-[1.5rem]" /></a>
                  <a className="cursor-pointer" href='https://twitter.com/comfygentech' target="blank" ><IoLogoTwitter className="text-[1.5rem]" /></a>
                  <a className="cursor-pointer" href='https://www.instagram.com/comfygen_/?hl=en' target="blank" ><IoLogoInstagram className="text-[1.5rem]" /></a>
                  <a className="cursor-pointer" href='https://www.linkedin.com/in/comfygen-private-limited-81b160238' target="blank" ><RiLinkedinFill className="text-[1.5rem]" /></a>
                </div>
              </div>
            </footer>
          </section>

        </div>
        {/* Whole Structure End*/}
      </>
    )
  }
}
function mapStateToProps(state) {
  const { loggingIn, user, otpSentLogin, registeruser, loading } = state.authentication;
  const { users, authentication } = state;
  return {
    loggingIn,
    otpSentLogin,
    user,
    users,
    registeruser,
    loading,
    authentication
  };
}
export default connect(mapStateToProps)(Home);
// export default NetworkDetector(connect(mapStateToProps)(Home));</div>lg: