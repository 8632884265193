import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userActions, dashboardActions } from '../../_actions';
import { alertActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
import { CONST } from '../../_config';
import {
  BsLink,
} from "react-icons/bs";
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton, EmailShareButton } from "react-share";
import { FacebookIcon, TwitterIcon, EmailIcon, WhatsappIcon } from "react-share";

class Referral extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      size: 10,
      keyWord: "",
      pageNo: 1,
    }
  }



  componentDidMount() {
    let data = {
      // "txType": "SEND_RECEIVED",
      "keyWord": "",
      "pageNo": 1,
      "size": 10
    }
    this.props.dispatch(userActions.getUserDetails());
    this.props.dispatch(userActions.getTx(data));
    this.props.dispatch(dashboardActions.getUserPackagesTeam());
    this.props.dispatch(dashboardActions.getUserPackagesTeamTotalCount());
    // window.scrollTo(0, 0)
  }

  sendCoin = (e) => {
    e.preventDefault();
    if (this.handleValidationSendCoin()) {

      let { coinType, toAddress, recievingAmount } = this.state.fieldslogin;
      this.props.dispatch(userActions.sendCoin({
        coinType: coinType,
        amount: Number(recievingAmount),
        to: toAddress,
      }, this.props));
    }
  }

  inputChangeAmount = (event) => {
    event.preventDefault();
    let { users } = this.props;
    let { overview } = users;
    let { setting } = overview;
    let { txFee } = setting;
    if (event.target.value >= 0) {
      const { fieldslogin, errorslogin } = this.state;
      fieldslogin[event.target.name] = event.target.value;
      fieldslogin['amount'] = event.target.value;
      fieldslogin['fees'] = txFee;
      fieldslogin['recievingAmount'] = parseFloat(event.target.value - txFee).toFixed(8);
      errorslogin['amount'] = "";
      this.setState({ fieldslogin, errorslogin });
    } else {
      const { errorslogin } = this.state;
      errorslogin['amount'] = "Amount should be positive number.";
      this.setState({ errorslogin });
    }
  }

  handlePageChange = (offset, page) => {
  }

  copyCodeToClipboard = (data) => {
    navigator.clipboard.writeText(data)
    this.props.dispatch(alertActions.success("Copied!"));
  }

  handlePageClick = (data) => {
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });
    let datatemp = {
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size,
      "txType": "SEND_RECEIVED",
    }
    this.props.dispatch(userActions.getTx(datatemp));
  }


  onClickMenu = () => {
    this.props.history.push("/app/referalTable")
  }


  onClickMenuTeam = (id) => {
    this.props.history.push("/app/userPackagesTeam/" + id)
  }

  render() {
    let { users,dashboard } = this.props;
    let { loading} = users;
    let { overview } = users;
    let { user } = overview ? overview : {};
    let { getUserPackagesTeamTotalCount } = dashboard;

    // let { wallet } = overview ? overview : {};
    // let { setting } = overview ? overview : {};
    let { refCode,  refCount } = user ? user : {};


    // console.log('getUserPackagesTeamTotalCount____________________', getUserPackagesTeamTotalCount);
    // console.log('RENDER_______#wallet::', wallet);
    // console.log('RENDER_______#setting::', setting);
    // console.log('RENDER_______refCount::', refCount);

    return (
      <>
        {/* <Header name="Referral" /> */}
        <div >
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        {/* Main Content */}
        <main className="bg_page flex-1 relative z-0 overflow-y-auto focus:outline-none" tabIndex={0}>
          <div className="p-6">
            <div className="2xl:p-10 p-5 bg-[#2A3441] shadow-xl">
              {/* <div className="bg-[#CA4E79] lg:flex lg:justify-between p-3 items-center rounded-md ">
              <div className="ml-3">
                <h1 className="text-white lg:text-4xl text-xl">Refer Friends & Earn Credit </h1>
                <p className="text-white text-sm lg:pt-2">Introduce a friend to EFINCAP_Final and you'll be earning EFINCAP_Final as a reward</p>
              </div>
              <div>
                <img className="w-32" src="imglogo/EFINCAP_Final Logo_For Dark BG.png" alt=" " />
              </div>
            </div> */}
              <div className="grid md:grid-cols-3 gap-4 mt-3 lg:grid-col-3">
                <div className="border-l-8 border-blue-400 rounded-md bg-[#111827] shadow-xl">
                  <h1 className="text-center pt-2 font-medium text-lg text-white">Invitation Link</h1>
                  <p onClick={() => this.copyCodeToClipboard(`${CONST.BACKEND_REFERRAL_URL}${refCode}`)} className="text-white text-2xl text-center font-bold">
                    <button className="btn_bg_col rounded-r-md text-white text-center lg:text-sm lg:font-bold lg:mr-6 mr-4 cursor-pointer text-[10px] ">Copy Link</button>
                  </p>
                  <div className="flex justify-end">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-blue-400" fill="none" viewBox="0 0 24 24" stroke="#B22727" stroke-width="2">
                      <path strokeLinecap="round" stroke-linejoin="round" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                    </svg>
                  </div>
                </div>
                <div className="border-l-8 border-blue-400 rounded-md bg-[#111827] shadow-xl">
                  <h1 className="text-center pt-2 font-medium text-lg text-white">Levels</h1>
                  <p className="text-white text-2xl text-center font-bold">{user && user.refReward ? user.refReward : 0.00}</p>
                  <div className="flex justify-end">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-blue-400" fill="none" viewBox="0 0 24 24" stroke="#B22727" stroke-width="2">
                      <path strokeLinecap="round" stroke-linejoin="round" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                    </svg>
                  </div>
                </div>


                {refCount !== 0 ?
                  <>

                    <div className="border-l-8 border-blue-400 rounded-md bg-[#111827] cursor-pointer"
                      onClick={this.onClickMenu}
                    >
                      <h1 className="text-center pt-2 font-medium text-lg text-white">My Referrals</h1>
                      <p className="text-white text-2xl text-center font-bold">{refCount ? refCount : 0}</p>
                      <div className="flex justify-end">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-blue-400" fill="none" viewBox="0 0 24 24" stroke="#B22727" stroke-width="2">
                          <path strokeLinecap="round" stroke-linejoin="round" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                        </svg>
                      </div>
                    </div>

                  </> :


                  <div className="border-l-8 border-blue-400 rounded-md bg-[#111827] cursor-pointer"
                  >
                    <h1 className="text-center pt-2 font-medium text-lg text-white">My Referrals</h1>
                    <p className="text-white text-2xl text-center font-bold">{refCount ? refCount : 0}</p>
                    <div className="flex justify-end">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-blue-400" fill="none" viewBox="0 0 24 24" stroke="#B22727" stroke-width="2">
                        <path strokeLinecap="round" stroke-linejoin="round" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                      </svg>
                    </div>
                  </div>

                }

              


              </div>
              <div className=" bg-[#111827] mt-3 rounded-md ">
                <h1 className="text-white lg:text-lg lg:font-bold pt-2 text-xs ml-8 lg:ml-8">Share my referral link with friends</h1>
                <div style={{ height: "1px" }} className="bg-gray-500 ml-8 mr-8 mt-1"></div>
                <div className="lg:flex lg:mt-8 lg:w-4/5 w-full lg:mr-auto lg:ml-auto ml-1 mt-5 flex p-2 lg:p-0 ">
                  <div className="bg-gray-500 w-4/5 sm:w-4/5 sm:ml-0 rounded-l-md items-center">
                    <h1 className="text-white lg:text-xs lg:font-bold text-[10px] lg:ml-5 underline pt-1.5 cursor-pointer pl-1 ">
                      {CONST.BACKEND_REFERRAL_URL}{refCode}
                    </h1>
                  </div>
                  <div onClick={() => this.copyCodeToClipboard(`${CONST.BACKEND_REFERRAL_URL}${refCode}`)} className="btn_bg_col lg:w-32 w-32 lg:mr-7 mr-2 rounded-r-md flex justify-end items-center py-2">
                    <BsLink className="sm:hidden text-white lg:h-8 lg:w-8 mr-1 h-6 w-6" />
                    <h1 className="text-white text-center lg:text-sm lg:font-bold lg:mr-6 mr-4 cursor-pointer text-[10px]">Copy Link</h1>
                  </div>
                </div>
                <div className="mt-10 w-full">
                  <div className='flex justify-center'>
                    <div className='grid grid-cols-4 w-4/6 '>

                      <div>
                        <WhatsappShareButton className='focus:outline-none'
                          title={"Hurry, sign up by using this link:"}
                          url={`${CONST.BACKEND_REFERRAL_URL}${refCode}`}
                        >
                          <div
                            className="flex mb-10 focus:outline-none lg:w-32 w-[100px] py-1.5 rounded items-center text-center lg:mr-24   cursor-pointer">
                            <WhatsappIcon className="text-white  relative" size={40} />

                          </div>
                        </WhatsappShareButton>
                      </div>

                      <div>
                        <FacebookShareButton className='focus:outline-none'
                          title={"Hurry, sign up by using this link:"}
                          url={`${CONST.BACKEND_REFERRAL_URL}${refCode}`}
                          hashtag={"facebook"}
                          quotes={"Hurry, sign up by using this link:"}
                          // url={`${CONST.BACKEND_REFERRAL_URL}${refCode}`}
                          description={"Hurry, sign up by using this link:"}
                        // url={`${CONST.BACKEND_REFERRAL_URL}${refCode}`}
                        >
                          <div
                            className="flex mb-10 focus:outline-none lg:w-32 w-[100px] py-1.5 rounded items-center text-center lg:mr-24  cursor-pointer">
                            <FacebookIcon className="bg-green-500 lg:ml-3 relative" size={40} />

                          </div>
                        </FacebookShareButton>
                      </div>

                      <div>
                        <TwitterShareButton className='focus:outline-none'
                          title={"Hurry, sign up by using this link:"}
                        // url={`${CONST.BACKEND_REFERRAL_URL}${refCode}`}
                        >
                          <div
                            className="flex mb-10 focus:outline-none lg:w-32 w-[100px] py-1.5 rounded items-center text-center lg:mr-24 cursor-pointer">
                            <TwitterIcon className="text-white  relative" size={40} />

                          </div>
                        </TwitterShareButton>
                      </div>

                      <div>

                        <EmailShareButton className='focus:outline-none'
                          url={`${CONST.BACKEND_REFERRAL_URL}${refCode}`}
                          subject="EFINCAP"
                          body={"Hurry, sign up by using this link:"
                            + <a href={`${CONST.BACKEND_REFERRAL_URL}${refCode}`}>Link</a>
                          }

                        >
                          <div
                            className="flex mb-10 focus:outline-none lg:w-32 w-[100px] py-1.5 rounded items-center text-center lg:mr-24 cursor-pointer">
                            <EmailIcon className="text-white  relative" size={40} />
                          </div>
                        </EmailShareButton>
                      </div>
                    </div>
                  </div>

                </div>

              </div>



              <div className="grid md:grid-cols-3 gap-4 mt-3 lg:grid-col-3">
                <div className="border-l-8 border-blue-400 rounded-md bg-[#111827] shadow-xl">
                  <h1 className="text-center pt-2 font-medium text-lg text-white">Total Partner</h1>
                  <p className="text-white text-2xl text-center font-bold">{getUserPackagesTeamTotalCount ? getUserPackagesTeamTotalCount.teamCount : "0"}
                  </p>
                  <div className="flex justify-end">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-blue-400" fill="none" viewBox="0 0 24 24" stroke="#B22727" stroke-width="2">
                      <path strokeLinecap="round" stroke-linejoin="round" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                    </svg>
                  </div>
                </div>
                <div className="border-l-8 border-blue-400 rounded-md bg-[#111827] shadow-xl">
                  <h1 className="text-center pt-2 font-medium text-lg text-white">Todays Team</h1>
                  <p className="text-white text-2xl text-center font-bold">{getUserPackagesTeamTotalCount ? getUserPackagesTeamTotalCount.todayTeamCount : "0"}
                  </p>
                  <div className="flex justify-end">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-blue-400" fill="none" viewBox="0 0 24 24" stroke="#B22727" stroke-width="2">
                      <path strokeLinecap="round" stroke-linejoin="round" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                    </svg>
                  </div>
                </div>
                <div className="border-l-8 border-blue-400 rounded-md bg-[#111827] shadow-xl">
                  <h1 className="text-center pt-2 font-medium text-lg text-white">Todays Volume</h1>
                  <p className="text-white text-2xl text-center font-bold">{getUserPackagesTeamTotalCount ? getUserPackagesTeamTotalCount.totalTeamVolumeToday : "0"}
                  </p>
                  <div className="flex justify-end">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-blue-400" fill="none" viewBox="0 0 24 24" stroke="#B22727" stroke-width="2">
                      <path strokeLinecap="round" stroke-linejoin="round" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                    </svg>
                  </div>
                </div>
              </div>

              <div className="grid md:grid-cols-3 gap-4 mt-3 lg:grid-col-3">
                <div className="border-l-8 border-blue-400 rounded-md bg-[#111827] shadow-xl">
                  <h1 className="text-center pt-2 font-medium text-lg text-white">Direct Volume</h1>
                  <p className="text-white text-2xl text-center font-bold">{getUserPackagesTeamTotalCount ? getUserPackagesTeamTotalCount.directVolume : "0"}
                  </p>
                  <div className="flex justify-end">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-blue-400" fill="none" viewBox="0 0 24 24" stroke="#B22727" stroke-width="2">
                      <path strokeLinecap="round" stroke-linejoin="round" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                    </svg>
                  </div>
                </div>
                <div className="border-l-8 border-blue-400 rounded-md bg-[#111827] shadow-xl">
                  <h1 className="text-center pt-2 font-medium text-lg text-white">Team Volume</h1>
                  <p className="text-white text-2xl text-center font-bold">{getUserPackagesTeamTotalCount ? getUserPackagesTeamTotalCount.totalTeamVolume : "0"}
                  </p>
                  <div className="flex justify-end">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-blue-400" fill="none" viewBox="0 0 24 24" stroke="#B22727" stroke-width="2">
                      <path strokeLinecap="round" stroke-linejoin="round" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                    </svg>
                  </div>
                </div>


                <div className="border-l-8 border-blue-400 rounded-md bg-[#111827] shadow-xl" onClick={() => this.onClickMenuTeam(user.id)}>
                  <h1 className="text-center pt-2 font-medium text-lg text-white">User Packages Team</h1>
                  <h1 className="text-center pt-2 font-medium text-lg text-white">{getUserPackagesTeamTotalCount ? getUserPackagesTeamTotalCount.teamCount : "0"}</h1>
                  <p className="text-white text-2xl text-center font-bold">
                    {/* {teamCount ? teamCount : 0} */}
                  </p>
                  <div className="flex justify-end">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-blue-400" fill="none" viewBox="0 0 24 24" stroke="#B22727" stroke-width="2">
                      <path strokeLinecap="round" stroke-linejoin="round" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                    </svg>
                  </div>
                </div>



                {/* <div className="border-l-8 border-blue-400 rounded-md bg-[#111827] shadow-xl">
                <h1 className="text-center pt-2 font-medium text-lg text-white">TOTAL REWARDS</h1>
                <p className="text-white text-2xl text-center font-bold">{user && user.refReward ? user.refReward : 0.00}</p>
                <div className="flex justify-end">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-blue-400" fill="none" viewBox="0 0 24 24" stroke="#B22727" stroke-width="2">
                    <path strokeLinecap="round" stroke-linejoin="round" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                  </svg>
                </div>
              </div> */}
              </div>



            </div>
          </div>
        </main>
      </>
    );
  }
}
function mapStateToProps(state) {
  const { users, authentication, dashboard } = state;
  return {
    users,
    authentication,
    dashboard
  };
}
export default connect(mapStateToProps)(Referral);

