import React from "react";
import {
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import Logout from "../../pages/Logout/Logout";
import Wallet from "../../pages/Wallet/Wallet";
import Header from "../Header";
import Dashboard from "../../pages/dashboard";
import Profile from "../../pages/profile";
import Referral from "../../pages/referral";
import Security from "../../pages/security";
import Transactions from "../../pages/transactions";
import UserPackagesTeam from "../../pages/UserPackagesTeam";
import CommingSoon from "../../components/commingSoon";
import Support from "../../pages/support";
import Contactus from "../../pages/contactus";
import Sidebar from "../Sidebar";
import superTransactions from "../../pages/superTransactions/superTransactions";
import MiningProfitsTransaction from "../../pages/MiningProfitsTransaction/MiningProfitsTransaction";
import teamMiningProfitsTransaction from "../../pages/teamMiningProfitsTransaction/teamMiningProfitsTransaction";
import teamWelcomeBonus from "../../pages/teamWelcomeBonus/teamWelcomeBonus";
import packageupgradation from "../../pages/packageupgradation/packageupgradation";
import incomeoverflow from "../../pages/incomeoverflow/incomeoverflow";
import UniLevelincome from "../../pages/UniLevelincome/UniLevelincome";
import directincome from "../../pages/directincome/directincome";

import referalTable from "../../pages/dashboard/components/referalTable/referalTable";
import CoinMarket from "../../pages/coinmarket/CoinMarket";
import changePassword from "../../pages/changePassword/changePassword";
import mainwallet from "../../pages/mainwallet/mainwallet";
import ExternalWallet from "../../pages/ExternalWallet/ExternalWallet";
import LtaReward from "../../pages/LtaReward/LtaReward";
import FastrackBonus from "../../pages/FastrackBonus/FastrackBonus";
import PromoPackage from "../../pages/PromoPackage/PromoPackage";
import Hierarchy from "../../pages/Hierarchy/Hierarchy";
// 
function Layout(props) {
  return (
    <div >
      <>
        <div className="overflow-hidden">
          <div className="bg-gray-100 ">
            <div className="min-h-screen flex flex-col justify-center">
              <div className="h-screen flex overflow-hidden" >
                <Sidebar />
                <div className="flex flex-col w-0 flex-1 overflow-hidden">
                  <Header history={props.history} name="" />
                  <Switch>
                    <Route path="/app/referalTable/:refId?" component={withRouter(referalTable)} />
                    <Route path="/app/changePassword" component={withRouter(changePassword)} />
                    <Route path="/contactus" component={withRouter(Contactus)} />
                    <Route path="/app/dashboard" component={withRouter(Dashboard)} />
                    <Route path="/app/wallet" component={withRouter(Wallet)} />
                    <Route path="/app/profile" component={withRouter(Profile)} />
                    <Route path="/app/referral" component={withRouter(Referral)} />
                    <Route path="/app/security" component={withRouter(Security)} />
                    <Route path="/app/support" component={withRouter(Support)} />
                    <Route path="/app/superTransactions/:id" component={withRouter(superTransactions)} />
                    <Route path="/app/transactions" component={withRouter(Transactions)} />
                    <Route path="/app/MiningProfitsTransaction" component={withRouter(MiningProfitsTransaction)} />
                    <Route path="/app/teamMiningProfitsTransaction" component={withRouter(teamMiningProfitsTransaction)} />
                    <Route path="/app/teamWelcomeBonus" component={withRouter(teamWelcomeBonus)} />
                    <Route path="/app/commingSoon" component={withRouter(CommingSoon)} />
                    <Route path="/app/logout" component={withRouter(Logout)} />
                    <Route path="/app/hierarchy" component={withRouter(Hierarchy)} />
                    <Route path="/app/packageupgradation" component={withRouter(packageupgradation)} />
                    <Route path="/app/incomeoverflow" component={withRouter(incomeoverflow)} />
                    <Route path="/app/UniLevelincome" component={withRouter(UniLevelincome)} />
                    <Route path="/app/directincome" component={withRouter(directincome)} />
                    <Route path="/app/mainwallet" component={withRouter(mainwallet)} />
                    <Route path="/app/externalwallet" component={withRouter(ExternalWallet)} />
                    <Route path="/app/ltaReward" component={withRouter(LtaReward)} />
                    <Route path="/app/fastrackBonus" component={withRouter(FastrackBonus)} />
                    <Route path="/app/coinmarket" component={withRouter(CoinMarket)} />
                    <Route path="/app/promoPackage" component={withRouter(PromoPackage)} />
                    <Route path="/app/userPackagesTeam/:id" component={withRouter(UserPackagesTeam)} />
                  </Switch>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default withRouter(Layout);
