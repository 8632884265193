import React, { Component } from 'react';
// import QRCode from 'qrcode.react';
import { userActions, alertActions } from '../../_actions';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment';
import jsPDF from "jspdf";
import "jspdf-autotable";
import Header from '../../components/Header/Header';
import CommingSoon from '../../components/commingSoon/CommingSoon';

import { authHeader } from '../../_helpers';


class Wallet extends Component {

  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      pageNo: 1,
      size: 10,
      priceTempData: {
        MAAEXCH: 1,
        btcPrice: 0,
        dollerPrice: 0,
      },
      fieldsIndex: {},
      errorsIndex: {},
      indexCardDetails: {},
      depositIndexCreateModal: false,
      WithdrawIndexCreateModal: false,
      commingSoon: false,
      showConvertCoinModal: false,
      currencyType: null,
      coinCovertFrom: {
        "from": "INR",
        "to": "MAA",
        "amount": 0
      }
    }
  }

  componentDidMount() {
    let temp = {
      "txType": "SEND_RECEIVED",
      "keyWord": "",
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    // let priceData = {
    //   "symbol": "MAAEXCH"
    // }
    this.props.dispatch(userActions.getWalletList());
    this.props.dispatch(userActions.getUserDetails());
    // this.props.dispatch(userActions.getPrice(priceData));
    this.props.dispatch(userActions.getTx(temp));
    this.loadScript("https://checkout.razorpay.com/v1/checkout.js");
    window.scrollTo(0, 0)
  }

  static getDerivedStateFromProps(nextProps, prevState) {

    if (nextProps.users.sendCoinSuccess) {
      return {
        ...nextProps,
        fieldsIndex: {},
        errorsIndex: {}
      }
    } else {
      return {
        ...nextProps
      }
    }
  }

  sendCoin = (e) => {
    e.preventDefault();
    if (this.handleValidationSendCoin()) {

      let { coinType, toAddress, recievingAmount, password } = this.state.fieldsIndex;
      this.props.dispatch(userActions.sendCoin({
        password: password,
        coinType: coinType,
        amount: Number(recievingAmount),
        to: toAddress,
      }, this.props));
    }
  }


  handleValidationSendCoin = () => {
    let fieldsIndex = this.state.fieldsIndex;
    let errorsIndex = {};
    let formIsValid = true;

    //coinType
    if (!fieldsIndex["coinType"]) {
      formIsValid = false;
      errorsIndex["coinType"] = "Please select Coin Type!";
    }
    //toAddress
    if (!fieldsIndex["toAddress"]) {
      formIsValid = false;
      errorsIndex["toAddress"] = "Please Enter To Address";
    }
    //amount
    if (!fieldsIndex["amount"]) {
      formIsValid = false;
      errorsIndex["amount"] = "Please enter Amount!";
    }
    //fees
    if (!fieldsIndex["fees"]) {
      formIsValid = false;
      errorsIndex["fees"] = "Please enter Fees!";
    }
    //password
    if (!fieldsIndex["password"]) {
      formIsValid = false;
      errorsIndex["password"] = "Please enter Password!";
    }
    //recievingAmount
    if (!fieldsIndex["recievingAmount"]) {
      formIsValid = false;
      errorsIndex["recievingAmount"] = "Please enter RecievingAmount!";
    }

    this.setState({ errorsIndex: errorsIndex });
    return formIsValid;
  }

  inputChange = (event) => {
    event.preventDefault();
    const { fieldsIndex, errorsIndex } = this.state;
    fieldsIndex[event.target.name] = event.target.value;
    errorsIndex[event.target.name] = "";
    // console.log(event.target.name, event.target.value);
    this.setState({ fieldsIndex, errorsIndex });
  }

  inputChangeAmount = (event) => {
    event.preventDefault();
    let { users } = this.props;
    let { overview } = users;
    let { setting } = overview;
    let { txFee } = setting ? setting : {};
    // console.log("event.target.value  ", event.target.value);
    if (event.target.value >= 0) {
      const { fieldsIndex, errorsIndex } = this.state;
      fieldsIndex[event.target.name] = event.target.value;
      fieldsIndex['amount'] = event.target.value;
      fieldsIndex['fees'] = txFee ? txFee : 0.0001;
      fieldsIndex['recievingAmount'] = parseFloat(event.target.value - (txFee ? txFee : 0.0001)).toFixed(8);
      errorsIndex['amount'] = "";
      this.setState({ fieldsIndex, errorsIndex });
    } else {
      const { errorsIndex } = this.state;
      errorsIndex['amount'] = "Amount should be positive number.";
      this.setState({ errorsIndex });
    }
  }

  handlePageChange = (offset, page) => {
    // console.log(offset, page)
  }

  inputChangePrice = (event) => {

    let { users } = this.props;
    let { priceDeta } = users;
    let { btcPrice, dollerPrice } = priceDeta ? priceDeta : {}


    event.preventDefault();
    const { priceTempData } = this.state;
    priceTempData[event.target.name] = event.target.value;
    priceTempData['dollerPrice'] = parseFloat(event.target.value * dollerPrice).toFixed(8);
    priceTempData['btcPrice'] = parseFloat(event.target.value * btcPrice).toFixed(8);
    this.setState({ priceTempData });
    // MAAEXCH: 1,
    //     btcPrice: 0,
    //     dollerPrice: 0,
  }


  handlePageClick = (data) => {
    // console.log("data  ", data);
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });
    let datatemp = {
      "txType": "SEND_RECEIVED",
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size
    }
    this.props.dispatch(userActions.getTx(datatemp));
  }

  handleSearch = (event) => {
    event.preventDefault();
    let { value } = event.target;
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      "txType": "SEND_RECEIVED",
      "keyWord": value,
      "pageNo": 1,
      "size": this.state.size
    }
    this.props.dispatch(userActions.getTx(data));
  }

  exportPDF = () => {
    let { users } = this.props;

    let { txList } = users;
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Send and Received Report";
    const headers = [["DATE", "TRANSACTION TYPE", "AMOUNT", "COIN", "TRANSACTION STATUS"]];
    const data = txList.map(elt => [moment(new Date(parseInt(elt.createdAt))).utcOffset("+05:30").format("YYYY-MM-DD HH:mm"), elt.txType, elt.amount, elt.coin, elt.status ? "SUCESS" : "PENDING"]);

    let content = {
      startY: 50,
      head: headers,
      body: data
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(Date.now() + "_send_received_report.pdf")
  }

  loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  displayRazorpay = async (e) => {
    // alert("hello razorPay...");

    let { users } = this.props;
    let { overview } = users;
    let { user } = overview ? overview : {}

    let header = new Headers({
      'Content-Type': 'application/json',
      "Authorization": authHeader().Authorization
    });

    let tempData = {
      amount: this.state.fieldsIndex.amt
    }

    const requestOptions = {
      method: "POST",
      headers: header,
      body: JSON.stringify(tempData)
    }
    // console.log("requestOptions  ", requestOptions);
    const data = await fetch("https://www.maafund.com/api/v1/razorpay", requestOptions).then((t) => t.json());

    const options = {
      key: "rzp_test_DauYslwjAowzOf",
      currency: data.currency,
      amount: data.amount,
      name: "MAA FUND",
      description: "Wallet Transaction",
      image: "https://www.maafund.com/images/image-1653927624336.png",
      order_id: data.id,
      // handler:  (response)=> {
      //   // alert("PAYMENT ID ::" + response.razorpay_payment_id);
      //   // alert("ORDER ID :: " + response.razorpay_order_id);

      //   this.setState({ depositIndexCreateModal: false , fieldsIndex: {},  errorsIndex: {},});
      // },
      handler: (response) => {
        // console.log("PAYMENT ID ::" + response.razorpay_payment_id);
        // console.log("ORDER ID :: " + response.razorpay_order_id);
        let temp = {
          "txType": "SEND_RECEIVED",
          "keyWord": "",
          "pageNo": this.state.pageNo,
          "size": this.state.size
        }

        this.setState({ depositIndexCreateModal: false, fieldsIndex: {}, errorsIndex: {}, });
        this.props.dispatch(userActions.getWalletList());
        this.props.dispatch(userActions.getUserDetails());
        this.props.dispatch(userActions.getTx(temp));
      },
      prefill: {
        // name: "Anirudh Jwala",
        // email: "anirudh@gmail.com",
        // contact: "9999999999",
        name: user && user.name ? user.name : "",
        email: user && user.email ? user.email : "",
        contact: user && user.mobNo ? user.mobNo : "",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  handleOpenDepositIndexModal = (data) => {
    // // console.log('handleOpenDepositIndexModal_________data::', data);
    this.setState({ depositIndexCreateModal: true, indexCardDetails: data, currencyType: data.name });
  }
  handleCloseDepositIndexModal = () => {
    this.setState({ depositIndexCreateModal: false });
  }

  handleOpenWithdrawIndexModal = (data) => {
    this.setState({
      WithdrawIndexCreateModal: true, currencyType: data.name
      //  indexCardDetails: data 
    });
  }
  handleCloseWithdrawIndexModal = () => {
    this.setState({ WithdrawIndexCreateModal: false });
  }

  handleOpencommingSoonModal = () => {
    this.setState({
      commingSoon: true
    });
  }

  handleClosecommingSoonModal = () => {
    this.setState({ commingSoon: false });
  }

  copyCodeToClipboard = (data) => {
    navigator.clipboard.writeText(data)
    this.props.dispatch(alertActions.success("Copied!"));
  }
  createWalletSubmit = (data) => {
    // console.log('data_data$$$$$', data);
    let reqData = {
      coinId: data.id
    }
    // console.log('_createWallet_______data.id::', reqData);
    this.props.dispatch(userActions.createWallet(reqData));
  }


  handleSelectCovertCoin = (e, name) => {
    let { coinCovertFrom } = this.state;

    if (name === "from" || name === "to") {
      let coinsObj = {
        "MAA Coin": "MAA",
        "INR Coin": "INR"
      }
      coinCovertFrom[name] = coinsObj[e.target.value]
    }
    else {
      coinCovertFrom[e.target.name] = e.target.value

    }

    this.setState({ coinCovertFrom });


    // coinCovertFrom[]

  }

  handleCoinConvertSubmit = () => {
    let { coinCovertFrom } = this.state;
    let data = {
      "from": coinCovertFrom.from,
      "to": coinCovertFrom.to,
      "amount": parseFloat(coinCovertFrom.amount)
    }
    this.props.dispatch(userActions.swapCoinAmount(data));
    this.handleCloseConvertCoinModal(false);
  }

  handleCloseConvertCoinModal = (check) => {
    this.setState({ showConvertCoinModal: check });
  }

  render() {

    let { users } = this.props;
    let { txList, getWalletListData, loading, overview, txTotal } = users;
    let { user } = overview ? overview : {}
    let { wallet } = getWalletListData ? getWalletListData : {}
    // console.log("overviewuseruseruser------>", user)

    return (
      <>
        <Header name="Wallet" />

        <div >
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <>
          <CommingSoon />

        </>

        {/* <main className="bg-slate-700 flex-1 relative z-0 overflow-y-auto focus:outline-none"  >
          <div className="2xl:p-10 sm:p-5 p-3">

            <center className="2xl:pt-64 pt-24 space-y-2">
              <h2 className='text-white font-semibold lg:text-5xl text-3xl font_text-sec tracking-wider animate-pulse '>
                COMMING SOON...
              </h2>
            </center>
          </div>
        </main> */}

      </>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  const { overview } = users ? users : {};
  const { setting } = overview ? overview : {};
  return {
    users,
    setting
  };
}
export default connect(mapStateToProps)(Wallet);
