import React, { Component } from "react";
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
// import Modal from 'react-modal';
import { Fragment } from 'react'
import { Transition } from '@headlessui/react'
import { Menu } from '@headlessui/react'
// import { history } from '../../_helpers/index';
import { FaRegUserCircle } from "react-icons/fa";
import { withRouter } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
// import { BiMenu } from "react-icons/bi";
// import { IoMdSettings } from "react-icons/io";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  logout = (url) => {
    window.sessionStorage.removeItem('user');
    this.props.history.push(url);
    setTimeout(() => {
    }, 150);

  }

  componentDidMount() {
    this.props.dispatch(userActions.getUserDetails());

  }

  logoutSubmit = (data) => {

    confirmAlert({
      title: 'Confirm to submit?',
      message: 'Are you sure want to logout.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.logout('/login')
        },
        {
          label: 'No'
        }
      ]
    });
  }

  classNames = (...classes) => {
    return classes.filter(Boolean).join(' ')
  }

  onClickMenu = (url) => {
    this.props.history.push(url)
    // console.log('this.props____:', this.props);
  }

  render() {



    return (
      <>

        <>

          {/* Navbar */}
          {/* 
          <div className='w-full flex justify-between items-center h-[65px] bg-[#2A3441] px-4 '>

            <div>
              <BiMenu className='text-white' size={20} />
            </div>
            <div>
              <ul className='flex space-x-3'>
                <li>
                  <img src='/imge/bell.png' alt='' className='w-5 bg-contain' />
                </li>
                <li>
                  <div className='w-5 h-5 bg-gray-500 rounded-full'></div>
                </li>
                <li>
                  <IoMdSettings className='text-white' size={22} />
                </li>
              </ul>
            </div>

          </div> */}

          <div className="relative z-10  h-16 bg-[#2A3441]">
            <div className="flex justify-between  items-center px-4">
              <div className=" w-[102px] h-[27px]">
                {/* <h1 className="text-white lg:ml-5 ml-8 md:text-lg font-medium">
                  sadfas
                </h1> */}
              </div>
              <div className="items-center py-2">
                <Menu as="div" className="relative  text-left flex lg:space-x-4 space-x-1 items-center">
                  {({ open }) => (
                    <>
                      <div className="lg:ml-6 relative pt-2 pr-2"
                      >
                        <Menu.Button className="max-w-xs flex lg:space-x-1 items-center text-sm rounded-full focus:outline-none focus:shadow-outline">
                          {/* <p className="text-white lg:text-base text-xs font-semibold capitalize">Admin</p> */}
                          <FaRegUserCircle className="text-white" size={25} />
                        </Menu.Button>
                      </div>
                      <Transition show={open} as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95">
                        <Menu.Items static className="origin-top-right absolute right-0 mt-2 w-44 top-8 font-medium rounded-md shadow-3xl bg-slate-900 ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <div className="py-1">
                            <Menu.Item>
                              {({ active }) =>
                              (<span onClick={() => this.logoutSubmit('/login')} className={this.classNames(active ? 'bg-red-500 text-white hover:bg-gray-700 hover:text-white' : 'text-white ', 'transition ease-in-out duration-500 cursor-pointer block px-4 py-1 font-semibold text-sm')}>Security</span>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) =>
                              (<span onClick={() => this.logoutSubmit('/app/changePassword')} className={this.classNames(active ? 'bg-red-500 white hover:bg-gray-700 hover:text-white' : 'text-white ', 'transition ease-in-out duration-500 cursor-pointer block px-4 py-1 font-semibold text-sm')}>Change Password</span>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) =>
                              (<span onClick={() => this.logoutSubmit('/login')} className={this.classNames(active ? 'bg-red-500 text-red-500 hover:bg-red-400 hover:text-white' : 'text-red-500 ', 'transition ease-in-out duration-500 cursor-pointer block px-4 py-1 font-semibold text-sm')}>Logout</span>
                              )}
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </>
                  )}
                </Menu>
              </div>
            </div>
          </div>
        </>

      </>
    )
  }
}
function mapStateToProps(state) {
  const { loggingIn, user, otpSent } = state.authentication;
  const { users } = state;
  return {
    loggingIn,
    otpSent,
    user,
    users
  };
}
export default withRouter(connect(mapStateToProps)(Header));
// export default NetworkDetector(connect(mapStateToProps)(Header));

// export default withRouter(Sidebar);
