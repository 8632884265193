import React, { Component } from 'react';
import { userActions, dashboardActions } from '../../_actions';
import { connect } from 'react-redux';
// import { BsCurrencyDollar } from "react-icons/bs";
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import LoadingOverlay from 'react-loading-overlay';
import { isMobile } from "react-device-detect";
import ReactPaginate from 'react-paginate';






class mainwallet extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isVerifyUserName: false,
      offset: 0,
      size: 10,
      keyWord: "",
      balType: "",
      // getUserNameVerified: "",
      pageNo: 1,
      fieldslogin: {},
      errorslogin: {},
      // showBalance: true,
      dateDetails: {
        from_date: new Date(),
        to_date: new Date(),
      },
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // if (nextProps.users.isVerifyUserName) {
    //   return {
    //     ...nextProps,
    //     fieldsAddUser: {},
    //     errorsAddUser: {},
    //     isVerifyUserName: nextProps.users.isVerifyUserName ? nextProps.users.isVerifyUserName : false,
    //     // getUserNameVerified: nextProps.users && nextProps.users.getUserNameVerified && nextProps.users.getUserNameVerified.id ? nextProps.users.getUserNameVerified.id : null


    //   }
    // }
    if (nextProps.users.sendBalanceSuccess) {
      console.log('nextProps.users.sendBalanceSuccess_____________', nextProps.users.sendBalanceSuccess);
      return {
        ...nextProps,
        isVerifyUserName: false,
        balType: "",
        fieldslogin: {},
        errorslogin: {},
      }
    }
    else {
      return {
        ...nextProps,
      }
    }
  }

  componentDidMount() {

    let data = {
      "txType": ["SEND", "RECEIVED"],
      "keyWord": "",
      "pageNo": 1,
      "size": 10
    }

    this.props.dispatch(userActions.getTxAllMulti(data));
    this.props.dispatch(userActions.getUserDetails());
  }

  sendBalance = () => {
    let { users } = this.props;
    let { getUserNameVerified } = users;

    if (this.handleValidationsendBalance()) {
      confirmAlert({
        title: 'Confirm to Send?',
        message: 'Are you sure to Send Amount ',
        buttons: [
          {
            label: 'Yes',
            onClick: () => this.props.dispatch(userActions.sendBalance({
              toUserId: getUserNameVerified.id ? getUserNameVerified.id : null,
              amount: this.state.fieldslogin.amount,
              balType: this.state.balType,
            }, this.props))
          },
          {
            label: 'No'
          }
        ]
      });
    }
  }

  sendMainBalance = () => {

    confirmAlert({
      title: 'Confirm to Send?',
      message: 'Are you sure to Send Amount ',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(dashboardActions.convertToMainBalance())
        },
        {
          label: 'No'
        }
      ]
    });

  }

  // handleShowBalance = () => {
  //   this.setState({ showBalance: !this.state.showBalance });
  // }

  handleValidationsendBalance = () => {
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = {};
    let formIsValid = true;
    // amount
    if (!fieldslogin["amount"]) {
      formIsValid = false;
      errorslogin["amount"] = "Please enter Amount!";
    }
    this.setState({ errorslogin: errorslogin });
    return formIsValid;
  }


  inputChange = (event) => {
    event.preventDefault();
    const { fieldslogin, errorslogin } = this.state;
    fieldslogin[event.target.name] = event.target.value;
    errorslogin[event.target.name] = "";
    this.setState({ fieldslogin, errorslogin });
  }

  getUserNameVerified = (e) => {
    e.preventDefault();
    if (this.handleValidationgetUserNameVerified()) {
      let reqData = {
        userName: this.state.fieldslogin.userName
      }
      this.props.dispatch(userActions.getUserNameVerified(reqData, this.props));
    }
  }

  handleValidationgetUserNameVerified = () => {
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = {};
    let formIsValid = true;

    // userName
    if (!fieldslogin["userName"]) {
      formIsValid = false;
      errorslogin["userName"] = "Please enter Amount!";
    }


    this.setState({ errorslogin: errorslogin });
    return formIsValid;
  }



  handlePageClick = (data) => {
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });
    let dataTR = {
      "txType": ["SEND", "RECEIVED"],
      "keyWord": "",
      "pageNo": data.selected + 1,
      "size": this.state.size,
    }
    this.props.dispatch(userActions.getTxAllMulti(dataTR));
  }
  onClickMenu = (url) => {
    this.props.history.push(url)
  }

  inputTypeChange = (e) => {
    e.preventDefault();
    let { value } = e.target;
    this.props.dispatch(userActions.getUserWalletByUserId());
    if (value === "ALL") {
      this.setState({ balType: value })
    } else {
      this.setState({ balType: value })
    }
  }


  render() {

    let { users, wallet } = this.props;
    let { txList, loading, txTotal, getUserNameVerified, userWalletData } = users;
    console.log('userWalletData____________-RENDER', userWalletData);
    console.log('balType__________________', this.state.balType);

    return (

      <>

        <div >
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <main className="bg_page flex-1 relative z-0 overflow-y-auto focus:outline-none lg:mb-0 mb-10" tabIndex={0}>
          <div className=" relative" id="wave">

            <div className="2xl:p-10 p-5">
              <div className="max-w-screen-3xl mx-auto md:w-full">
                <div className='grid lg:grid-cols-2 grid-cols-1 gap-4 '>
                  <div className="pb-4">
                    <div className="flex justify-between text-white bg-[#111627] py-4 px-4">
                      <h1 className="px-2 text-lg font-semibold">Balance</h1>
                      <h1 className="px-2 text-2xl text-green-700 font-bold">{wallet && wallet.balance ? wallet.balance.toFixed(2) : 0}$</h1>
                    </div>
                  </div>
                  <div className="pb-4">
                    <div className="flex justify-between text-white bg-[#111627] py-4 px-4">
                      <h1 className="px-2 text-lg font-semibold">Payout</h1>
                      {/* {
                        this.state.showBalance ?
                          <div className="flex items-center lg:mr-5">
                            <h className="px-2 text-2xl text-green-700 font-bold">xxxx</h>
                          </div>
                          :
                          <div className="flex items-center lg:mr-5">
                            <h className="px-2 text-2xl text-green-700 font-bold">{wallet && wallet.wBalance ? wallet.wBalance.toFixed(2) : 0}$</h>
                          </div>
                      } */}
                      {/* <div className="items-center lg:mr-5 lg:ml-0 ml-5 cursor-pointer flex flex-wrap">
                        <h1 className="w-25 py-1 px-1 cursor-pointer bg-[#790000] rounded-md text-center text-xs font-semibold text-white hover:bg-shine-400 focus:outline-none" onClick={this.handleShowBalance}>
                          {this.state.showBalance ? "Show balance" : "Hide balance"}
                        </h1>
                      </div> */}
                      <h1 className="px-2 text-2xl text-green-700 font-bold">{wallet && wallet.wBalance ? wallet.wBalance.toFixed(2) : 0}$</h1>
                      <button type="button" onClick={() => this.sendMainBalance()} className="w-36 py-1 px-1 cursor-pointer bg-[#790000] rounded-md text-xs font-semibold text-white hover:bg-shine-400 focus:outline-none">Convert To Main Wallet</button>
                    </div>
                  </div>
                </div>
                <section className="grid grid-cols-12 gap-5">
                  <div className="bg-slate-900 2xl:col-span-5 xl:col-span-6 lg:col-span-6 col-span-12 2xl:py-7 py-5 2xl:px-10 px-5 flex-col rounded-md">
                    <h3 className="md:text-2xl text-lg leading-6 font-semibold uppercase text-white text-opacity-70 tracking-widest">Send Fund</h3>

                    <form autoComplete="off" className="space-y-2 mt-2">
                      <div className="relative w-48 border border-gray-300 text-gray-800 bg-white shadow-lg appearance-none">
                        <label for="frm-whatever" className="sr-only">My field</label>
                        <select className="appearance-none w-full p-2 bg-white text-sm font-normal" onChange={this.inputTypeChange}
                          id="txType"
                          value={this.state.balType}
                          name="txType">
                          <option selected value="">Please choose&hellip;</option>
                          <option value="balance">Balance</option>
                          <option value="wBalance">Payout </option>
                        </select>
                        <div className="pointer-events-none absolute right-0 top-0 bottom-0 flex items-center px-2 text-gray-700 border-l">
                          <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                          </svg>
                        </div>
                      </div>


                      <div>

                        {this.state.balType == "balance" ?
                          <>
                            <input type="text" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-20 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" value={userWalletData ? userWalletData.balance.toFixed(2) : 0}>
                              {/* {userWalletData ? userWalletData.balance.toFixed(2) : 0}
                              {userWalletData ? userWalletData.balance.toFixed(2) : 0} */}
                            </input>
                          </>
                          : null}
                        {this.state.balType == "wBalance" ?
                          <input type="text" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-20 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" value={userWalletData ? userWalletData.wBalance.toFixed(2) : 0} />
                          // <h2>
                          //   {userWalletData ? userWalletData.wBalance.toFixed(2) : 0}
                          // </h2>
                          : null}
                      </div>


                      <div className="grid lg:grid-cols-1 grid-cols-1 gap-6 mt-6">
                        <div className="{otpSent?'disableArea':''}">
                          <label className="text-white text-sm">Enter User Name</label>
                          <div className="mt-1 rounded-md shadow-sm relative">
                            <input className={`appearance-none block w-full px-4 pl-4 py-2 h-12 rounded-xl text-gray-800 bg-gray-50 focus:outline-none focus:border-gray-200 focus:bg-gray-100 focus:text-gray-800 transition duration-150 ease-in-out text-lg font-normal sm:leading-5 ${!this.state.errorslogin["userName"] ? "'border-opacity-20 border-opacity-100 border placeholder-gray-700" : "border-opacity-100 border border-red-500"}`}

                              value={this.state.fieldslogin && this.state.fieldslogin['userName'] ? this.state.fieldslogin['userName'] : ''}
                              id="userName" name="userName" placeholder="Enter a User Name" type="userName" onChange={this.inputChange} />
                            {this.state.errorslogin["userName"] ?
                              <div className="invalid-feedback text-yellow-400">
                                {this.state.errorslogin["user Name"]}
                              </div>
                              : null}
                            <div className="flex justify-between px-4">
                              <p className="mt-2 text-white text-base cursor-pointer">{getUserNameVerified && getUserNameVerified.email ? getUserNameVerified.email : null}</p>
                              <label for="exampleFormControlInput1" className="mt-2 text-white text-base underline cursor-pointer" onClick={this.getUserNameVerified}>
                                {this.state.isVerifyUserName ? "Verified" : "Verify"}</label>
                            </div>
                          </div>
                        </div>
                      </div>


                      <div className="grid lg:grid-cols-1 grid-cols-1 gap-6">
                        <div className="mt-4">
                          <label className="text-white text-sm">Amount</label>
                          <div className="mt-1 rounded-md shadow-sm relative">
                            <input className={`appearance-none block w-full px-4 pl-4 py-2 h-12 rounded-xl text-gray-800 bg-gray-50 focus:outline-none focus:border-gray-200 focus:bg-gray-100 focus:text-gray-800 transition duration-150 ease-in-out text-lg font-normal sm:leading-5 ${!this.state.errorslogin["amount"] ? "'border-opacity-20 border-opacity-100 border placeholder-gray-700" : "border-opacity-100 border border-red-500 bg-dark-400"}`}
                              id="amount" name="amount" placeholder="Amount" type="number"
                              value={this.state.fieldslogin && this.state.fieldslogin['amount'] ? this.state.fieldslogin['amount'] : ''}
                              onChange={this.inputChange} />
                            {this.state.errorslogin["amount"] ?
                              <div className="invalid-feedback text-yellow-400">
                                {this.state.errorslogin["amount"]}
                              </div>
                              : null}
                          </div>
                        </div>
                      </div>


                      <div className=" grid lg:grid-cols-1 grid-cols-1 gap-6 pt-6">
                        <span className="block w-full rounded-md shadow-sm">
                          <button className="lg:w-3/6 w-full mx-auto flex justify-center py-2 uppercase px-3 border border-transparent text-sm font-meduim rounded-md text-white bg-[#DC2626] focus:outline-none transition duration-150 ease-in-out" type="button" onClick={() => this.sendBalance()} data-config-id="01_primary-action">Success</button>
                        </span>
                      </div>
                    </form>
                  </div>

                  <div className="bg-slate-900 2xl:col-span-7 lg:mb-0 xl:col-span-6 lg:col-span-6 col-span-12 2xl:py-7 py-5 px-5 flex-col rounded-md ">
                    <div className="w-full flex justify-between flex-wrap">
                      <h3 className="md:text-2xl mb-2 text-lg leading-6 md:leading-9 text-center font-semibold uppercase text-white text-opacity-70 tracking-widest">Transaction History</h3>
                      <button onClick={() => this.onClickMenu('/app/transactions')} className="w-32 py-1 cursor-pointer bg-[#790000] rounded-md text-xs font-semibold text-white hover:bg-shine-400 focus:outline-none">See All Transaction</button>
                    </div>
                    <div className=" overflow-hidden pb-2 mt-4 shadow-md">
                      <div className="overflow-x-auto max-w-full ">
                        <div className="inline-block min-w-full  ">
                          <div className="overflow-x-auto">

                            <table className="min-w-full divide-y divide-gray-800 border-0 ">
                              <thead className="border border-black">
                                <tr className="border border-black text-center">
                                  <th scope="col" className="whitespace-nowrap border border-white text-center px-[49px] bg-[#790000] py-3 text-xs font-semibold text-white uppercase">S/n</th>
                                  <th scope="col" className="whitespace-nowrap border border-white text-center px-[38px] py-3 bg-[#790000] text-xs font-semibold text-white capitalize">date</th>
                                  {/* <th scope="col" className="whitespace-nowrap border border-white text-center px-[49px] bg-[#790000] py-3 text-xs font-semibold text-white uppercase">coin name</th> */}

                                  <th scope="col" className="whitespace-nowrap border border-white text-center px-[45px] bg-[#790000] py-3 text-xs font-semibold text-white capitalize">Transaction Type</th>
                                  <th scope="col" className="whitespace-nowrap border border-white text-center px-[22px] py-3 bg-[#790000] text-xs font-semibold text-white capitalize">amount</th>
                                  <th scope="col" className="whitespace-nowrap border border-white text-center px-[31px] py-3 bg-[#790000] text-xs font-semibold text-white capitalize">Received From</th>

                                </tr>
                              </thead>
                              <tbody>

                                {
                                  txList && txList.length > 0 ?
                                    txList.map((element, index) => (
                                      <>
                                        <tr className="border border-black text-center">
                                          <td className="whitespace-nowrap border border-gray-300 text-center px-[13px] py-3 text-xs font-semibold text-black bg-white uppercase bg-whj">{this.state.offset + index + 1}</td>
                                          <td className="whitespace-nowrap border border-gray-300 text-center px-[33px] py-3 text-xs font-semibold text-black bg-white capitalize bg-whj">{moment(new Date(parseInt(element && element.createdAt ? element.createdAt : "-"))).utcOffset("+05:30").format("DD-MM-YYYY HH:mm")}</td>
                                          {/* <td className="whitespace-nowrap border border-gray-300 text-center px-[13px] py-3 text-xs font-semibold text-black bg-white uppercase bg-whj">{element && element.coinId && element.coinId.name ? element.coinId.name : "-"}</td> */}
                                          {/* <td className="whitespace-nowrap border border-gray-300 text-center px-[55px] py-3 text-xs font-semibold text-black bg-white capitalize bg-whj">{element && element.coinId && element.coinId.price ? element.coinId.price : 0} </td> */}
                                          <td className="whitespace-nowrap border border-gray-300 text-center px-[25px] py-3 text-xs font-semibold text-black bg-white capitalize bg-whj">{element && element.txType ? element.txType : "-"}</td>
                                          <td className="whitespace-nowrap border border-gray-300 text-center px-[51px] py-3 text-xs font-semibold text-black bg-white capitalize bg-whj">{element && element.amount ? element.amount : "-"}</td>
                                          <td className="whitespace-nowrap border border-gray-300 text-center px-[11.5px] py-3 text-xs font-semibold text-black bg-white uppercase bg-whj">{element && element.userRecId && element.userRecId.userName ? element.userRecId.userName : "-"} </td>

                                        </tr>
                                      </>
                                    )) : null
                                }
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      {
                        isMobile ?
                          <nav className="relative z-0 flex justify-end mt-5 w-76">
                            {
                              txTotal && txTotal > 10 ?
                                <ReactPaginate
                                  previousLabel={'Prev'}
                                  nextLabel={'Next'}
                                  breakLabel={'...'}
                                  breakClassName={'break-me'}
                                  pageCount={txTotal / this.state.size}
                                  marginPagesDisplayed={0}
                                  pageRangeDisplayed={2}
                                  onPageChange={this.handlePageClick}
                                  containerClassName={'pagination'}
                                  pageClassName={'page-cls'}
                                  activeClassName={'active'}
                                />
                                : null}
                          </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                            {
                              txTotal && txTotal > 10 ?
                                <ReactPaginate
                                  previousLabel={'Previous'}
                                  nextLabel={'Next'}
                                  breakLabel={'...'}
                                  breakClassName={'break-me'}
                                  pageCount={txTotal / this.state.size}
                                  marginPagesDisplayed={3}
                                  pageRangeDisplayed={3}
                                  onPageChange={this.handlePageClick}
                                  containerClassName={'pagination'}
                                  pageClassName={'page-cls'}
                                  activeClassName={'active'}
                                />
                                : null}
                          </nav>
                      }

                    </div>
                  </div>
                </section>

              </div>
            </div>
          </div>
        </main>


      </>

    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  const { overview } = users ? users : {};
  const { setting } = overview ? overview : {};
  const { wallet } = overview ? overview : {};
  // console.log("overviewoverviewoverview____mapstateprops:::", overview);
  // console.log("overviewover______setting___mapstateprops:::", setting);
  // console.log("overviewover______wallet___mapstateprops:::", wallet);
  return {
    users,
    setting,
    wallet
  };
}
export default connect(mapStateToProps)(mainwallet);