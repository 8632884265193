import React, { Component } from 'react';
import { userActions } from '../../_actions';
import { connect } from 'react-redux';
import { RiFacebookFill, RiInstagramLine, RiTwitterLine } from "react-icons/ri";
import { BiEdit, BiX } from "react-icons/bi";
import LoadingOverlay from 'react-loading-overlay';


class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.saveUserInfo = this.saveUserInfo.bind(this);
    this.saveUserPersonalAddr = this.saveUserPersonalAddr.bind(this);
    this.saveSocialMedia = this.saveSocialMedia.bind(this);
    this.inputChange = this.inputChange.bind(this);
    this.state = {
      isDisabledPersonal: true,
      isDisabledAddress: true,
      isDisabledSocialMedia: true,
      fieldsUser: {},
      errorsUser: {},
      fieldsSocialMedia: {},
      errorsSocialMedia: {},
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.users.isPasswordUpdate) {
      return {
        ...nextProps,
        fieldsUser: {},
        errorsUser: {},
        fieldsSocialMedia: {},
        errorsSocialMedia: {},

      }
    } else if (nextProps.users.isDisabledPersonal) {
      return {
        ...nextProps,
        isDisabledPersonal: true,
        isDisabledAddress: true,
        isDisabledSocialMedia: true,
        // isDisabledPersonal: true
      }
    } else {
      return {
        ...nextProps,

      }
    }
  }


  inputChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    this.setState({ [name]: value });
    let fieldsUser = this.state.fieldsUser;
    let errorsUser = this.state.errorsUser;
    fieldsUser[name] = value;
    errorsUser[name] = "";
    // console.log(name, value);
    this.setState({ fieldsUser, errorsUser });
  }

  inputChangeSocialMedia = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    this.setState({ [name]: value });
    let fieldsSocialMedia = this.state.fieldsSocialMedia;
    let errorsSocialMedia = this.state.errorsSocialMedia;
    fieldsSocialMedia[name] = value;
    errorsSocialMedia[name] = "";
    // console.log(name, value);
    this.setState({ fieldsSocialMedia, errorsSocialMedia });
  }

  saveUserInfo(e) {
    e.preventDefault();
    if (this.handleValidationUserInfo()) {
      let { firstName, lastName, mobNo } = this.state.fieldsUser;
      this.props.dispatch(userActions.saveUserInfo({
        firstName: firstName,
        lastName: lastName,
        mobNo: mobNo
      }, this.props));
    }
  }

  handleValidationUserInfo = () => {
    let fieldsUser = this.state.fieldsUser;
    let errorsUser = {};
    let formIsValid = true;

    //firstName
    if (!fieldsUser["firstName"] || !fieldsUser["firstName"].match("^[A-Za-z]+(((\\'|\\-|\\.)?([A-Za-z])+))?$")) {
      formIsValid = false;
      errorsUser["firstName"] = "Please Enter Valid firstName!";
    }

    //lastName
    if (!fieldsUser["lastName"] || !fieldsUser["lastName"].match("^[A-Za-z]+(((\\'|\\-|\\.)?([A-Za-z])+))?$")) {
      formIsValid = false;
      errorsUser["lastName"] = "Please Enter Valid lastName!";
    }

    if (!fieldsUser["mobNo"]) {
      formIsValid = false;
      errorsUser["mobNo"] = "Please enter Mobile Number!";
    }

    // console.log("errorsUsererrorsUsererrorsUser", errorsUser);

    this.setState({ errorsUser: errorsUser });
    return formIsValid;
  }

  saveUserPersonalAddr(e) {
    e.preventDefault();
    if (this.handleValidationAddrInfo()) {
      let { street, city, state, country, postCode } = this.state.fieldsUser;
      this.props.dispatch(userActions.saveUserPersonalAddr({
        street: street,
        city: city,
        state: state,
        country: country,
        postCode: postCode
      }, this.props));
    }
  }


  handleValidationAddrInfo = () => {
    let fieldsUser = this.state.fieldsUser;
    let errorsUser = {};
    let formIsValid = true;


    //street
    if (!fieldsUser["street"] || fieldsUser["street"] === "") {
      formIsValid = false;
      errorsUser["street"] = "Cannot Be Empty";
    }

    //city
    if (!fieldsUser["city"] || fieldsUser["city"] === "") {
      formIsValid = false;
      errorsUser["city"] = "Cannot Be Empty";
    }

    //state
    if (!fieldsUser["state"] || fieldsUser["state"] === "") {
      formIsValid = false;
      errorsUser["state"] = "Cannot Be Empty";
    }

    //country
    if (!fieldsUser["country"] || fieldsUser["country"] === "") {
      formIsValid = false;
      errorsUser["country"] = "Cannot Be Empty";
    }

    //postCode
    if (!fieldsUser["postCode"] || fieldsUser["postCode"] === "") {
      formIsValid = false;
      errorsUser["postCode"] = "Cannot Be Empty";
    }

    // console.log("errorsUsererrorsUsererrorsUser", errorsUser);

    this.setState({ errorsUser: errorsUser });
    return formIsValid;
  }

  saveSocialMedia(e) {
    e.preventDefault();
    if (this.handleValidationSocialMedia()) {
      let { facebook, instagram, twitter, linkedin } = this.state.fieldsSocialMedia;
      this.props.dispatch(userActions.saveSocialMedia({
        facebook: facebook,
        instagram: instagram,
        twitter: twitter,
        linkedin: linkedin
      }, this.props));
    }
  }


  handleValidationSocialMedia = () => {
    let fieldsSocialMedia = this.state.fieldsSocialMedia;
    let errorsSocialMedia = {};
    let formIsValid = true;

    //facebook
    if (!fieldsSocialMedia["facebook"] || !fieldsSocialMedia["facebook"] === "") {
      formIsValid = false;
      errorsSocialMedia["facebook"] = "Please Enter Valid facebook!";
    }

    //instagram
    if (!fieldsSocialMedia["instagram"] || !fieldsSocialMedia["instagram"] === "") {
      formIsValid = false;
      errorsSocialMedia["instagram"] = "Please Enter Valid instagram!";
    }

    //twitter
    if (!fieldsSocialMedia["twitter"] || !fieldsSocialMedia["twitter"] === "") {
      formIsValid = false;
      errorsSocialMedia["twitter"] = "Please Enter Valid twitter!";
    }

    //linkedin
    if (!fieldsSocialMedia["linkedin"] || !fieldsSocialMedia["linkedin"] === "") {
      formIsValid = false;
      errorsSocialMedia["linkedin"] = "Please Enter Valid linkedin!";
    }

    // console.log("errorsUsererrorsUsererrorsUser", errorsSocialMedia);

    this.setState({ errorsSocialMedia: errorsSocialMedia });
    return formIsValid;
  }

  componentDidMount() {
    this.props.dispatch(userActions.getUserInfo());
    this.props.dispatch(userActions.getUserDetails());
    this.props.dispatch(userActions.getSocialMediaById());
    window.scrollTo(0, 0)
  }

  updateProfile = (e) => {
    let { users } = this.props;
    let { getUserInfo } = users;
    this.setState({
      fieldsUser: getUserInfo,
      isDisabledPersonal: false,
    })
  }
  cancelProfile = (e) => {
    this.setState({
      isDisabledPersonal: true,
    })
  }

  updateProfileAddress = (e) => {
    let { users } = this.props;
    let { getUserInfo } = users;
    this.setState({
      fieldsUser: getUserInfo,
      isDisabledAddress: false,
    })
  }
  cancelProfileAddress = (e) => {
    this.setState({
      isDisabledAddress: true,
    })
  }
  updateSocialMedia = (e) => {
    let { users } = this.props;
    let { getSocialMediaById } = users;

    // console.log("getSocialMediaByIdgetSocialMediaById  ", getSocialMediaById);
    let currentData = {
      facebook: getSocialMediaById && getSocialMediaById.facebook ? getSocialMediaById.facebook : '',
      instagram: getSocialMediaById && getSocialMediaById.instagram ? getSocialMediaById.instagram : '',
      twitter: getSocialMediaById && getSocialMediaById.twitter ? getSocialMediaById.twitter : '',
      linkedin: getSocialMediaById && getSocialMediaById.linkedin ? getSocialMediaById.linkedin : '',
    }
    this.setState({
      fieldsSocialMedia: currentData,
      isDisabledSocialMedia: false,
    })
  }
  cancelSocialMedia = (e) => {
    this.setState({
      isDisabledSocialMedia: true,
    })
  }

  render() {
    let { users, user } = this.props;
    let { loading, getUserInfo, getSocialMediaById } = users;

    // // console.log("RENDER_____________getSocialMediaById::", getSocialMediaById);
    // // console.log("RENDER_____________getUserInfo::", getUserInfo);


    return (
      <>

        <div >
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>


        <div className="h-screen overflow-y-auto bg-[#1f2837] py-6">
          <div className="bg-[#111627] mt-2 mx-5">
            <h1 className="text-white py-2 px-6 text-xl">My Profile</h1>
          </div>


          <div className="grid xl:grid-cols-2 gap-2 py-4 mx-5">
            <div className='bg-[#111627] py-2 rounded-sm text-white'>

              <div className="text-center p-4">
                <button className="bg-white hover:bg-blue-700 text-white font-bold py-10 px-10 rounded-full"></button>
                <h1 className="py-1 text-xl">{user && user.userName ? user.userName : "-"}</h1>
                <p className='text-sm text-white uppercase'>{getUserInfo && getUserInfo.firstName ? getUserInfo.firstName : ""} {getUserInfo && getUserInfo.lastName ? getUserInfo.lastName : ""}</p>
                <div className="border-b border-white py-2 mx-6"></div>
              </div>
              <div className="mx-5 space-y-2 text-center">
                {/* <p className="text-white text-base">{getUserInfo && getUserInfo.email ? getUserInfo.email : ""}</p> */}
                <p className="text-white text-base">{getUserInfo && getUserInfo.mobNo ? "+91 " + getUserInfo.mobNo : ""}</p>

                <p className="text-white text-base">{getUserInfo && getUserInfo.street ? getUserInfo.street + "," : ""} {getUserInfo && getUserInfo.city ? getUserInfo.city + "," : ""} {getUserInfo && getUserInfo.state ? getUserInfo.state + "," : ""} {getUserInfo && getUserInfo.country ? getUserInfo.country : ""}</p>

                <p className="text-white text-lg mt-4">SOCIAL PROFILE</p>
              </div>

              <div className="space-y-2 py-2">
                <div className="md:w-4/5 w-full mx-auto">
                  <div className="flex justify-around flex-wrap items-center px-2 space-y-4">

                    <span className='flex mt-4'>
                      <span className="bg-[#3B5998] border border-none rounded-full p-2 cursor-pointer">
                        <RiFacebookFill className="w-5 h-5" />
                      </span>
                      <p className='text-white mx-1 capitalize mt-2'>{getSocialMediaById && getSocialMediaById.facebook ? getSocialMediaById.facebook : ""}</p>
                    </span>
                    <span className='flex'>
                      <span className="bg-[#4099ff] border border-none rounded-full p-2 cursor-pointer">
                        <RiTwitterLine className='w-5 h-5' />
                      </span>
                      <p className='text-white mx-1 capitalize mt-2'>{getSocialMediaById && getSocialMediaById.twitter ? getSocialMediaById.twitter : ""}</p>
                    </span>
                    <span className='flex'>
                      <span className="bg-[#e1306c] border border-none rounded-full p-2 cursor-pointer">
                        <RiInstagramLine className='w-5 h-5' />
                      </span>
                      <p className='text-white mx-1 capitalize mt-2'>{getSocialMediaById && getSocialMediaById.instagram ? getSocialMediaById.instagram : ""}</p>
                    </span>
                    <span className='flex'>
                      <span className="bg-[#e1306c] border border-none rounded-full p-2 cursor-pointer">
                        <RiInstagramLine className='w-5 h-5' />
                      </span>
                      <p className='text-white mx-1 capitalize mt-2'>{getSocialMediaById && getSocialMediaById.linkedin ? getSocialMediaById.linkedin : ""}</p>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className='bg-[#111627]  text-white'>

              <div className='px-6 space-y-4'>
                {/* <h2 className='text-xl tracking-wider mt-4'>Personal Infomation</h2> */}

                <div className="flex justify-between flex-wrap mt-4">
                  <h3 className="text-xl tracking-wider">Personal Information</h3>
                  {
                    this.state.isDisabledPersonal === true ?
                      <button className="p-1 uppercase border border-transparent text-sm font-meduim rounded-full text-white bg-[#DC2626] focus:outline-none transition duration-150 ease-in-out" type="button" onClick={this.updateProfile} ><BiEdit className='w-5 h-5' /></button> : null
                  }
                  {
                    this.state.isDisabledPersonal === false ?
                      <button className="p-1 uppercase border border-transparent text-sm font-meduim rounded-full text-white bg-[#DC2626] focus:outline-none transition duration-150 ease-in-out" type="button" onClick={this.cancelProfile} ><BiX className='w-5 h-5' /></button> : null
                  }
                </div>

                <div className="w-full">
                  <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full md:w-1/2 px-3 md:mb-0">
                      <label className="block tracking-wide text-white text-xs font-bold mb-2" for="grid-first-name">First Name</label>
                      <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white" name="firstName" placeholder='First Name' type="text"
                        value={this.state.isDisabledPersonal && getUserInfo && getUserInfo["firstName"] ? getUserInfo["firstName"] : this.state.fieldsUser["firstName"] || ''}
                        onChange={this.inputChange}
                        disabled={this.state.isDisabledPersonal} />
                      {this.state.errorsUser["firstName"] ?
                        <div className="invalid-feedback text-red-500 text-xs italic">
                          {this.state.errorsUser["firstName"]}
                        </div>
                        : null}
                    </div>
                    <div className="w-full md:w-1/2 px-3">
                      <label className="block tracking-wide text-white text-xs font-bold mb-2" for="grid-last-name">
                        Last Name
                      </label>
                      <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" name="lastName" placeholder='Last Name' type="text"
                        value={this.state.isDisabledPersonal && getUserInfo && getUserInfo["lastName"] ? getUserInfo["lastName"] : this.state.fieldsUser["lastName"] || ''}
                        onChange={this.inputChange}
                        disabled={this.state.isDisabledPersonal}
                      />
                      {this.state.errorsUser["lastName"] ?
                        <div className="invalid-feedback text-red-500 text-xs italic">
                          {this.state.errorsUser["lastName"]}
                        </div>
                        : null}
                    </div>
                  </div>
                </div>
                <div className="w-full">
                  <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full md:w-1/2 px-3">
                      <label className="block tracking-wide text-white text-xs font-bold mb-2" for="grid-last-name">
                        Phone Number
                      </label>
                      <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" name="mobNo" placeholder='Enter Phone Number' type="number"
                        value={this.state.isDisabledPersonal && getUserInfo && getUserInfo["mobNo"] ? getUserInfo["mobNo"] : this.state.fieldsUser["mobNo"] || ''}
                        onChange={this.inputChange}
                        disabled={this.state.isDisabledPersonal} />
                      {this.state.errorsUser["mobNo"] ?
                        <div className="invalid-feedback text-red-500 text-xs italic">
                          {this.state.errorsUser["mobNo"]}
                        </div>
                        : null}
                    </div>
                  </div>
                </div>

                <div className="grid lg:grid-cols-1 grid-cols-1 gap-6 py-6">
                  {
                    this.state.isDisabledPersonal === false ?
                      <span className="block w-full rounded-md shadow-sm">
                        <button className="lg:w-2/5 w-full mx-auto flex justify-center py-2 uppercase px-3 border border-transparent text-sm font-meduim rounded-md text-white bg-[#DC2626] focus:outline-none transition duration-150 ease-in-out" type="button" disabled={this.state.isDisabledPersonal} onClick={this.saveUserInfo} data-config-id="01_primary-action">SUBMIT</button>
                      </span>
                      : null
                  }
                </div>

              </div>


            </div>
          </div>


          <div className="grid xl:grid-cols-2 gap-2  mx-5">
            <div className='bg-[#111627] text-white'>
              <div className='px-6 space-y-4'>
                <div className="flex justify-between flex-wrap mt-4">
                  <h3 className="text-xl tracking-wider">Personal Address</h3>
                  {
                    this.state.isDisabledAddress === true ?
                      <button className="p-1 uppercase border border-transparent text-sm font-meduim rounded-full text-white bg-[#DC2626] focus:outline-none transition duration-150 ease-in-out" type="button" onClick={this.updateProfileAddress} ><BiEdit className='w-5 h-5' /></button> : null
                  }
                  {
                    this.state.isDisabledAddress === false ?
                      <button className="p-1 uppercase border border-transparent text-sm font-meduim rounded-full text-white bg-[#DC2626] focus:outline-none transition duration-150 ease-in-out" type="button" onClick={this.cancelProfileAddress} ><BiX className='w-5 h-5' /></button> : null
                  }
                </div>
                <div className="w-full">
                  <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full md:w-1/2 px-3 md:mb-0">
                      <label className="block tracking-wide text-white text-xs font-bold mb-2" for="grid-first-name">Street</label>
                      <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white" name="street" placeholder='A-485,Lorem Ipsum,city' type="text"
                        value={this.state.isDisabledAddress && getUserInfo && getUserInfo["street"] ? getUserInfo["street"] : this.state.fieldsUser["street"] || ''}
                        onChange={this.inputChange}
                        disabled={this.state.isDisabledAddress} />
                      {this.state.errorsUser["street"] ?
                        <div className="invalid-feedback text-red-500 text-xs italic">
                          {this.state.errorsUser["street"]}
                        </div>
                        : null}
                    </div>
                    <div className="w-full md:w-1/2 px-3">
                      <label className="block tracking-wide text-white text-xs font-bold mb-2" for="grid-last-name">City</label>
                      <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" name="city" placeholder='Your City' type="text"
                        value={this.state.isDisabledAddress && getUserInfo && getUserInfo["city"] ? getUserInfo["city"] : this.state.fieldsUser["city"] || ''}
                        onChange={this.inputChange}
                        disabled={this.state.isDisabledAddress} />
                      {this.state.errorsUser["city"] ?
                        <div className="invalid-feedback text-red-500 text-xs italic">
                          {this.state.errorsUser["city"]}
                        </div>
                        : null}
                    </div>
                  </div>
                </div>

                <div className="w-full">
                  <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full md:w-1/2 px-3 md:mb-0">
                      <label className="block tracking-wide text-white text-xs font-bold mb-2" for="grid-first-name">State</label>
                      <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white" name="state" placeholder='State' type="text"
                        value={this.state.isDisabledAddress && getUserInfo && getUserInfo["state"] ? getUserInfo["state"] : this.state.fieldsUser["state"] || ''}
                        onChange={this.inputChange}
                        disabled={this.state.isDisabledAddress} />
                      {this.state.errorsUser["state"] ?
                        <div className="invalid-feedback text-red-500 text-xs italic">
                          {this.state.errorsUser["state"]}
                        </div>
                        : null}
                    </div>
                    <div className="w-full md:w-1/2 px-3">
                      <label className="block tracking-wide text-white text-xs font-bold mb-2" for="grid-last-name">Country</label>
                      <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" name="country" placeholder='Country'
                        value={this.state.isDisabledAddress && getUserInfo && getUserInfo["country"] ? getUserInfo["country"] : this.state.fieldsUser["country"] || ''}
                        onChange={this.inputChange}
                        disabled={this.state.isDisabledAddress} />
                      {this.state.errorsUser["country"] ?
                        <div className="invalid-feedback text-red-500 text-xs italic">
                          {this.state.errorsUser["country"]}
                        </div>
                        : null}
                    </div>
                  </div>
                </div>

                <div className="w-full">
                  <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full md:w-1/2 px-3 md:mb-0">
                      <label className="block tracking-wide text-white text-xs font-bold mb-2" for="grid-first-name">Post Code</label>
                      <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white" name="postCode" placeholder='Post Code' type="text"
                        value={this.state.isDisabledAddress && getUserInfo && getUserInfo["postCode"] ? getUserInfo["postCode"] : this.state.fieldsUser["postCode"] || ''}
                        onChange={this.inputChange}
                        disabled={this.state.isDisabledAddress} />
                      {this.state.errorsUser["postCode"] ?
                        <div className="invalid-feedback text-red-500 text-xs italic">
                          {this.state.errorsUser["postCode"]}
                        </div>
                        : null}
                    </div>
                  </div>
                </div>


                <div className=" grid lg:grid-cols-1 grid-cols-1 gap-6 py-6">
                  {
                    this.state.isDisabledAddress === false ?
                      <span className="block w-full rounded-md shadow-sm">
                        <button className="lg:w-2/5 w-full mx-auto flex justify-center py-2 uppercase px-3 border border-transparent text-sm font-meduim rounded-md text-white bg-[#DC2626] focus:outline-none transition duration-150 ease-in-out" type="button" disabled={this.state.isDisabledAddress} onClick={this.saveUserPersonalAddr} data-config-id="01_primary-action">SUBMIT</button>
                      </span>
                      : null
                  }
                </div>
              </div>
            </div>



            <div className='bg-[#111627]  text-white'>
              <div className='px-6 space-y-4'>
                <div className="flex justify-between flex-wrap mt-4">
                  <h3 className="text-xl tracking-wider">Social Media</h3>
                  {
                    this.state.isDisabledSocialMedia === true ?
                      <button className="p-1 uppercase border border-transparent text-sm font-meduim rounded-full text-white bg-[#DC2626] focus:outline-none transition duration-150 ease-in-out" type="button" onClick={this.updateSocialMedia}><BiEdit className='w-5 h-5' /></button> : null
                  }
                  {
                    this.state.isDisabledSocialMedia === false ?
                      <button className="p-1 uppercase border border-transparent text-sm font-meduim rounded-full text-white bg-[#DC2626] focus:outline-none transition duration-150 ease-in-out" type="button" onClick={this.cancelSocialMedia}><BiX className='w-5 h-5' /></button> : null
                  }
                </div>
                <div className="w-full">
                  <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full md:w-1/2 px-3 md:mb-0">
                      <label className="block tracking-wide text-white text-xs font-bold mb-2" for="grid-first-name">Facebook</label>
                      <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white" name="facebook" placeholder='Facebook Id' type="text"
                        value={this.state.isDisabledSocialMedia && getSocialMediaById && getSocialMediaById["facebook"] ? getSocialMediaById["facebook"] : this.state.fieldsSocialMedia["facebook"] || ''}
                        onChange={this.inputChangeSocialMedia}
                        disabled={this.state.isDisabledSocialMedia} />
                      {this.state.errorsSocialMedia["facebook"] ?
                        <div className="invalid-feedback text-red-500 text-xs italic">
                          {this.state.errorsSocialMedia["facebook"]}
                        </div>
                        : null}
                    </div>
                    <div className="w-full md:w-1/2 px-3">
                      <label className="block tracking-wide text-white text-xs font-bold mb-2" for="grid-last-name">Instagram</label>
                      <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" name="instagram" placeholder='Instagram Id' type="text"
                        value={this.state.isDisabledSocialMedia && getSocialMediaById && getSocialMediaById["instagram"] ? getSocialMediaById["instagram"] : this.state.fieldsSocialMedia["instagram"] || ''}
                        onChange={this.inputChangeSocialMedia}
                        disabled={this.state.isDisabledSocialMedia} />
                      {this.state.errorsSocialMedia["instagram"] ?
                        <div className="invalid-feedback text-red-500 text-xs italic">
                          {this.state.errorsSocialMedia["instagram"]}
                        </div>
                        : null}
                    </div>
                  </div>
                </div>

                <div className="w-full">
                  <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full md:w-1/2 px-3 md:mb-0">
                      <label className="block tracking-wide text-white text-xs font-bold mb-2" for="grid-first-name">Twitter</label>
                      <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white" name="twitter" placeholder='Twitter Id' type="text"
                        value={this.state.isDisabledSocialMedia && getSocialMediaById && getSocialMediaById["twitter"] ? getSocialMediaById["twitter"] : this.state.fieldsSocialMedia["twitter"] || ''}
                        onChange={this.inputChangeSocialMedia}
                        disabled={this.state.isDisabledSocialMedia} />
                      {this.state.errorsSocialMedia["twitter"] ?
                        <div className="invalid-feedback text-red-500 text-xs italic">
                          {this.state.errorsSocialMedia["twitter"]}
                        </div>
                        : null}
                    </div>
                    <div className="w-full md:w-1/2 px-3">
                      <label className="block tracking-wide text-white text-xs font-bold mb-2" for="grid-last-name">Linkedin</label>
                      <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" name="linkedin" placeholder='Linked In Id' type="text"
                        value={this.state.isDisabledSocialMedia && getSocialMediaById && getSocialMediaById["linkedin"] ? getSocialMediaById["linkedin"] : this.state.fieldsSocialMedia["linkedin"] || ''}
                        onChange={this.inputChangeSocialMedia}
                        disabled={this.state.isDisabledSocialMedia} />
                      {this.state.errorsSocialMedia["linkedin"] ?
                        <div className="invalid-feedback text-red-500 text-xs italic">
                          {this.state.errorsSocialMedia["linkedin"]}
                        </div>
                        : null}
                    </div>
                  </div>
                </div>

                <div className=" grid lg:grid-cols-1 grid-cols-1 gap-6 py-6">
                  {
                    this.state.isDisabledSocialMedia === false ?
                      <span className="block w-full rounded-md shadow-sm">
                        <button className="lg:w-2/5 w-full mx-auto flex justify-center py-2 uppercase px-3 border border-transparent text-sm font-meduim rounded-md text-white bg-[#DC2626] focus:outline-none transition duration-150 ease-in-out" type="button" disabled={this.state.isDisabledSocialMedia} onClick={this.saveSocialMedia} data-config-id="01_primary-action">SUBMIT</button>
                      </span>
                      : null
                  }
                </div>

                {/* <div className="flex justify-center py-10">
                  <button className="bg-[#800000] hover:bg-blue-700 text-white font-bold py-1 px-12 rounded-full" type="button" onClick={this.saveSocialMedia}>SUBMIT</button>
                </div> */}
              </div>


            </div>
          </div>

        </div>

      </>
    );
  }
}
function mapStateToProps(state) {
  const { users } = state;
  const { overview, getSocialMediaById } = users ? users : {};
  const { user } = overview ? overview : {};
  return {
    users,
    user,
    getSocialMediaById
  };
}
export default connect(mapStateToProps)(Dashboard);