import React from "react";

import Modal from 'react-modal';


export default function CreateAppSettingModal(props) {

  let { appsettingCreateModal, handleAppSeetingHideModal,
    errorField,
    inputChange, buyPackageSubmit
  } = props;
  //console.log("userCreateModal  ",userCreateModal);

  return (

    <Modal
      isOpen={appsettingCreateModal}
    // contentLabel="Example Modal"
    >


      <div className="main-modal fixed flex justify-center items-center w-full h-100 inset-0 z-50 overflow-hidden animated fadeIn faster" style={{ background: 'rgba(0,0,0,.7)' }}>
        <div className="border border-teal-500 modal-container bg-[#1F2937] w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">
          <div className="modal-content py-4 text-left px-6">

            <div className="flex justify-between items-center pb-3">
              <p className="text-2xl font-bold text-gray-400">BUY PACKAGE</p>
              <div className="modal-close cursor-pointer z-50">
                <svg onClick={() => handleAppSeetingHideModal()} className="fill-current text-white" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                  <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                  </path>
                </svg>
              </div>
            </div>

            <div className="my-5">

              <form autoComplete="off">
                <div className="{otpSent?'disableArea':''}">

                  <div className="flex px-1 w-full ">

                    <div className='w-full'>
                      <div className=' text-white space-y-2'>
                        <div className=" rounded-md shadow-sm relative bg-[#1F2937] w-full">
                          <input className={`appearance-none block w-full px-4 py-2 h-12 rounded-xl text-gray-800 bg-gray-50 focus:outline-none focus:border-gray-200 focus:bg-gray-100 focus:text-gray-800 transition duration-150 text-xl font-medium sm:leading-5 ${errorField && !errorField["amount"] ? " placeholder-gray-500" : "border-opacity-100 border border-red-500"}`}
                            id="amount" name="amount" placeholder="amount"
                            onChange={inputChange}
                          />
                          {errorField && errorField["amount"] ?
                            <div className="invalid-feedback text-yellow-600">
                              {errorField["amount"]}
                            </div>
                            : null}
                        </div>
                        <center className="pt-4">
                          <button className='bg-[#DC2626] text-white text-xl font-semibold px-6 py-2 w-44 rounded-2xl hover:bg-red-800' type="button" onClick={buyPackageSubmit}>BUY</button>
                        </center>
                      </div>
                    </div>

                  </div>
                </div>
              </form>

            </div>

          </div>
        </div>
      </div>

    </Modal>



  );
}
