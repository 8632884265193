import React, { Component } from 'react';
import { userActions } from '../../_actions';
import { connect } from 'react-redux';
// import { BsCurrencyDollar } from "react-icons/bs";
// import QRCode, { QRCodeSVG } from 'qrcode.react';
import moment from 'moment';
// import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import LoadingOverlay from 'react-loading-overlay';
// import ReactPaginate from 'react-paginate';
// import { isMobile } from "react-device-detect";
import Countdown from 'react-countdown';
const Completionist = () => <span>Expired!</span>;

class ExternalWallet extends Component {

  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      size: 10,
      keyWord: "",
      pageNo: 1,
      formField: {},
      errorField: {},
      dateDetails: {
        from_date: new Date(),
        to_date: new Date(),
      },
    }
  }

  componentDidMount() {

    // let data = {
    //   "txType": "WITHDRAW",
    //   "keyWord": "",
    //   "pageNo": 1,
    //   "size": 10
    // }

    // this.props.dispatch(userActions.getTx(data));
    // this.props.dispatch(userActions.getDftWallet());
    this.props.dispatch(userActions.getUserPromoPackageByUserId());
  }


  static getDerivedStateFromProps(nextProps, prevState) {

    if (nextProps.users.withdrawBalanceSuccess) {
      return {
        ...nextProps,
        isVerifyUserName: false,
        formField: {},
        errorField: {},
      }
    }
    else {
      return {
        ...nextProps,
      }
    }
  }

  transactionSearchSubmit = (e) => {
    e.preventDefault();
    if (this.handleValidation()) {
      let reqData = {
        "txType": "SEND_RECEIVED",
        "keyWord": "",
        "pageNo": 1,
        "size": 10,
        // "coinId": this.state.formField && this.state.formField.coinId ? this.state.formField.coinId : null,
        "from": (this.state.dateDetails.from_date),
        "to": (this.state.dateDetails.to_date),
      }
      // console.log('transactionSearch_____******', reqData);
      // console.log('this.state.dateDetails******', this.state.dateDetails);
      this.props.dispatch(userActions.transactionSearch(reqData, this.props));
    }
  }


  getDate = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let dateDetails = this.state.dateDetails;
    dateDetails[name] = value;
    // console.log(name, value);
    this.setState({ dateDetails });
  }




  handleFromDate = (event) => {
    // console.log(event.target.name, event.target.value);
    // event.preventDefault();
    const { dateDetails } = this.state;
    dateDetails["from_date"] = event.target.value;

    this.setState({ dateDetails });
  }


  handleToDate = (event) => {
    // console.log(event.target.name, event.target.value);
    // event.preventDefault();
    const { dateDetails } = this.state;
    dateDetails["to_date"] = event.target.value;

    this.setState({ dateDetails });
  }

  handlePageClick = (data) => {
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });
    let datatemp = {
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size,
      "txType": "WITHDRAW",
    }
    this.props.dispatch(userActions.getTx(datatemp));
  }



  UserPromoPackageSubmit = (e) => {
    e.preventDefault();
    if (this.handleValidationAddress()) {
      let reqData = {
        amount: parseInt(this.state.formField.amount)
      }

      // console.log("reqData____________:", reqData);

      this.props.dispatch(userActions.createUserPromoPackage(reqData, this.props));
    }
  }

  handleValidationAddress = () => {
    let formField = this.state.formField;
    let errorField = {};
    let formIsValid = true;

    //amount
    if (!formField["amount"] || formField["amount"] === "") {
      formIsValid = false;
      errorField["amount"] = "Cannot be empty";
    }

    // console.log('errorsIndex_errorsIndex_:::::', errorField);
    this.setState({ errorField: errorField });
    return formIsValid;
  }

  inputChange = (event) => {
    // console.log(event.target.name, event.target.value);
    const { formField, errorField } = this.state;
    formField[event.target.name] = event.target.value;
    errorField[event.target.name] = "";
    // console.log(event.target.name, event.target.value);
    this.setState({ formField, errorField });
  }

  claimPackage = (data) => {
    this.props.dispatch(userActions.claimPromoPack());
  }

  render() {



    let { users } = this.props;
    let { loading, userPromoPackage,
      // buyPromoPackage 
    } = users;

    // // console.log("RENDER_____________getDftWalletItems___--:::::", getDftWalletItems);
    // console.log("RENDER________________userPromoPackage--:::::", userPromoPackage);


    return (

      <>

        <div >
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <div className="h-screen overflow-y-auto w-full bg-[#1f2837] p-6">
          <section>
            <div className="container mx-auto xl:flex xl:space-x-6 xl:space-y-0 space-y-6 items-start w-full">
              <div className="w-full flex flex-wrap mx-auto">
                {userPromoPackage ?
                  <>
                    <div className="w-full rounded-sm overflow-hidden shadow-lg">
                      <div className="md:w-2/5 w-full mx-auto h-[24rem] bg-[#111627] flex justify-center items-center mb-4">
                        <div>
                          <h1 className='text-white text-xl font-semibold text-center pb-4'>Promotional Package</h1>
                          <h1 className='text-white text-xl font-semibold text-center pb-4'>Amount : {userPromoPackage && userPromoPackage.amount ? userPromoPackage.amount : "-"}</h1>
                          {userPromoPackage.status === 1 ? <>
                            <h1 className='text-white text-xl font-semibold text-center pb-4'>Congratulation You Have Achieved</h1>
                            {/* <h1 className='text-white text-xl font-semibold text-center pb-4'>Hours Left :
                              <Countdown date={userPromoPackage.createdAt + 259200 * 1000} daysInHours={true}>
                              </Countdown>
                            </h1> */}
                          </>
                            : null}

                          {userPromoPackage.status === 2 ? <><Completionist /></> : null}
                          {/* <Completionist /> */}

                          {
                            userPromoPackage.status === 0 ?
                              <div className="block w-full rounded-md shadow-sm mt-6">
                                <h1 className='text-white text-xl font-semibold text-center pb-4'>Hours Left :
                                  <Countdown date={userPromoPackage.createdAt + 259200 * 1000} daysInHours={true}>
                                  </Countdown>
                                </h1>
                                <button className="lg:w-2/5 w-full mx-auto flex justify-center py-2 uppercase px-3 border border-transparent text-sm font-meduim rounded-md text-white bg-[#DC2626] focus:outline-none transition duration-150 ease-in-out" type="button" onClick={() => this.claimPackage()}>Claim</button>
                              </div>
                              : null
                          }
                        </div>
                      </div>
                    </div>
                  </>
                  :
                  <>


                    <div className="w-full rounded-sm overflow-hidden shadow-lg">
                      <div className="xl:w-2/5 w-full mx-auto h-[24rem] bg-[#111627] flex justify-center items-center mb-4">
                        <div className='p-4'>
                          <h1 className='text-white text-xl font-semibold text-center pb-4'>Buy Promotional Package</h1>
                          <div className="w-full md:mb-0">
                            {/* <label className="block tracking-wide text-white text-xs font-bold mb-2" for="grid-first-name">First Name</label> */}
                            <input className="border-opacity-100 border border-red-500  appearance-none block md:w-96 w-full mx-auto px-4 py-2 h-12 rounded-xl text-gray-800 bg-gray-50 focus:outline-none focus:border-gray-200 focus:bg-gray-100 focus:text-gray-800 transition duration-150 text-xl font-medium sm:leading-5"
                              id="amount" name="amount" placeholder="Enter Amount"
                              onChange={this.inputChange}
                              value={this.state.formField && this.state.formField['amount'] ? this.state.formField['amount'] : ''}
                            />
                            {this.state.errorField["amount"] ?
                              <div className="invalid-feedback text-red-500 text-xs italic">
                                {this.state.errorField["amount"]}
                              </div>
                              : null}
                          </div>
                          <div className="block w-full rounded-md shadow-sm mt-6">
                            <button className="lg:w-2/5 w-full mx-auto flex justify-center py-2 uppercase px-3 border border-transparent text-sm font-meduim rounded-md text-white bg-[#DC2626] focus:outline-none transition duration-150 ease-in-out" type="button" onClick={this.UserPromoPackageSubmit}>SUBMIT</button>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className="flex justify-center items-center w-full md:w-2/5 px-4 py-6 h-[34rem] mt-6 mr-6 bg-[#111627]">
                      <div className="w-full">
                        <h1 className='text-white text-xl font-semibold text-center pb-4'>Buy Promotional Package</h1>
                        <div className='flex justify-center text-center space-y-3 py-4'>
                          <input className="border-opacity-100 border border-red-500  appearance-none block md:w-96 w-full mx-auto px-4 py-2 h-12 rounded-xl text-gray-800 bg-gray-50 focus:outline-none focus:border-gray-200 focus:bg-gray-100 focus:text-gray-800 transition duration-150 text-xl font-medium sm:leading-5"
                            id="amount" name="amount" placeholder="Enter Amount"
                            onChange={this.inputChange}
                            value={this.state.formField && this.state.formField['amount'] ? this.state.formField['amount'] : ''}
                          />
                          {this.state.errorField && this.state.errorField["amount"] ?
                            <div className="invalid-feedback text-yellow-600">
                              {this.state.errorField["amount"]}
                            </div>
                            : null}
                        </div>
                        <center className="pt-4">
                          <button className='bg-[#DC2626] text-white text-xl font-semibold px-6 py-2 w-44 rounded-2xl hover:bg-red-800' type="button" onClick={this.UserPromoPackageSubmit}>Submit</button>
                        </center>
                      </div>
                    </div> */}
                  </>
                }
              </div>
            </div>

            {
              userPromoPackage ?
                <>
                  <div className=' bg-slate-900 pb-6 px-2'>
                    <h1 className="text-white text-xl py-2 px-4">Promotional Package List</h1>
                    <div className="overflow-hidden pb-2 lg:p-6">
                      <div className="overflow-auto max-w-full ">
                        <div className="inline-block min-w-full  ">
                          <div className="overflow-hidden  ">
                            <table className="min-w-full border border-black  text-center">
                              <thead className="border border-black">
                                <tr className="border border-black text-center">
                                  <th scope="col" className="whitespace-nowrap border border-white text-center px-[49px] bg-[#790000] py-3 text-xs font-semibold text-white uppercase">S/n</th>
                                  <th scope="col" className="whitespace-nowrap border border-white text-center px-[38px] py-3 bg-[#790000] text-xs font-semibold text-white capitalize">date</th>
                                  <th scope="col" className="whitespace-nowrap border border-white text-center px-[49px] bg-[#790000] py-3 text-xs font-semibold text-white uppercase">Amount</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr className="border border-black text-center">
                                  <td className="whitespace-nowrap border border-gray-300 text-center px-[11.5px] py-3 text-xs font-semibold text-black bg-white uppercase bg-whj">1</td>
                                  <td className="whitespace-nowrap border border-gray-300 text-center px-[11.5px] py-3 text-xs font-semibold text-black bg-white uppercase bg-whj">{moment(new Date(parseInt(userPromoPackage && userPromoPackage.createdAt ? userPromoPackage.createdAt : "-"))).utcOffset("+05:30").format("DD-MM-YYYY HH:mm")}</td>
                                  <td className="whitespace-nowrap border border-gray-300 text-center px-[11.5px] py-3 text-xs font-semibold text-black bg-white uppercase bg-whj">{userPromoPackage && userPromoPackage.amount ? userPromoPackage.amount : "-"}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </> :
                <div className=' bg-slate-900 pb-6 px-2'>
                  <h1 className="text-white text-xl py-2 px-4">Promotional Package List</h1>
                  <div className="overflow-hidden pb-2 lg:p-6">
                    <div className="overflow-auto max-w-full ">
                      <div className="inline-block min-w-full  ">
                        <div className="overflow-hidden  ">
                          <table className="min-w-full border border-black  text-center">
                            <thead className="border border-black">
                              <tr className="border border-black text-center">
                                <th scope="col" className="whitespace-nowrap border border-white text-center px-[49px] bg-[#790000] py-3 text-xs font-semibold text-white uppercase">S/n</th>
                                <th scope="col" className="whitespace-nowrap border border-white text-center px-[38px] py-3 bg-[#790000] text-xs font-semibold text-white capitalize">date</th>
                                <th scope="col" className="whitespace-nowrap border border-white text-center px-[49px] bg-[#790000] py-3 text-xs font-semibold text-white uppercase">Amount</th>

                              </tr>
                            </thead>
                            <tbody>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

            }


          </section>


        </div >

      </>

    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  const { overview } = users ? users : {};
  const { setting } = overview ? overview : {};
  return {
    users,
    setting
  };
}
export default connect(mapStateToProps)(ExternalWallet);