import React from "react";
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { RiWallet2Line, RiWallet3Fill } from "react-icons/ri";
import { HiChevronLeft, HiChevronDown } from "react-icons/hi";
import { GiWallet } from "react-icons/gi";
import { FaNetworkWired } from "react-icons/fa";

function Sidebar({ location, history, user }) {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  const [wallet, setWallet] = React.useState(false);
  const onClickMenu = (url) => {
    history.push(url);
    setTimeout(() => {
      setNavbarOpen(!navbarOpen)
    }, 150);

  }

  const logout = (url) => {
    window.sessionStorage.removeItem('user');
    history.push(url);
    setTimeout(() => {
      setNavbarOpen(!navbarOpen)
    }, 150);

  }

  const logoutSubmit = () => {

    confirmAlert({
      title: 'Confirm to submit?',
      message: 'Are you sure want to logout.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => logout('/login')
        },
        {
          label: 'No'
        }
      ]
    });
  }

  return (
    <>


      {/* Navbar Toggle */}
      <button className={` ${navbarOpen === true ? 'hidden' : 'flex'} absolute top-0 left-0 px-5 items-center justify-center  text-gray-500 focus:outline-none focus:bg-gray-800 focus:text-gray-600 z-50 h-16 lg:hidden `} onClick={() => setNavbarOpen(!navbarOpen)}>
        <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h7">
          </path>
        </svg>
      </button>


      {/* Navbar transparent Background & close Button */}
      {navbarOpen ?
        <div className={` ${navbarOpen === false ? 'hidden' : ''} lg:hidden lg:flex-shrink-0 lg:static inset-0 z-50 fixed bg-black bg-opacity-40`} onClick={() => setNavbarOpen(!navbarOpen)}>

          <div className="flex items-center justify-center h-12 w-12 rounded-full focus:outline-none focus:bg-gray-600 absolute left-[250px] lg:left-80 top-0 z-20   " aria-label="Close sidebar">
            <svg className="h-6 w-6 text-white" stroke="currentColor" fill="none" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12">
              </path>
            </svg>
          </div>
        </div>
        : null}

      {/* Main Navbar */}
      <div className={` ${navbarOpen === false ? 'hidden' : 'flex'} bg-[#111827] fixed left-0 inset-y-0 lg:static lg:left-auto lg:inset-auto lg:flex lg:w-80 w-64 z-50`}>

        <div className="flex flex-col lg:w-80 w-64 relative overflow-y-auto">

          <div onClick={() => onClickMenu('/app/dashboard')} className="bg-[#111827] cursor-pointer flex items-center h-16 border-b border-gray-500 flex-shrink-0 px-4 w-ful z-50 sticky top-0">
            <span className="w-20 h-16 rounded-full mt-2 bg-dark-400 relative flex justify-center items-center mr-4 lg:mr-8">
              <img className="w-20 bg-contain" src="/imglogo/EFINCAP_Final Logo_For Dark BG.png" alt="logo" />
            </span>
            <span className="text-shine-400 font-bold md:text-2xl text__change tracking-wide text-orange-400	">
            CONFY-MLM
            </span>
          </div>

          <div className="bg_sidebar flex-1 flex flex-col pt-1">
            <nav className="flex-1 py-4 space-y-4">

              <div className="w-full">
                <div className="w-full flex justify-center mx-auto"><div className="rounded-full w-14 h-14 bg-gray-400 text-center"></div></div>
                <h1 className="uppercase text-lg text-center text-white py-1">{user && user.userName ? user.userName : "-"}</h1>
                <p className="text-sm text-center text-white">Mining Advisor</p>
              </div>


              <div className="flex justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="#C10F14">
                  <path fillRule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd" />
                </svg>

                <span className="px-5">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="#C10F14" strokeWidth="2">
                    <path strokeLinecap="round" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                  </svg>
                </span>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="#C10F14" strokeWidth="2">
                  <path strokeLinecap="round" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                </svg>
              </div>

              <div className="border-b w-full border-gray-900"></div>
              {/* <span className="pl-5 pr-2 text-white uppercase text-sm">personal</span> */}
              <span onClick={() => onClickMenu('/app/dashboard')} className={`mt-0 w-full h-12 cursor-pointer flex items-center -mr-5 pl-5 pr-2 py-3 text-md leading-5 font-semibold text-white focus:outline-none  transition ease-in-out duration-150 ${location.pathname === "/app/dashboard" ? "text-white bg-[#C10F14]" : ""}`}>
                <span className="rounded-full p-1 bg_icon">
                  <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6">
                    </path>
                  </svg>
                </span>
                {/* <img className="w-6 h-6 mr-3" src="imge/Selected Menu.png" alt="logo" /> */}
                <span className="ml-3">Dashboard</span>
              </span>



              {wallet ?
                <div>
                  <span onClick={() => setWallet(!wallet)} className={`w-full h-12 cursor-pointer flex items-center justify-between -mr-5 pl-5 pr-2 py-3 text-md leading-5 font-semibold text-white focus:outline-none  transition ease-in-out duration-150 ${location.pathname === "/app/portfolio" ? "text-white bg-[#C10F14]" : ""}`}>
                    <div className="flex justify-between space-x-2">
                      <span className="rounded-full p-1 bg_icon">
                        <RiWallet2Line className="w-6 h-6 text-white" />
                      </span>
                      <span className="text-sm font-semibold mt-1.5">Wallet Management</span>
                    </div>
                    {
                      wallet ? <HiChevronDown /> : <HiChevronLeft />
                    }
                  </span>

                  <div onClick={() => onClickMenu('/app/mainwallet')} className={`hover:bg-gray-800 cursor-pointer items-center flex space-x-2 px-6 pl-10 py-2 text-md   font-semibold text-gray-400 hover:text-white  focus:outline-none  transition ease-in-out duration-150  ${location.pathname === "/app/mainwallet" ? "text-white bg-[#C10F14]" : ""}`}>
                    <div className=" ml-[12px]">
                      <RiWallet3Fill className="w-6 h-6 text-white" />
                    </div>
                    <span className="text-xs font-semibold text-white">Main Wallet</span>
                  </div>
                  <div onClick={() => onClickMenu('/app/externalwallet')} className={`hover:bg-gray-800 cursor-pointer items-center flex space-x-2 px-6 pl-10 py-2 text-md   font-semibold text-gray-400 hover:text-white  focus:outline-none  transition ease-in-out duration-150  ${location.pathname === "/app/externalwallet" ? "text-white bg-[#C10F14]" : ""}`}>
                    <div className=" ml-[12px]">
                      <GiWallet className="w-6 h-6 text-white" />
                    </div>
                    <span className="text-xs font-semibold text-white">External Wallet</span>
                  </div>

                </div>
                :
                <span onClick={() => setWallet(!wallet)} className={`w-full h-12 cursor-pointer flex items-center justify-between -mr-5 pl-5 pr-2 py-3 text-md leading-5 font-semibold text-white focus:outline-none  transition ease-in-out duration-150 ${location.pathname === "/app/portfolio" ? "text-white bg-[#C10F14]" : ""}`}>
                  <div className="flex justify-between space-x-2">
                    <span className="rounded-full p-1 bg_icon">
                      <RiWallet2Line className="w-6 h-6" />
                    </span>
                    <span className="text-sm font-semibold mt-1.5">Wallet Management</span>
                  </div>
                  {
                    wallet ? <HiChevronDown /> : <HiChevronLeft />
                  }
                </span>
              }

              <span onClick={() => onClickMenu('/app/packageupgradation')} className={`w-full h-12 cursor-pointer space-x-2 flex items-center -mr-5 pl-5 pr-2 py-3 text-md leading-5 font-semibold text-white focus:outline-none  transition ease-in-out duration-150 ${location.pathname === "/app/packageupgradation" ? "text-white bg-[#C10F14]" : ""}`}>
                <span className="rounded-full p-1 bg_icon">
                  <img src="newImg/packages.svg" alt="packages" className="w-7 h-7"   />
                </span>
                <span className="ml-3">Package Upgradation</span>
              </span>


              {/* <span onClick={() => onClickMenu('/app/promoPackage')} className={`w-full h-12 cursor-pointer space-x-2 flex items-center -mr-5 pl-5 pr-2 py-3 text-md leading-5 font-semibold text-white focus:outline-none  transition ease-in-out duration-150 ${location.pathname === "/app/promoPackage" ? "text-white bg-[#C10F14]" : ""}`}>
                <span className="rounded-full p-1 bg_icon">
                  <img src="newImg/packages.svg" alt="packages" className="w-7 h-7" />
                </span>
                <span className="ml-3">Promotional Package</span>
              </span> */}


              <span onClick={() => onClickMenu('/app/transactions')} className={`w-full h-12 cursor-pointer space-x-2 flex items-center -mr-5 pl-5 pr-2 py-3 text-md leading-5 font-semibold text-white focus:outline-none  transition ease-in-out duration-150 ${location.pathname === "/app/transactions" ? "text-white bg-[#C10F14]" : ""}`}>
                <span className="rounded-full p-1 bg_icon">
                  <img src="newImg/transaction.svg" alt="packages" className="w-7 h-7" />
                </span>
                <span className="ml-3">Transaction History</span>
              </span>

              <span onClick={() => onClickMenu('/app/referral')} className={`w-full h-12 cursor-pointer space-x-2 flex items-center -mr-5 pl-5 pr-2 py-3 text-md leading-5 font-semibold text-white focus:outline-none  transition ease-in-out duration-150 ${location.pathname === "/app/referral" ? "text-white bg-[#C10F14]" : ""}`}>
                <span className="rounded-full p-1 bg_icon">
                  <img src="newImg/referaal.svg" alt="packages" className="w-7 h-7" />
                </span>
                <span className="ml-3">My Infrastructure</span>
              </span>

              <span onClick={() => onClickMenu('/app/changePassword')} className={`w-full h-12 cursor-pointer space-x-2 flex items-center -mr-5 pl-5 pr-2 py-3 text-md leading-5 font-semibold text-white focus:outline-none  transition ease-in-out duration-150 ${location.pathname === "/app/changePassword" ? "text-white bg-[#C10F14]" : ""}`}>
                <span className="rounded-full p-1 bg_icon">
                  <img src="newImg/password.svg" alt="packages" className="w-7 h-7" />
                </span>
                <span className="ml-3">Change Password</span>
              </span>

              <span onClick={() => onClickMenu('/app/profile')} className={`w-full h-12 cursor-pointer space-x-2 flex items-center -mr-5 pl-5 pr-2 py-3 text-md leading-5 font-semibold text-white focus:outline-none  transition ease-in-out duration-150 ${location.pathname === "/app/profile" ? "text-white bg-[#C10F14]" : ""}`}>
                <span className="rounded-full p-1 bg_icon">
                  <img src="newImg/profile.svg" alt="packages" className="w-6 h-6" />
                </span>
                <span className="ml-3">Profile</span>
              </span>


              <span onClick={() => onClickMenu('/app/hierarchy')} className={`w-full h-12 cursor-pointer space-x-2 flex items-center -mr-5 pl-5 pr-2 py-3 text-md leading-5 font-semibold text-white focus:outline-none  transition ease-in-out duration-150 ${location.pathname === "/app/hierarchy" ? "text-white bg-[#C10F14]" : ""}`}>
                <span className="rounded-full p-1 bg_icon">
                  {/* <img src="newImg/profile.svg" alt="packages" className="w-6 h-6" /> */}
                 <FaNetworkWired className="w-7 h-7"/>
                </span>
                <span className="ml-3">Hierarchy</span>
              </span>


              <span onClick={() => onClickMenu('/app/support')} className={`w-full h-12 cursor-pointer space-x-2 flex items-center -mr-5 pl-5 pr-2 py-3 text-md leading-5 font-semibold text-white focus:outline-none  transition ease-in-out duration-150 ${location.pathname === "/app/support" ? "text-white bg-[#C10F14]" : ""}`}>
                <span className="rounded-full p-1 bg_icon">
                  <img src="newImg/support.svg" alt="packages" className="w-7 h-7" />
                </span>
                <span className="ml-3">Support</span>
              </span>


              <span onClick={logoutSubmit} className={`w-full h-12 cursor-pointer flex items-center -mr-5 pl-5 pr-2 py-3 text-md leading-5 font-semibold text-white focus:outline-none transition ease-in-out duration-150 ${location.pathname === "/login" ? "text-white bg-[#C10F14]" : ""}`}>
                <span className="rounded-full p-1 bg_icon ml-1">
                  <img src="newImg/logout.svg" alt="packages" className="w-5 h-5" />
                </span>
                <span className="ml-3">Logout</span>
              </span>

            </nav>
          </div>
        </div>

      </div>
    </>
  );

}

function mapStateToProps(state) {
  const { users } = state;
  const { overview } = users ? users : {};
  const { setting, user } = overview ? overview : {};
  // console.log("overviewoverviewoverviewoverview", overview);
  return {
    users,
    setting,
    user
  };
}


export default (connect(mapStateToProps)(withRouter(Sidebar)));

// export default withRouter(Sidebar);