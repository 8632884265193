import React, { Component } from 'react';
import { userActions, dashboardActions } from '../../_actions';
import { connect } from 'react-redux';
import moment from 'moment';
// import "./App.css";
import "jspdf-autotable";
import ReactPaginate from 'react-paginate';
import { isMobile } from "react-device-detect";
import LoadingOverlay from 'react-loading-overlay';



class Transaction extends Component {

  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      size: 10,
      keyWord: "",
      pageNo: 1,
      formField: {},
      errorField: {},
      dateDetails: {
        from: new Date(),
        to: new Date(),
      },
    }
  }

  componentDidMount() {

    // let data = {
    //   "txType": "MINING",
    //   "keyWord": "",
    //   "pageNo": 1,
    //   "size": 10
    // }
    let data = {
      "txType": ["MINING"],
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.size
    }

    this.props.dispatch(dashboardActions.getTxAllMulti(data));
  }

  transactionSearchSubmit = (e) => {
    e.preventDefault();
    if (this.handleValidation()) {
      let reqData = {
        "txType": "SEND_RECEIVED",
        "keyWord": "",
        "pageNo": 1,
        "size": 10,
        // "coinId": this.state.formField && this.state.formField.coinId ? this.state.formField.coinId : null,
        "from": (this.state.dateDetails.from),
        "to": (this.state.dateDetails.to),
      }
      // console.log('transactionSearch_____******', reqData);
      // console.log('this.state.dateDetails******', this.state.dateDetails);
      this.props.dispatch(userActions.transactionSearch(reqData, this.props));
    }
  }

  getTx = (e) => {
    e.preventDefault();
    // if (this.handleValidation()) {
    let reqData = {
      "txType": "RECEIVED_FROM_ADMIN",
      "keyWord": "",
      "pageNo": 1,
      "size": 10,
      "from": (this.state.dateDetails.from),
      "to": (this.state.dateDetails.to),
    }

    // console.log("getTx_______________:", reqData);

    this.props.dispatch(userActions.getTx(reqData, this.props));
    // }
  }


  getDate = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let dateDetails = this.state.dateDetails;
    dateDetails[name] = value;
    // console.log(name, value);
    this.setState({ dateDetails });
  }

  inputChange = (event) => {
    // console.log(event.target.name, event.target.value);
    // event.preventDefault();
    const { formField, errorField } = this.state;
    formField[event.target.name] = event.target.value;
    errorField[event.target.name] = "";
    // console.log(event.target.name, event.target.value);
    this.setState({ formField, errorField });
  }



  handlePageClick = (data) => {
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });
    let datatemp = {
      "txType": "MINING",
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size,
    }
    this.props.dispatch(userActions.getTx(datatemp));
  }

  handleValidation = () => {
    // let formField = this.state.formField;
    let errorField = {};
    let formIsValid = true;

    // console.log('errorsIndex_errorsIndex_:::::', errorField);
    this.setState({ errorField: errorField });
    return formIsValid;
  }

  handleFromDate = (event) => {
    // console.log(event.target.name, event.target.value);
    // event.preventDefault();
    const { dateDetails } = this.state;
    dateDetails["from"] = event.target.value;

    this.setState({ dateDetails });
  }


  handleToDate = (event) => {
    // console.log(event.target.name, event.target.value);
    // event.preventDefault();
    const { dateDetails } = this.state;
    dateDetails["to"] = event.target.value;

    this.setState({ dateDetails });
  }

  render() {



    let { users, dashboard } = this.props;
    let { loading } = users;
    let { getTxAllMulti ,txTotal} = dashboard;
    // // console.log("RENDER___________________1111111111111111111111111:::", getTxAllMulti);


    return (

      <>

        <div >
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <div className="bg-[#1f2837] w-full h-screen overflow-y-auto">
          <section>
            <div className='p-6'>
              <div className='w-full flex justify-center bg-slate-800 pt-6'>

              </div>
              <div className=' bg-slate-900 pb-2 px-2'>
                <h1 className="text-white text-xl pt-4 px-4">Mining Profits Transaction</h1>

                <div className="overflow-hidden pb-2 lg:p-6">
                  <div className="overflow-auto max-w-full ">

                    <div className="inline-block min-w-full  ">
                      <div className="overflow-hidden  ">

                        <table className="min-w-full border border-black  text-center">
                          <thead className="border border-black">
                            <tr className="border border-black text-center">
                              <th scope="col" className="whitespace-nowrap border border-white text-center px-[49px] bg-[#790000] py-3 text-xs font-semibold text-white uppercase">S/n</th>
                              {/* <th scope="col" className="whitespace-nowrap border border-white text-center px-[49px] bg-[#790000] py-3 text-xs font-semibold text-white uppercase">name</th> */}
                              {/* <th scope="col" className="whitespace-nowrap border border-white text-center px-[31px] py-3 bg-[#790000] text-xs font-semibold text-white capitalize">price</th> */}
                              <th scope="col" className="whitespace-nowrap border border-white text-center px-[45px] bg-[#790000] py-3 text-xs font-semibold text-white capitalize">Transaction Type</th>
                              <th scope="col" className="whitespace-nowrap border border-white text-center px-[22px] py-3 bg-[#790000] text-xs font-semibold text-white capitalize">amount</th>
                              <th scope="col" className="whitespace-nowrap border border-white text-center px-[38px] py-3 bg-[#790000] text-xs font-semibold text-white capitalize">date</th>
                            </tr>
                          </thead>
                          <tbody>

                            {
                              getTxAllMulti && getTxAllMulti.length > 0 ?
                                getTxAllMulti.map((element, index) => (
                                  <>
                                    <tr className="border border-black text-center">
                                      <td className="whitespace-nowrap border border-gray-300 text-center px-[11.5px] py-3 text-xs font-semibold text-black bg-white uppercase bg-whj">{this.state.offset + index + 1}</td>
                                      {/* <td className="whitespace-nowrap border border-gray-300 text-center px-[11.5px] py-3 text-xs font-semibold text-black bg-white uppercase bg-whj">{element && element.userRecId && element.userRecId.userName ? element.userRecId.userName : "-"}</td> */}
                                      {/* <td className="whitespace-nowrap border border-gray-300 text-center px-[11.5px] py-3 text-xs font-semibold text-black bg-white uppercase bg-whj">{element && element.coinId && element.coinId.price ? element.coinId.price : "-"} </td> */}
                                      <td className="whitespace-nowrap border border-gray-300 text-center px-[11.5px] py-3 text-xs font-semibold text-black bg-white uppercase bg-whj">{element && element.txType ? element.txType : "-"}</td>
                                      <td className="whitespace-nowrap border border-gray-300 text-center px-[11.5px] py-3 text-xs font-semibold text-black bg-white uppercase bg-whj">{element && element.amount ? element.amount : "-"}</td>
                                      <td className="whitespace-nowrap border border-gray-300 text-center px-[11.5px] py-3 text-xs font-semibold text-black bg-white uppercase bg-whj">{moment(new Date(parseInt(element && element.createdAt ? element.createdAt : "-"))).utcOffset("+05:30").format("DD-MM-YYYY HH:mm")}</td>
                                    </tr>
                                  </>
                                )) : null
                            }

                          </tbody>
                        </table>
                      </div>
                    </div>


                  </div>

                </div>
              </div>

              {
                isMobile ?
                  <nav className="relative z-0 flex justify-end mt-2 w-76">
                    {
                      txTotal && txTotal > 10 ?
                        <ReactPaginate
                          previousLabel={'Prev'}
                          nextLabel={'Next'}
                          breakLabel={'...'}
                          breakClassName={'break-me'}
                          pageCount={txTotal / this.state.size}
                          marginPagesDisplayed={1}
                          pageRangeDisplayed={1}
                          onPageChange={this.handlePageClick}
                          containerClassName={'pagination'}
                          pageClassName={'page-cls'}
                          activeClassName={'active'}
                        />
                        : null}
                  </nav> : <nav className="relative z-0 flex justify-end mt-2 w-76">
                    {
                      txTotal && txTotal > 10 ?
                        <ReactPaginate
                          previousLabel={'Previous'}
                          nextLabel={'Next'}
                          breakLabel={'...'}
                          breakClassName={'break-me'}
                          pageCount={txTotal / this.state.size}
                          marginPagesDisplayed={3}
                          pageRangeDisplayed={3}
                          onPageChange={this.handlePageClick}
                          containerClassName={'pagination'}
                          pageClassName={'page-cls'}
                          activeClassName={'active'}
                        />
                        : null}
                  </nav>
              }

            </div>
          </section>
        </div>
      </>

    );
  }
}

function mapStateToProps(state) {
  const { users, dashboard } = state;
  const { overview } = users ? users : {};
  const { setting } = overview ? overview : {};
  return {
    users,
    dashboard,
    setting
  };
}
export default connect(mapStateToProps)(Transaction);
